import React from "react";
import Template from "./PostTemplate";
import marked from "marked";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

const markdown = `
Að læra að keyra getur kostað <a href="/blogg/hvad-kostar-bilprof/">á milli 300.000 og 400.000 kr</a>. Það er ekki á hvers mann færi að greiða slíkar upphæðir en það eru til leiðir til að lækka kostnaðinn! 

Til dæmis bjóða mörg (en ekki öll) stéttarfélög upp á styrk til almenns ökunáms. Í sumum tilfellum er jafnvel hægt að lækka kostnaðinn um mörg hundruð þúsund krónur með þeirra hjálp. 

Hvort og hve mikið er styrkt fer eftir því í hvaða stéttarfélagi þú ert, hve lengi þú hefur unnið, vinnuhlutfalli og hvort þú hafir sótt um aðra styrki.

Tökum nokkur dæmi. 

## Dæmi 1: Félagi í Eflingu í fullri vinnu

Hámarksstyrkur til náms og námskeiða Eflingu nemur 130.000 kr. á ári. En ef félagi í Eflingu hefur ekki nýtt sér styrkinn í þrjú ár á hann rétt á 390.000 kr. styrk (130.000 x 3) fyrir námskeið og nám. Það má nýta upp í 90% af námskostnaði. Það þýðir að mögulegt er að klára ökunám fyrir u.þ.b. 60.000 kr!

Styrkurinn getur farið upp í bóklega og verklega hluta námsins. Hafðu í huga að styrkurinn fer ekki upp í bókakostnað eða próf. 


## Dæmi 2: Félagi í VR í fullri vinnu

Félagsmenn VR eiga rétt á allt að 180.000 kr styrk á ári fyrir nám og námskeið. Félagsmaður sem ekki hefur nýtt sér styrk í þrjú árá rétt á 540.000 kr. styrk. <a href="https://www.starfsmennt.is/starfsreglur/">Sömu reglur</a> gilda hjá VR og hjá Eflingu að styrkupphæð má ekki vera hærri en 90%
af námskeiðskostnaði.

Við sjáum því að þetta er mjög svipað dæmi og hjá Eflingu!

## Dæmi 3: Félagi í sumarstarfi
Ef þú ert í skóla á veturna en ert dugleg/ur að vinna yfir sumarmánuðina þá áttu rétt á að sækja um styrk hjá mörgum en ekki öllum stéttarfélögum. Sum stéttarfélög miða við 6 mánuði og önnur jafnvel 12. 

Styrkur eftir 3 mánuði vinnu er venjulega lágmarks styrkur og fer það eftir stéttarfélagi hversu hár hann er.

## Dæmi 4: Félagi í hlutastarfi
Ef þú ert í hlutastarfi (t.d. með skóla) þá áttu venjulega rétt á að sækja um styrk í samræmi við vinnuhlutfall.

## Samantekt
Styrkur frá stéttarfélagi þínu getur **mögulega** lækkað kostnaðinn af ökunáminu þínu. Hvort og hve mikið er styrkt fer eftir stéttarfélagi, hve lengi þú hefur unnið, hlutfall vinnu í mánuði og hvort þú hafir sótt um aðra styrki. Kíktu á heimasíðu þíns stéttarfélags eða hafðu samband við félagið til að kanna rétt þinn.

Á heimasíðum stéttarfélaga er ekki alltaf sagt berum orðum hvort að veittur sé styrkur til ökunáms. Ökunám flokkast venjulega sem "Nám og námskeið" hjá flestum eða sem "Annað". 

Ef þú ert í vafa um rétt þinn þá er best að senda línu á þitt stéttarfélag og spyrja.

<span class="last-updated">Uppfært 31. október 2024</span>
`;

const markdownEn = `Learning to drive in Iceland can cost between 300,000 to 400,000 kr but it’s not within everyone’s means to pay that sort of money. 

There are, however, ways to reduce the cost.

Many, but not all, unions in Iceland offer grants to help you pay for your driving licence. In some cases it’s even possible to save yourself hundreds of thousands of kronurs with their help. 

Whether or not you can get a grant depends on what union you’re in, how long you’ve been working, how much you work in a given month and whether or not you’ve applied for other grants.

## Example 1: An Efling union member in a full-time job
The maximum grant for education and courses from Efling is 130,000 kr per year. This means that the grant covers about almost half of the typical cost of the studies each year. But if it so happens that you haven’t applied for a grant in three years then you have a right to a 390,000 kr (130,000 x 3) grant. You can use that money for up to 90% of the education costs.

Efiling used to only pay for the theory part of the studies. But in 2022 their rules changed and now the grant can be used for both the theory courses and the in-person practical lessons. Keep in mind that the grant *can’t* be used to buy books, pay for the final theory or practical test. All this means that it’s not possible to get the licence for free but you can to learn to drive for just 60,000 kr!

## Example 2: A VR union member
Let’s take another example from a large union.

VR union members have a right to up to 180,000 kr grant per year for education and courses. That’s a bit over half of the typical cost of learning to drive, every year. In addition VR union members that haven’t used their grant for three years have a right to 540,000 kr. The same rules apply as with Efling; the grant amount can’t be more than 90% of the study cost. 


## Example 3: Union members in summer jobs
If you are, for example, studying during the winter but spend the summer months of June, July and August working, then you may have the right to a grant from your union. But some unions need you to work 6 months or even 12 months out of the year to accrue grant rights.

However, the bad news is that the grants for only three months work are usually on the lower end. 

## Example 4: A union member in a part-time job
If you’re in a part time job you usually have a right to a grant proportionate to the amount you work. So a half job might give you half the normal grant.

## Conclusion
Your union can possibly pay for part of your driver’s education. Whether and how much you get depends on what union you’re in, how long you’ve been working, how much you work in a given month and whether or not you’ve applied for other grants. Our advice is simply: you should have a look at your union’s website to check your rights.

By the way, it’s not always obvious on union websites whether or not they subsidise your driving studies. Typically learning to drive is categorised under “Studies and courses” or as “Other.” If you’re in any doubt then it’s best to send a message to your union and ask.

<span class="last-updated">Updated 31 October 2024</span>
`;

export const translations = {
  is: {
    Unions: {
      BlogPost: markdown,
      Title: "Bílpróf á 60.000 kr? Hvernig stéttarfélögin geta hjálpað þér",
      Description: "Sparaðu á milli 40-85% af ökunáminu!",
    },
  },
  en: {
    Unions: {
      BlogPost: markdownEn,
      Title:
        "A driver's licence for 60,000 kr? How your union may be able to help you.",
      Description:
        "You may be able to save between 40-85% on the cost of learning to drive",
    },
  },
};

const Unions = () => {
  const { t } = useTranslation();
  const keywords = ["bílpróf", "stéttafélög", "ökunám"];
  const imageOptions = {
    imgCaption: "",
    imgAlt: "Sendibíll á Íslandi",
    imgSrc: "/images/blogg/van-in-iceland.jpg",
  };
  const parseMarkdown = marked(t("Unions.BlogPost"));
  const cleanMarkdown = DOMPurify.sanitize(parseMarkdown);

  return (
    <Template
      title={t("Unions.Title")}
      description={t("Unions.Description")}
      keywords={keywords}
      imageOptions={imageOptions}
    >
      <article dangerouslySetInnerHTML={{ __html: cleanMarkdown }}></article>
    </Template>
  );
};

export default Unions;
