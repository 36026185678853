import React from "react";
import Template from "./PostTemplate";
import marked from "marked";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

const namsheimild = "4.300";
const utiumferdina = "1.990";
const skriflega = "6.580";
const tvoskrifleg = "13.160";
const verklega = "17.430";
const okuskoli3 = "49.500";

const markdown = `
Það kostar sitt að læra að keyra bíl. Það kostar að lágmarki í kringum 270.000 kr. en getur farið upp í kr. 400.000 kr. fyrir allan pakkann.

Kostnaðurinn gæti skiptst upp á eftirfarandi máta:

<table>
  <tr>
    <td>Námsheimild frá sýslumanni</td>
    <td>${namsheimild} kr.</td>
  </tr>
  <tr>
    <td>15 ökutímar (12.900 kr tíminn) </td>
    <td>193.500 kr.</td>
  </tr>
  <tr>
    <td>Námsbókin - Út í umferðina</td>
    <td>${utiumferdina} kr.</td>
  </tr>
  <tr>
    <td>Ökuskóli 1 (hjá okkur)</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Ökuskóli 2 (hjá okkur)</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Ökuskóli 3</td>
    <td>${okuskoli3} kr.</td>
  </tr>
  <tr>
    <td>Skriflega prófið</td>
    <td>${skriflega} kr.</td>
  </tr>
  <tr>
    <td>Verklega prófið</td>
    <td>${verklega} kr.</td>
  </tr>
 <tr>
    <td><b>Heildarverð</b></td>
    <td><b>298.300 kr.</b></td>
  </tr>
</table>

Ef þú vilt skilja ferlið aðeins betur getur þú <a href="/blogg/hvernig-faer-madur-bilprof">lesið um það betur hérna.</a> 

Margir vilja taka fleiri en 15 ökutíma hjá ökukennara og þurfa þar af leiðandi að greiða meira fyrir námið. Síðan þurfa sumir að endurtaka verklega eða bóklega bílprófið. Þannig hækkar kostnaðurinn.

Þannig það er allur gangur á þessu. 

Til dæmis, ef þú tækir 20 ökunámstíma og féllir einu sinni í skriflega prófinu þá gæti kostnaðurinn litið svona út:

<table>
  <tr>
    <td>Námsheimild frá sýslumanni</td>
    <td>${namsheimild} kr.</td>
  </tr>
  <tr>
    <td>20 ökutímar (12.900 kr. tíminn)</td>
    <td>258.000 kr.</td>
  </tr>
  <tr>
    <td>Námsbókin - Út í umferðina</td>
    <td>${utiumferdina} kr.</td>
  </tr>
  <tr>
    <td>Ökuskóli 1 (hjá okkur)</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Ökuskóli 2 (hjá okkur)</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Ökuskóli 3</td>
    <td>${okuskoli3} kr.</td>
  </tr>
  <tr>
    <td>Skriflega prófið (tvisvar)</td>
    <td>${tvoskrifleg} kr.</td>
  </tr>
  <tr>
    <td>Verklega prófið</td>
    <td>${verklega} kr.</td>
  </tr>
 <tr>
    <td><b>Heildarverð</b></td>
    <td><b>369.380 kr.</b></td>
  </tr>
</table>

<span class="last-updated">Uppfært 9. apríl 2024</span>`;

const markdownEn = `
It costs a good deal to learn how to drive a car. The total cost to get a driving licence in Iceland can ranges from 270.000 kr up to 400.000 kr for the entire process.

Here is an example of how the total amount could be broken down:

<table>
  <tr>
    <td>Applying for a study permit</td>
    <td>${namsheimild} kr.</td>
  </tr>
  <tr>
    <td>15 driving lessons (12.900 per lesson)</td>
    <td>193.500 kr.</td>
  </tr>
  <tr>
    <td>The textbook - Út í umferðina</td>
    <td>${utiumferdina} kr.</td>
  </tr>
  <tr>
    <td>Driving school 1 (Ökuskóli 1) with us</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Driving school 2 (Ökuskóli 2) with us</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Driving school 3</td>
    <td>${okuskoli3} kr.</td>
  </tr>
  <tr>
    <td>Written exam</td>
    <td>${skriflega} kr.</td>
  </tr>
  <tr>
    <td>Practical exam</td>
    <td>${verklega} kr.</td>
  </tr>
 <tr>
    <td><b>Total</b></td>
    <td><b>298.300 kr.</b></td>
  </tr>
</table>

If you want to know a little more about the whole process you can <a href="/blogg/hvernig-faer-madur-bilprof">[fix link!!!]read about the process here.</a> 

Many students will want to take more than 15 lessons with an instructor to gain more confidence and will thus end up paying more. In addition, some students need to retake the practical or written exam (or both!) and thus the cost can increase quickly.

So as you can see, the cost can vary. 

For example, if you took 20 driving lessons and needed to retake the written exam once the breakdown could look like this:

<table>
  <tr>
    <td>Student permit application</td>
    <td>${namsheimild} kr.</td>
  </tr>
  <tr>
    <td>20 driving lessons (12.900 kr. per lesson) </td>
    <td>258.000 kr.</td>
  </tr>
  <tr>
    <td>Textbook - Út í umferðina</td>
    <td>${utiumferdina} kr.</td>
  </tr>
  <tr>
    <td>Driving school 1 (Ökuskóli 1) with us</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Driving school 2 (Ökuskóli 2) with us</td>
    <td>12.500 kr.</td>
  </tr>
  <tr>
    <td>Driving school 3</td>
    <td>${okuskoli3} kr.</td>
  </tr>
    <td>Written exam (twice)</td>
    <td>${tvoskrifleg} kr.</td>
  </tr>
  <tr>
    <td>Practical exam</td>
    <td>${verklega} kr.</td>
  </tr>
 <tr>
    <td><b>Total</b></td>
    <td><b>369.380 kr.</b></td>
  </tr>
</table>

<span class="last-updated">Last updated 9. April 2024</span>`;

export const translations = {
  is: {
    Cost: {
      BlogPost: markdown,
      Title: "Hvað kostar bílpróf?",
      Description:
        "Ökutímar, ökuskólar 1, 2 og 3, og bílprófið sjálft. Þetta safnast fljótt saman.",
    },
  },
  en: {
    Cost: {
      BlogPost: markdownEn,
      Title: "How much does a driving licence in Iceland cost?",
      Description:
        "Driving lessons, Driving schools 1, 2 and 3 and exams. The costs can add up quickly.",
    },
  },
};

const Process = () => {
  const { t } = useTranslation();

  const keywords = [
    "bílpróf",
    "kostar",
    "b réttindi",
    "ökunám",
    "æfingarakstur",
  ];
  const imageOptions = {
    imgAlt: "bílpróf",
    imgSrc: "/images/blogg/bill.jpg",
  };

  const parseMarkdown = marked(t("Cost.BlogPost"));
  const cleanMarkdown = DOMPurify.sanitize(parseMarkdown);
  return (
    <Template
      title={t("Cost.Title")}
      description={t("Cost.Description")}
      keywords={keywords}
      imageOptions={imageOptions}
    >
      <article dangerouslySetInnerHTML={{ __html: cleanMarkdown }}></article>
    </Template>
  );
};

export default Process;
