import React from "react";
import FormButton from "../../../../reusables/formButton";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../hooks";
import { closeCourse } from "../../../../api/courseApi";

const OpenForm = () => {
	const { userId } = useParams<{ userId: string }>();
	const { data, status, message, exec: initCloseCourse } = useApi(closeCourse);

	const submitForm = (course: string) => async (e: React.MouseEvent<HTMLInputElement>) => {
		e.preventDefault();
		initCloseCourse(parseInt(userId), parseInt(course));
	};

	return (
		<div>
			<h2>Close</h2>
			<div>
				<FormButton size="small" name="1" text="Close Ökuskóli 1" onClick={submitForm("1")} />
			</div>
			<div style={{ marginTop: "10px", marginBottom: "5px" }}>
				<FormButton size="small" name="2" text="Close Ökuskóli 2" onClick={submitForm("2")} />
			</div>
			STATUS: {status}
			{JSON.stringify({ data, status, message })}
		</div>
	);
};

export default OpenForm;
