import React, { FC, useState } from "react";
import { UserTestQuestions } from "../../../../store/test";
import FormButton from "../../../../../reusables/formButton";
import { useTranslation } from "react-i18next";
import { useGetLang } from "../../../../../hooks/useGetLang";
import { StepQuestions } from "../../../../store/steps";

export const translations = {
  is: {
    CorrectAnswers: {
      "Fela rétt svar": "Fela rétt svar",
      "Sýna rétt svar": "Sýna rétt svar",
      "Rétt svar er": "Rétt svar er:",
    },
  },
  en: {
    CorrectAnswers: {
      "Fela rétt svar": "Hide correct answer",
      "Sýna rétt svar": "Show correct answer",
      "Rétt svar er": "Correct answer is:",
    },
  },
};

interface CorrectAnswersProps {
  question: UserTestQuestions | StepQuestions;
  stepQuestion?: boolean;
}

const CorrectAnswers: FC<CorrectAnswersProps> = ({
  question,
  stepQuestion = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const lang = useGetLang();
  const key = stepQuestion ? "step_answers" : "answers";

  const text = isOpen
    ? t("CorrectAnswers.Fela rétt svar")
    : t("CorrectAnswers.Sýna rétt svar");

  return (
    <div style={{ marginTop: "7px" }}>
      <FormButton
        size="small"
        text={text}
        onClick={() => setIsOpen((prevState) => !prevState)}
      />
      {isOpen && <p>{t("CorrectAnswers.Rétt svar er")} </p>}
      {isOpen &&
        question[key].map((answer, answerIndex) => {
          const answerId = `a${answer.id}`;
          const isRight = answer.correct;

          return (
            <li
              className={`checkbox-button-container correct`}
              key={answerIndex}
              style={{ width: "100%" }}
            >
              <label htmlFor={answerId}>
                <input
                  className="checkbox-button"
                  type="checkbox"
                  name={question.id.toString()}
                  checked={isRight}
                  disabled={true}
                  value={answer.id}
                  id={answerId}
                />
                <span>{lang === "is" ? answer.answer : answer.answer_en}</span>
              </label>
            </li>
          );
        })}
    </div>
  );
};

export default CorrectAnswers;
