import React from "react";
import CourseWrapper from "./../CourseWrapper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Okuskoli1Info: {
      MetaTitle: "Ö1 | Greiðsla",
      "Aðeins um Ökuskóla 1": "Aðeins um Ökuskóla 1",
      "Um hvað fjallar Ökuskóli 1": "Um hvað fjallar Ökuskóli 1?",
      "Ökuskóla 1 er skipt upp í 6 lotur. Hver lota hefur sitt þema":
        "Ökuskóla 1 er skipt upp í 6 lotur. Hver lota hefur sitt þema:",
      Ökunámsferlið: "Ökunámsferlið",
      "Bíll og búnaður": "Bíll og búnaður",
      Umferðarheild: "Umferðarheild",
      Umferðarreglur: "Umferðarreglur",
      "Viðhorf gagnvart umferðinni": "Viðhorf gagnvart umferðinni",
      Umferðarskilti: "Umferðarskilti",
      TextBlock1:
        "Hver lota er skipt upp í 10 skref sem innihalda texta, spurningar og myndir. Ef þú lendir í vandræðum getur þú alltaf haft samband við Hilmar Guðjónsson, ökukennarann okkar.",
      TextBlock2:
        "Þegar þú klárar lotu þarf að bíða í 21 klukkutíma áður en næsta lota opnist. Þetta er gert til að fylgja reglugerðum Samgögustofu.",
      "Hafðu í huga að": "Hafðu í huga að",
      TextBlock3:
        "Samkvæmt reglugerðum þarf að klára ökuskólann 30 dögum frá fyrstu innskráningu. Ef þú hefur ekki klárað ökuskólann eftir 30 daga getur þú endurtekið námskeiðið hjá okkur frítt.",
      "Allt klárt": "Allt klárt?",
      "Þá er bara eftir að greiða og svo getur þú byrjað":
        "Þá er bara eftir að greiða og svo getur þú byrjað!",
      "Áfram í greiðslu": "Áfram í greiðslu",
    },
  },
  en: {
    Okuskoli1Info: {
      MetaTitle: "Driving School 1 | Payment",
      "Aðeins um Ökuskóla 1": "About Driving School 1",
      "Um hvað fjallar Ökuskóli 1": "What is covered in Driving School 1?",
      "Ökuskóla 1 er skipt upp í 6 lotur. Hver lota hefur sitt þema":
        "Driving School 1 is split into 6 rounds. Each round has a seperate theme:",
      Ökunámsferlið: "The process of learning to drive",
      "Bíll og búnaður": "Cars and Equipment",
      Umferðarheild: "Traffic as a whole",
      Umferðarreglur: "Traffic laws",
      "Viðhorf gagnvart umferðinni": "Behaviour in traffic",
      Umferðarskilti: "Traffic signs",
      TextBlock1:
        "Each round is split into 10 steps that have text, questions and images. You can always contact us if something is unclear or you don't understand something.",
      TextBlock2:
        "When you finish a round it will be 21 hours before the next round opens. This is done to follow rules set us by the Ministry of Transport.",
      "Hafðu í huga að": "Keep in mind",
      TextBlock3:
        "You need to finish the school within 30 days. But if you don't manage to finish it you can always repeat the course with us for free.",
      "Allt klárt": "Ready?",
      "Þá er bara eftir að greiða og svo getur þú byrjað":
        "Then all you need to do is purchase the course and begin!",
      "Áfram í greiðslu": "Go to payment",
    },
  },
};

const Okuskoli1Info = () => {
  const { t } = useTranslation();
  return (
    <CourseWrapper title={t("Okuskoli1Info.MetaTitle")} paymentStep="step-1">
      <h1>{t("Okuskoli1Info.Aðeins um Ökuskóla 1")}</h1>
      <h2>{t("Okuskoli1Info.Um hvað fjallar Ökuskóli 1")}</h2>
      <p>
        {t(
          "Okuskoli1Info.Ökuskóla 1 er skipt upp í 6 lotur. Hver lota hefur sitt þema"
        )}{" "}
      </p>
      <ul>
        <li>{t("Okuskoli1Info.Ökunámsferlið")}</li>
        <li>{t("Okuskoli1Info.Bíll og búnaður")}</li>
        <li>{t("Okuskoli1Info.Umferðarheild")}</li>
        <li>{t("Okuskoli1Info.Umferðarreglur")}</li>
        <li>{t("Okuskoli1Info.Viðhorf gagnvart umferðinni")}</li>
        <li>{t("Okuskoli1Info.Umferðarskilti")}</li>
      </ul>
      <p>{t("Okuskoli1Info.TextBlock1")}</p>
      <p>{t("Okuskoli1Info.TextBlock2")}</p>
      <h2>{t("Okuskoli1Info.Hafðu í huga að")}</h2>
      <p>{t("Okuskoli1Info.TextBlock3")}</p>
      <h2>{t("Okuskoli1Info.Allt klárt")} </h2>
      <p>
        {t("Okuskoli1Info.Þá er bara eftir að greiða og svo getur þú byrjað")}
      </p>

      <Link to="/okuskoli-1/skraning" className="btn">
        {t("Okuskoli1Info.Áfram í greiðslu")}{" "}
        <i
          style={{ fontSize: "14px" }}
          className="fa fa-chevron-right"
          aria-hidden="true"
        ></i>
      </Link>
    </CourseWrapper>
  );
};

export default Okuskoli1Info;
