import React from "react";
import CourseWrapper from "../CourseWrapper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Okuskoli2Info: {
      MetaTitle: "Ö2 | Greiðsla",
      "Um Ökuskóla 2": "Aðeins um Ökuskóla 2",
      "Um hvað fjallar Ökuskóli 2": "Um hvað fjallar Ökuskóli 2?",
      "Ökuskóla 2 er skipt upp í 6 lotur. Hver lota hefur sitt þema":
        "Ökuskóla 2 er skipt upp í 6 lotur. Hver lota hefur sitt þema:",
      "Upprifjun á Ökuskóla 1": "Upprifjun á Ökuskóla 1",
      Umferðarsálfræði: "Umferðarsálfræði",
      "Áhættuþættir umferðarinnar": "Áhættuþættir umferðarinnar",
      "Ábyrgð ökumannsins": "Ábyrgð ökumannsins",
      "Opinber viðurlög við brotum": "Opinber viðurlög við brotum",
      "Slys og skyndihjálp": "Slys og skyndihjálp",
      TextBlock1:
        "Hver lota er skipt upp í 10 skref sem innihalda texta, spurningar og myndir. Ef þú lendir í vandræðum getur þú alltaf haft samband við Hilmar Guðjónsson, ökukennarann okkar.",
      TextBlock2:
        "Þegar þú klárar lotu þarf að bíða í 21 klukkutíma áður en næsta lota opnist. Þetta er gert til að fylgja reglugerðum Samgögustofu.",
      "Hafðu í huga að": "Hafðu í huga að",
      TextBlock3:
        "Samkvæmt reglugerðum þarf að klára ökuskólann 30 dögum frá fyrstu innskráningu. Ef þú hefur ekki klárað ökuskólann eftir 30 daga getur þú endurtekið námskeiðið hjá okkur frítt.",
      "Allt klárt": "Allt klárt?",
      "Þá er bara eftir að greiða og svo getur þú byrjað":
        "Þá er bara eftir að greiða og svo getur þú byrjað!",
      "Áfram í greiðslu": "Áfram í greiðslu",
    },
  },
  en: {
    Okuskoli2Info: {
      MetaTitle: "Driving School 2 | Payment",
      "Um Ökuskóla 2": "About Driving School 2",
      "Um hvað fjallar Ökuskóli 2": "What is covered in Driving School 2?",
      "Ökuskóla 2 er skipt upp í 6 lotur. Hver lota hefur sitt þema":
        "Driving School 2 is split into 6 rounds. Each round has a seperate theme:",
      "Upprifjun á Ökuskóla 1": "Driving School 1 Revision",
      Umferðarsálfræði: "Traffic psychology",
      "Áhættuþættir umferðarinnar": "Traffic risk factors",
      "Ábyrgð ökumannsins": "The driver's responsibility",
      "Opinber viðurlög við brotum": "Penalties for traffic violations",
      "Slys og skyndihjálp": "Accidents and first-aid",
      TextBlock1:
        "Each round is split into 10 steps that have text, questions and images. You can always contact us if something is unclear or you don't understand something.",
      TextBlock2:
        "When you finish a round it will be 21 hours before the next round opens. This is done to follow rules set us by the Ministry of Transport.",
      "Hafðu í huga að": "Keep in mind",
      TextBlock3:
        "You need to finish the school within 30 days. But if you don't manage to finish it you can always repeat the course with us for free.",
      "Allt klárt": "Ready?",
      "Þá er bara eftir að greiða og svo getur þú byrjað":
        "Then all you need to do is purchase the course and begin!",
      "Áfram í greiðslu": "Go to payment",
    },
  },
};

const Okuskoli2Info = () => {
  const { t } = useTranslation();

  return (
    <CourseWrapper title={`Ö2 | Greiðsla`} paymentStep="step-1">
      <div className="payment-info-text">
        <h1>{t("Okuskoli2Info.Um Ökuskóla 2")}</h1>
        <p>
          {t(
            "Okuskoli2Info.Ökuskóla 2 er skipt upp í 6 lotur. Hver lota hefur sitt þema"
          )}
        </p>
        <ul>
          <li>{t("Okuskoli2Info.Upprifjun á Ökuskóla 1")}</li>
          <li>{t("Okuskoli2Info.Umferðarsálfræði")}</li>
          <li>{t("Okuskoli2Info.Áhættuþættir umferðarinnar")}</li>
          <li>{t("Okuskoli2Info.Ábyrgð ökumannsins")}</li>
          <li>{t("Okuskoli2Info.Opinber viðurlög við brotum")}</li>
          <li>{t("Okuskoli2Info.Slys og skyndihjálp")}</li>
        </ul>
        <p>{t("Okuskoli2Info.TextBlock1")}</p>
        <p>{t("Okuskoli2Info.TextBlock2")}</p>
        <h2>{t("Okuskoli2Info.Hafðu í huga að")}</h2>
        <p>{t("Okuskoli2Info.TextBlock3")}</p>
        <h2>{t("Okuskoli2Info.Allt klárt")}</h2>
        <p>
          {t("Okuskoli2Info.Þá er bara eftir að greiða og svo getur þú byrjað")}
        </p>

        <Link to="/okuskoli-2/skraning" className="btn">
          {t("Okuskoli2Info.Áfram í greiðslu")}{" "}
          <i
            style={{ fontSize: "14px" }}
            className="fa fa-chevron-right"
            aria-hidden="true"
          ></i>
        </Link>
      </div>
    </CourseWrapper>
  );
};

export default Okuskoli2Info;
