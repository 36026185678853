import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Pages } from "../../routes/routes";
import LangToggle from "./LangToggle";

const MobileMenu = () => {
  const { t } = useTranslation();

  const history = useHistory();

  // Closes the menu when someone clicks a new link.
  // If you delete the below the user will be taken
  // To a new page but the menu won't close.
  useEffect(() => {
    history.listen(() => {
      closeMenu();
    });
  }, [history]);

  const closeMenu = () => {
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("show-nav")) {
      body.classList.remove("show-nav");
      body.classList.add("hide-nav");
    }
  };

  const handleMenuToggle = (event: React.SyntheticEvent) => {
    event.preventDefault();

    // Get HTML body element and whack a new class on it.
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("show-nav")) {
      body.classList.remove("show-nav");
      body.classList.add("hide-nav");
    } else {
      body.classList.remove("hide-nav");
      body.classList.add("show-nav");
    }
  };

  return (
    <div className="mobile-menu">
      <nav id="body-nav">
        <ul className="mobile-link-container">
          <li>
            <Link to={t(Pages.Registration)}>{t("Navigation.Nýskráning")}</Link>
          </li>
          <li>
            <Link to={t(Pages.Login)}>{t("Navigation.Innskráning")}</Link>
          </li>
          <li>
            <Link to={t(Pages.DrivingInstructors)}>
              {t("Navigation.Ökukennari")}
            </Link>
          </li>
          <li>
            <Link to={t(Pages.DrivingSchool1)}>
              {t("Navigation.Ökuskóli 1")}
            </Link>
          </li>
          <li>
            <Link to={t(Pages.DrivingSchool2)}>
              {t("Navigation.Ökuskóli 2")}
            </Link>
          </li>
          <li style={{ marginTop: "40px" }}>
            <LangToggle />
          </li>
        </ul>
      </nav>
      <a href="#" className="toggle-nav">
        <div className="mobile-nav">
          <div className="menu-btn" onClick={handleMenuToggle}>
            <p className="assistive-text">Navigation</p>
            <div></div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default MobileMenu;
