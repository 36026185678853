import React, { useState, useEffect } from "react";
import axios from "axios";
import CourseWrapper from "../../../CourseWrapper";
import {
  updateTestAnswer,
  updateTestAnswers,
  updateTestData,
} from "../../../store/redux";
import { connect } from "react-redux";
import TestQuestions from "./TestQuestions";
import { Dispatch } from "redux";
import { AuthReducerTypes, IndexReducerTypes } from "../../../store/types";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Test: {
      Svara: "Svara",
      "Taka aftur": "Taka aftur",
      "Til baka": "Til baka",
      Lota: "Lota",
      Krossapróf: "Krossapróf",
      Próf: "Próf",
      "Við hverja spurningu getur verið fleiri en eitt svar":
        "Við hverja spurningu getur verið fleiri en eitt svar.",
      "Til þess að ljúka lotunni þarf að svara öllum spurningunum rétt. En þú getur endurtekið krossaprófið eins oft og þú vilt":
        "Til þess að ljúka lotunni þarf að svara öllum spurningunum rétt. En þú getur endurtekið krossaprófið eins oft og þú vilt.",
      "Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið lotu":
        "Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið lotu",
      "Fara í lokapróf": "Fara í lokapróf",
      "Aftur á forsíðu": "Aftur á forsíðu",
      "Þú varst með": "Þú varst með",
      "Þú átt eftir að svara": "Þú átt eftir að svara",
      spurningu: "spurningu",
      spurningum: "spurningum",
      villur: " villur",
      villu: " villu",
    },
  },
  en: {
    Test: {
      Svara: "Submit answers",
      "Taka aftur": "Repeat test",
      "Til baka": "Back",
      Lota: "Round",
      Krossapróf: "Test",
      Próf: "Test",
      "Við hverja spurningu getur verið fleiri en eitt svar":
        "Each question can have multiple correct answers.",
      "Til þess að ljúka lotunni þarf að svara öllum spurningunum rétt. En þú getur endurtekið krossaprófið eins oft og þú vilt":
        "You have to answer all the questions correctly to finish the round. But you can repeat the test as often as you need to.",
      "Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið lotu":
        "Well done! You answered all the questions correctly and have now finished round",
      "Fara í lokapróf": "Go to final test",
      "Aftur á forsíðu": "Back to dashboard",
      "Þú varst með": "You had",
      "Þú átt eftir að svara": "You haven't answered questions",
      spurningu: "spurningu",
      spurningum: "spurningum",
      villur: " incorrect answers",
      villu: " incorrect answer",
    },
  },
};

interface TestProps
  extends RouteComponentProps<{ courseId: string; roundNr: string }> {
  dispatch: Dispatch;
  authReducer: AuthReducerTypes;
}

const SubmitTestButton = ({
  submitTest,
  isTestFinished,
}: {
  submitTest: () => void;
  isTestFinished: boolean;
}) => {
  const { t } = useTranslation();
  if (isTestFinished) {
    return null;
  }

  return (
    <button type="submit" id="submitTest" className="btn" onClick={submitTest}>
      {t("Test.Svara")}
    </button>
  );
};

const RetakeTestButton = ({
  retakeTest,
  isTestFinished,
}: {
  retakeTest: () => void;
  isTestFinished: boolean;
}) => {
  const { t } = useTranslation();

  if (!isTestFinished) {
    return null;
  }

  return (
    <button type="submit" id="retake" className="btn" onClick={retakeTest}>
      {t("Test.Taka aftur")}
    </button>
  );
};

const Test = ({ match, dispatch, authReducer, history }: TestProps) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { testAnswers, userTest, wrongTestAnswers, userRounds } = authReducer;
  const { courseId, roundNr } = match.params;

  useEffect(() => {
    fetchTestQuestions();
  }, []);

  const fetchTestQuestions = () => {
    axios
      .get(`/api/test?course=${courseId}&round=${roundNr}`)
      .then((res) => {
        dispatch(updateTestData({ ...res.data }));
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const answerSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateTestAnswer({
        testAnswerId: e.target.value,
        testQuestionId: e.currentTarget.name,
        testId: e.currentTarget.id,
        testChecked: e.target.checked,
      })
    );
  };

  const prevStep = () => {
    history.push(`/okuskoli-${courseId}/lota-${roundNr}/skref-10`);
  };

  const getRoundId = () => {
    let roundId;
    userRounds.forEach((round) => {
      const { course_id, round_id, nr } = round;
      if (course_id === parseInt(courseId) && nr === parseInt(roundNr)) {
        roundId = round_id;
      }
    });

    return roundId;
  };

  const submitTest = () => {
    // If there are unanswered questions
    if (testAnswers.length < 10) {
      const unansweredQuestions: any[] = [];

      authReducer.test[0].questions.forEach((question, index) => {
        let hasBeenAnswered = false;
        testAnswers.forEach(function (answer) {
          if (question.id.toString() === answer.questionId) {
            hasBeenAnswered = true;
          }
        });
        if (hasBeenAnswered == false) {
          const questionNumber = index + 1;
          unansweredQuestions.push(" " + questionNumber);
        }
      });

      const tmp =
        unansweredQuestions.length === 1
          ? t("Test.spurningu")
          : t("Test.spurningum");
      const message = `${t(
        "Test.Þú átt eftir að svara"
      )} ${tmp} ${unansweredQuestions.toString()}`;
      setErrorMessage(message);
    } else {
      const testId = authReducer.test[0].id;
      const roundId = getRoundId();

      axios
        .post(`/api/submit-test`, {
          answers: testAnswers,
          testId: testId,
          userTestId: userTest.id,
          roundId: roundId,
        })
        .then((res) => {
          dispatch(updateTestAnswers({ ...res.data, roundNr }));
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  };

  const retakeTest = () => fetchTestQuestions();
  const goToFinalTest = () => history.push(`/okuskoli-${courseId}/lokaprof`);

  let disabled = false;
  if (userTest !== null && userTest.status == "Finished") {
    disabled = true;
  }

  const test = authReducer.test[0];

  let wrongAnswers = null;
  let roundFinishedFeedback = null;
  let roundFinishedButton = null;
  if (wrongTestAnswers !== null && wrongTestAnswers > 0) {
    let wrongAnswerCount = t("Test.villur");
    if (wrongTestAnswers == 1) {
      wrongAnswerCount = t("Test.villu");
    }
    wrongAnswers = (
      <div className="negative-alert">
        <p>
          <i className="fa fa-frown-o"></i>{" "}
          <span>
            {`${t(
              "Test.Þú varst með"
            )} ${wrongTestAnswers} ${wrongAnswerCount}`}
          </span>
        </p>
      </div>
    );
  } else if (userTest.status === "Finished") {
    userRounds.forEach((round) => {
      if (round.nr === parseInt(roundNr)) {
        if (round.status === "Finished") {
          roundFinishedFeedback = (
            <div className="positive-alert">
              <p>
                <i className="fa fa-smile-o"></i>{" "}
                <span>
                  {`${t(
                    "Test.Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið lotu"
                  )} ${parseInt(roundNr)}!`}
                </span>
              </p>
            </div>
          );
          if (parseInt(roundNr) === 6) {
            roundFinishedButton = (
              <button
                type="submit"
                id="goToFinalTest"
                className="btn"
                onClick={goToFinalTest}
              >
                {t("Test.Fara í lokapróf")}
              </button>
            );
          } else {
            roundFinishedButton = (
              // It's an <a> tag for a reason - changing to other will introduce bug.
              <a href={"/okuskoli-" + courseId} id="backToMain" className="btn">
                {t("Test.Aftur á forsíðu")}
              </a>
            );
          }
        }
      }
    });
  }

  return (
    <CourseWrapper
      courseId={courseId}
      title={`${t("Test.Próf")} | ${t("Test.Lota")} ${roundNr} | Ö${courseId}`}
    >
      <div className="test-container">
        <h2>
          {t("Test.Lota")} {roundNr} | {t("Test.Krossapróf")}
        </h2>
        <p>{t("Test.Við hverja spurningu getur verið fleiri en eitt svar")}</p>
        <p>
          {t(
            "Test.Til þess að ljúka lotunni þarf að svara öllum spurningunum rétt. En þú getur endurtekið krossaprófið eins oft og þú vilt"
          )}
        </p>
        <ul className="questionRow-ul">
          {test && (
            <TestQuestions
              testAnswers={testAnswers}
              userTest={userTest}
              disabled={disabled}
              test={test}
              answerSelected={answerSelected}
              testType={"RoundTest"}
            />
          )}
        </ul>
        <div>
          <span>{errorMessage}</span>
        </div>
        {wrongAnswers}
        {roundFinishedFeedback}
        <div className="btn-div">
          <button
            type="submit"
            id="previous"
            className="btn"
            onClick={prevStep}
          >
            {t("Test.Til baka")}
          </button>
          <SubmitTestButton
            submitTest={submitTest}
            isTestFinished={userTest.status === "Finished"}
          />
          <RetakeTestButton
            retakeTest={retakeTest}
            isTestFinished={userTest.status === "Finished"}
          />
          {roundFinishedButton}
        </div>
      </div>
    </CourseWrapper>
  );
};

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Test);
