import React, { Component, Ref } from "react";
import Message from "./Message";
import MessageForm from "./MessageForm";
import axios from "axios";
import { connect } from "react-redux";
import { addNewMessage } from "../../../store/redux";
import { Dispatch } from "redux";
import { AuthReducerTypes, IndexReducerTypes, Message as MessageType } from "../../../store/types";

interface ChatboxProps {
	dispatch: Dispatch;
	messages: MessageType[];
	authReducer: AuthReducerTypes;
}

interface ChatboxState {}

class Chatbox extends Component<ChatboxProps, ChatboxState> {
	private myRef = React.createRef<HTMLDivElement>();

	constructor(props: ChatboxProps) {
		super(props);
		// this.myRef = React.createRef<HTMLDivElement>();
		this.scrollToBottom = this.scrollToBottom.bind(this);
		this.onMessageSubmit = this.onMessageSubmit.bind(this);
	}

	scrollToBottom() {
		if (this.myRef.current) this.myRef.current.scrollTo({ top: 1000 });
	}

	componentDidUpdate() {
		this.scrollToBottom();
	}

	componentDidMount() {
		this.scrollToBottom();
	}

	onMessageSubmit(message: { text: string }) {
		const { dispatch } = this.props;
		const { name } = this.props.authReducer;

		axios
			.post("/api/send-message", { messageText: message.text })
			.then((res) => {
				const { message_text, created_date } = res.data.message;
				dispatch(
					addNewMessage({
						messageTime: created_date,
						text: message_text,
						user: name,
					}),
				);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	render() {
		const { messages } = this.props;

		return (
			<div>
				<div id="chat-box" ref={this.myRef}>
					{messages.map(function (message, index) {
						return (
							<Message
								key={index}
								index={index}
								user={message.user}
								text={message.text}
								time={message.messageTime}
							/>
						);
					})}
				</div>
				<div>
					<MessageForm onMessageSubmit={this.onMessageSubmit} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IndexReducerTypes) => {
	const { authReducer } = state;
	return {
		authReducer,
	};
};

export default connect(mapStateToProps)(Chatbox);
