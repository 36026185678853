import api from "./api";

const URLS = {
  openCourseUrl: "admin/open-course",
  closeCourseUrl: "admin/close-course",
  confirmCourse: "admin/confirm-course",
  getUserDetailsUrl: "admin/user-details",
  getUsersUrl: "admin/users",
  getUserSettingsUrl: "user-information",
};

export const getUserSettings = () => {
  return api.get(URLS.getUserSettingsUrl);
};

export const getUsers = () => {
  return api.get(URLS.getUsersUrl);
};

export const getUserDetails = (userId: number) => {
  return api.post(URLS.getUserDetailsUrl, {
    userId,
  });
};

export const closeCourse = (userId: number, course: number) => {
  return api.post(URLS.closeCourseUrl, {
    course,
    userId,
  });
};

export const openCourse = (
  course: number,
  paymentType: string,
  amount: number,
  userId: number
) => {
  return api.post(URLS.openCourseUrl, {
    course,
    paymentType,
    amount,
    userId,
  });
};

export const confirmCourse = (course: number, userId: number) => {
  return api.post(URLS.confirmCourse, {
    course,
    userId,
  });
};
