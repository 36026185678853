import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { IndexReducerTypes, AuthReducerTypes } from "../../store/types";
import { Dispatch } from "redux";
import { toggleDyslexiaFont } from "../../store/redux";
import { Switch } from "../../../reusables/switch";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    DyslexiaSetting: {
      "OpenDyslexic lesblindutexti": "OpenDyslexic lesblindutexti",
      "Nota lesblindu texta": "Nota lesblindu texta",
    },
  },
  en: {
    DyslexiaSetting: {
      "OpenDyslexic lesblindutexti": "OpenDyslexic dyslexia typeface",
      "Nota lesblindu texta": "Use dyslexia typeface",
    },
  },
};

const DyslexiaSetting = ({
  authReducer,
  dispatch,
}: {
  authReducer: AuthReducerTypes;
  dispatch: Dispatch;
}) => {
  // This should probably be in global state
  const { dyslexiaFont } = authReducer;
  const { t } = useTranslation();

  const updateDyslexicFont = async () => {
    // Make a request to the API
    // Setting to true or false.
    // Then udpate the UI.
    await axios.post("/api/user-information", {
      field: "dyslexia_font",
      value: !dyslexiaFont,
    });
    dispatch(toggleDyslexiaFont());
  };

  return (
    <>
      <p className="mt-0 mb-2 font-bold">
        {t("DyslexiaSetting.OpenDyslexic lesblindutexti")}
      </p>
      <div onClick={updateDyslexicFont}>
        <Switch checked={dyslexiaFont} />
        <span className="ml-2">
          {t("DyslexiaSetting.Nota lesblindu texta")}
        </span>
      </div>
    </>
  );
};
const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(DyslexiaSetting);
