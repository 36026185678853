import axios, { AxiosInstance } from "axios";

const axiosParams = {
	baseURL: "/api/",
};

const axiosInstance = axios.create(axiosParams);

const api = (axios: AxiosInstance) => {
	return {
		get: (url: string, config = {}) => axios.get(url, config),
		post: (url: string, body: Object, config = {}) => axios.post(url, body, config),
		patch: (url: string, body: Object, config = {}) => axios.patch(url, body, config),
		delete: (url: string, config = {}) => axios.delete(url, config),
	};
};

export default api(axiosInstance);
