import React, { useState } from "react";
import Button from "../../../reusables/formButton";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Feedback: {
      "Ábending?": "Ábending?",
      "Er eithvað vitlaust eða ruglandi?": "Er eithvað vitlaust eða ruglandi?",
      "Sendi...": "Sendi...",
      "Skeyti sent! Takk fyrir ábendinguna":
        "Skeyti sent! Takk fyrir ábendinguna.",
      Senda: "Senda",
      "Villa!": "Villa!",
    },
  },
  en: {
    Feedback: {
      "Ábending?": "Feedback",
      "Er eithvað vitlaust eða ruglandi?":
        "Is there anything we could do better?",
      "Sendi...": "Sending...",
      "Skeyti sent! Takk fyrir ábendinguna":
        "Message sent! Thank you for your feedback.",
      Senda: "Send",
      "Villa!": "Error!",
    },
  },
};

const FeedbackBox = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  function submitFeedback() {
    setRequestStatus("loading");
    axios
      .post(`/api/send-feedback`, {
        messageText: text,
        path: location.pathname,
      })
      .then((res) => {
        console.log(res);
        setRequestStatus("success");
        setText("");
      })
      .catch((error) => {
        setRequestStatus("error");
        setErrorMessage(error.response.data);
        console.error(error.response);
      });
  }

  function handleOpen() {
    if (isOpen === false) {
      setIsOpen(true);
    }
  }

  function handleClose() {
    setRequestStatus("");
    setErrorMessage("");
    setIsOpen(false);
  }

  let classNames;
  if (isOpen) {
    classNames = "feedback-box no-pointer open";
  } else {
    classNames = "feedback-box";
  }

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setText(event.target.value);
  }

  return (
    <div className={classNames} onClick={handleOpen}>
      <p className="title">
        <i className="fa fa-comment" aria-hidden="true"></i>
        {t("Feedback.Ábending?")}
      </p>
      {isOpen ? (
        <p className="close" onClick={handleClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </p>
      ) : (
        ""
      )}
      {isOpen ? (
        <>
          <p>{t("Feedback.Er eithvað vitlaust eða ruglandi?")}</p>{" "}
          <textarea
            rows={4}
            cols={40}
            value={text}
            onChange={handleChange}
          ></textarea>
          <div>
            <Button
              text={t("Feedback.Senda")}
              onClick={submitFeedback}
              className="secondary-btn"
            />
          </div>
          {requestStatus === "loading" ? <p>{t("Feedback.Sendi...")}</p> : ""}
          {requestStatus === "success" ? (
            <p>{t("Feedback.Skeyti sent! Takk fyrir ábendinguna")}</p>
          ) : (
            ""
          )}
          {requestStatus === "error" ? (
            <p>
              {t("Feedback.Villa!")} {errorMessage}
            </p>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default FeedbackBox;
