import React, { useEffect } from "react";
import DesktopMenu from "./Navigation";
import MobileMenu from "./MobileMenu";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useGetLang } from "../../hooks/useGetLang";

export const translations = {
  is: {
    NotFound: {
      "404 - Síða er ekki til": "404 - Síða er ekki til",
      "Á hvaða leið varst þú félagi?": "Á hvaða leið varst þú félagi?",
      "Þessi síða er ekki til!": "Þessi síða er ekki til!",
    },
  },
  en: {
    NotFound: {
      "404 - Síða er ekki til": "404 - Page not found",
      "Á hvaða leið varst þú félagi?": "It's a dead end.",
      "Þessi síða er ekki til!": "This page doesn't exist! :-/",
    },
  },
};

const NotFound = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const lang = useGetLang();

  // Change the language, in case the route exists but in a different language
  // This is important for the pre-renderer.
  const changeLanguage = () => {
    const newLang = lang === "en" ? "is" : "en";
    i18n.changeLanguage(newLang);
    localStorage.setItem("language", newLang);
  };

  useEffect(() => {
    changeLanguage();
  }, []);

  return (
    <div className="site-wrap">
      <Helmet>
        <title>404 | Urðarbrunnur Netökuskóli</title>
      </Helmet>
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body">
          <div className="default-text-container">
            <h1>{t("NotFound.404 - Síða er ekki til")}</h1>
            <p>{t("NotFound.Á hvaða leið varst þú félagi?")}</p>
            <p>{t("NotFound.Þessi síða er ekki til!")}</p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NotFound;
