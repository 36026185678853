import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pages } from "../../routes/routes";

export const translations = {
  is: {
    BlogOverview: {
      Title: "Fróðleikur um ökunámið",
      CostUpdated: "Uppfært 7. janúar 2025",
      ProcessUpdated: "Uppfært 15. desember 2023",
      UnionsUpdated: "Uppfært 31. október 2024",
    },
  },
  en: {
    BlogOverview: {
      Title: "Learning to drive in Iceland",
      CostUpdated: "Updated January 7, 2025",
      ProcessUpdated: "Updated December 15, 2023",
      UnionsUpdated: "Updated October 31, 2024",
    },
  },
};

const BlogOverview = () => {
  const { t } = useTranslation();

  const articles = [
    {
      title: t("Cost.Title"),
      description: t("Cost.Description"),
      updated: t("BlogOverview.CostUpdated"),
      image: "/images/blogg/bill.jpg",
      link: t(Pages.BlogCostOfLicense),
    },
    {
      title: t("Process.Title"),
      description: t("Process.Description"),
      updated: t("BlogOverview.ProcessUpdated"),
      image: "/images/blogg/bilprof.jpg",
      link: t(Pages.BlogHowToGetLicense),
    },
    {
      title: t("Unions.Title"),
      description: t("Unions.Description"),
      updated: t("BlogOverview.UnionsUpdated"),
      image: "/images/blogg/van-in-iceland.jpg",
      link: t(Pages.HowUnionsHelp),
    },
  ];

  return (
    <div className="blog-overview">
      <h2>{t("BlogOverview.Title")}</h2>
      <div className="blog-grid">
        {articles.map((article, index) => (
          <Link to={article.link} key={index} className="blog-card">
            <div className="blog-image">
              <img src={article.image} alt={article.title} />
            </div>
            <div className="blog-content">
              <div>
                <h3>{article.title}</h3>
                <p className="subtitle">{article.description}</p>
              </div>
              <p className="last-updated">{article.updated}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogOverview;
