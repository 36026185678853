/* eslint-env browser */
import React, { useEffect } from "react";
import CourseWrapper from "../../CourseWrapper";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { okuskoliData, showAlert as showAlertFn } from "../../store/redux";
import { DaysLeft } from "./DaysLeft";
import { StartAgain } from "./StartAgain";
import { Alert } from "./Alert";
import { NextRoundButton } from "./NextRoundButton";
import MainText from "./MainText";
import { AuthReducerTypes, IndexReducerTypes } from "../../store/types";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { useInterval } from "../../../hooks/useInterval";

export const translations = {
  is: {
    Main: {
      Ökuskóli: "Ökuskóli",
      "Aftur á stjórnborð": "Aftur á stjórnborð",
      Ö: "Ö",
    },
  },
  en: {
    Main: {
      Ökuskóli: "Driving School",
      "Aftur á stjórnborð": "Back to dashboard",
      Ö: "DS",
    },
  },
};

interface Params {
  courseId: string;
}

interface MainProps extends RouteComponentProps<Params> {
  authReducer: AuthReducerTypes;
  dispatch: Dispatch;
}

const Main = ({ authReducer, match, dispatch }: MainProps) => {
  const { courseId } = match.params;
  const {
    currentStep,
    currentRoundStatus,
    sinceFirstStart,
    sinceSecondStart,
    extraDaysFirst,
    extraDaysSecond,
    roundsFinished,
    userCourses,
    showAlert,
    timerMinutes,
    timerHours,
    messages,
    messageFeatureUsed,
    currentUserRounds,
    nextRoundOpen,
    currentRound,
    id,
  } = authReducer;
  const { t } = useTranslation();

  const fetchCourseData = () => {
    axios
      .post(`/api/sub-dashboard`, { userId: id, courseId: parseInt(courseId) })
      .then((res) => {
        dispatch(okuskoliData({ ...res.data }));
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  // Takes userRounds and spits out
  const setUpTicker = () => {
    if (!nextRoundOpen) {
      currentUserRounds.forEach((round, index) => {
        const lastFinishedTime = currentUserRounds[index].finished_time;
        if (
          parseInt(courseId) === round.course_id &&
          round.nr === currentRound &&
          lastFinishedTime
        ) {
          const dateDiff = msToTime(
            new Date().getTime() - new Date(lastFinishedTime).getTime()
          );
          const hours = dateDiff[0];
          const minutes = dateDiff[1];
          const newHour = hours - 3;
          dispatch(showAlertFn({ timerHours: newHour, timerMinutes: minutes }));
        }
      });
    }
  };

  useEffect(() => {
    setUpTicker();
  }, [authReducer.nextRoundOpen]);

  useEffect(() => {
    fetchCourseData();
  }, []);

  // Update fetchCourseData every 60 seconds in order to update the
  // "there are 19 hours and 50 minutes until round 2 opens" ticker.
  // This is an easy way to keep things in sync.
  useInterval(() => {
    fetchCourseData();
    setUpTicker();
  }, 1000 * 60);

  const startAgain = () => {
    axios
      .post("/api/restart-course", { courseId: parseInt(courseId) })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  let daysLeft;
  if (parseInt(courseId) === 1) {
    daysLeft = 30 + extraDaysFirst - sinceFirstStart.days;
  } else {
    daysLeft = 30 + extraDaysSecond - sinceSecondStart.days;
  }

  // Set courseStatus var to pass into components below.
  let courseStatus: "Finished" | "Expired" | "Not Started" | "Ongoing" =
    "Ongoing";
  userCourses.forEach((course) => {
    if (course.id === parseInt(courseId)) {
      courseStatus = course.status;
    }
  });

  return (
    <CourseWrapper courseId={courseId} title={`${t("Main.Ö")}${courseId}`}>
      <div className="payment-page">
        <h1>
          {t("Main.Ökuskóli")} {courseId}
        </h1>
        <DaysLeft
          daysLeft={daysLeft}
          courseStatus={courseStatus}
          courseId={courseId}
        />
        <div className="subdashboard-btn-container">
          <NextRoundButton
            daysLeft={daysLeft}
            courseId={courseId}
            courseStatus={courseStatus}
            currentRoundStatus={currentRoundStatus}
            nextRoundOpen={nextRoundOpen}
            currentRound={currentRound}
            roundsFinished={roundsFinished}
            currentStep={currentStep}
            messageFeatureUsed={messageFeatureUsed}
          />
          <Link to="/stjornbord" className="secondary-btn">
            <i className="fa fa-tachometer"></i>
            {t("Main.Aftur á stjórnborð")}
          </Link>
          <StartAgain
            daysLeft={daysLeft}
            courseStatus={courseStatus}
            startAgain={startAgain}
          />
        </div>
        <Alert
          showAlert={showAlert}
          courseStatus={courseStatus}
          nextRoundOpen={nextRoundOpen}
          timerMinutes={timerMinutes}
          timerHours={timerHours}
          prevNr={currentRound}
          nr={currentRound + 1}
        />
        <div>
          <MainText courseId={courseId} messages={messages} />
        </div>
      </div>
    </CourseWrapper>
  );
};

function msToTime(duration: number) {
  const minutes = 59 - ((duration / (1000 * 60)) % 60);
  const hours = 23 - ((duration / (1000 * 60 * 60)) % 24);
  return [Math.round(hours), Math.round(minutes)];
}

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Main);
