import React from "react";
import Helmet from "./Helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import DesktopMenu from "./Navigation";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";
import { Pages } from "../../routes/routes";

export const translations = {
  is: {
    About: {
      "Um okkur": "Um okkur",
      "Um okkur texti 1":
        "Urðarbrunnur var stofnaður í ágúst 2015. Bak við skólan standa Freyr Þorvaldsson og Hilmar Guðjónsson. Ökuskólinn Urðarbrunnur er í stöðugri þróun og við leitum sífellt nýrra leiða til að gera hann betri.",
      "Um okkur texti 2":
        "Að læra á netinu hentar mörgum og við svörum þeirri þörf.",
      Teymið: "Teymið",
      "Freyr Title": "Vefforritari og altmuligmand",
      "Hilmar Title": "Skólastjóri og ökukennari",
      "Ökuskóli á netinu": "Ökuskóli á netinu",
      "Skráðu þig í ökuskóla 1": "Skráðu þig í ökuskóla 1",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Einhverjar spurningar? Hringdu í síma 777-9344.",
    },
  },
  en: {
    About: {
      "Um okkur": "About us",
      "Um okkur texti 1":
        "Urðarbrunnur was founded in August 2015.  Urðarbrunnur Driving School is in constant evolution and we're always seeking ways to improve.",
      "Um okkur texti 2": "Studying online suits many and we meet that demand.",
      Teymið: "The team",
      "Freyr Title": "Web developer and generalist",
      "Hilmar Title": "Principal and driving instructor",
      "Ökuskóli á netinu": "Online driving school",
      "Skráðu þig í ökuskóla 1": "Sign up to Driving School 1 (Ökuskóli 1)",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Any questions? Call us at 777-9344.",
    },
  },
};

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="site-wrap">
      <Helmet pageTitle={t("About.Um okkur")} />
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body default-page about-us">
          <div className="default-text-container">
            <h1>{t("About.Um okkur")}</h1>
            <p>{t("About.Um okkur texti 1")}</p>
            <p>{t("About.Um okkur texti 2")}</p>

            <div className="about-us-team">
              <h2>{t("About.Teymið")}</h2>
              <div className="starfsmadur">
                <img src="/images/starfsmenn/hilmar.jpg" />
                <h3>Hilmar Guðjónsson</h3>
                <p>{t("About.Hilmar Title")}</p>
              </div>

              <div className="starfsmadur">
                <img src="/images/starfsmenn/avatar.jpg" />
                <h3>Freyr Þorvaldsson</h3>
                <p>{t("About.Freyr Title")}</p>
              </div>
            </div>

            <div className="button-container">
              <Link className="main-button" to={t(Pages.Registration)}>
                {t("About.Skráðu þig í ökuskóla 1")}
              </Link>
            </div>
            <p className="spurningar-p">
              {t("About.Einhverjar spurningar? Hringdu í síma 777-9344.")}
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default About;
