import React, { FC } from "react";
import { Link } from "react-router-dom";

type ButtonProps = {
  type: "react-router-link" | "link" | "onClick";
  href?: string;
  className?: string;
  size?: "tiny" | "small" | "medium" | "big";
  onClick?: () => void;
  disabled?: boolean; // Only for onClick buttons
};

// Types of buttons:
// 1. react-router Links
// 2. <a></a> tags
// 3. onClick
const Button: FC<ButtonProps> = ({
  href,
  type,
  className,
  size,
  children,
  onClick,
  disabled = false,
}) => {
  // If no button size is specified, default to big.
  let sizeClassName = "";
  if (!size) {
    sizeClassName = "btn-big";
  } else {
    sizeClassName = `btn-${size}`;
  }

  // Return react-router-dom link.
  if (type === "react-router-link" && href !== undefined) {
    return (
      <Link
        to={href}
        id="btn-component"
        className={`${className} ${sizeClassName}`}
      >
        {children}
      </Link>
    );
  }

  if (type === "link") {
    return (
      <a href={href}>
        <div id="btn-component" className={`${className} ${sizeClassName}`}>
          {children}
        </div>
      </a>
    );
  }

  if (type === "onClick") {
    if (onClick === undefined) return <p>Pass in onClick</p>;
    const disabledClass = disabled ? "disabled-btn" : "";

    // Don't allow onClick events if button is disabled
    const buttonOnClick = !disabled ? onClick : undefined;

    return (
      <div
        aria-disabled={disabled}
        id="btn-component"
        onClick={buttonOnClick}
        className={`${className} ${sizeClassName} ${disabledClass}`}
        tabIndex={0}
        onKeyDown={(e) => {
          e.key === "Enter" && !disabled ? onClick() : null;
        }}
      >
        {children}
      </div>
    );
  }

  return null; // To silence TS
};

export default Button;
