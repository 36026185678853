import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import CourseWrapper from "./CourseWrapper";
import { userIsLoggedIn } from "./store/redux";
import Button from "../reusables/button";
import { Dispatch } from "redux";
import {
  AuthReducerTypes,
  IndexReducerTypes,
  UserCourses,
} from "./store/types";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Dashboard: {
      "Kaupa aðgang": "Kaupa aðgang",
      "Smelltu á hnappinn hér fyrir neðan til að kaupa aðgang":
        "Smelltu á hnappinn hér fyrir neðan til að kaupa aðgang.",
      "Þú ert ekki skráð(ur) í Ökuskóla": "Þú ert ekki skráð(ur) í Ökuskóla",
      "Þú hefur lokið Ökuskóla": "Þú hefur lokið Ökuskóla",
      "en þú getur alltaf kíkt inn aftur og skoðað námsefnið ef þig langar að rifja upp efnið":
        "en þú getur alltaf kíkt inn aftur og skoðað námsefnið ef þig langar að rifja upp efnið",
      Skoða: "Skoða",
      "Þetta er allt að koma! Smelltu á hnappinn hér að neðan til að halda áfram með Ökuskóla":
        "Þetta er allt að koma! Smelltu á hnappinn hér að neðan til að halda áfram með Ökuskóla",
      "Halda áfram": "Halda áfram",
      Ökuskóli: "Ökuskóli",
    },
  },
  en: {
    Dashboard: {
      "Kaupa aðgang": "Buy access",
      "Smelltu á hnappinn hér fyrir neðan til að kaupa aðgang":
        "Click on the button below to purchase access.",
      "Þú ert ekki skráð(ur) í Ökuskóla":
        "You are not yet signed up to driving school",
      "Þú hefur lokið Ökuskóla": "You have finished Driving School",
      "en þú getur alltaf kíkt inn aftur og skoðað námsefnið ef þig langar að rifja upp efnið":
        "but you can always access the course again and review the material",
      Skoða: "View course",
      "Þetta er allt að koma! Smelltu á hnappinn hér að neðan til að halda áfram með Ökuskóla":
        "Keep up the good... studying. Click on the button below to continue with Driving School",
      "Halda áfram": "Continue course",
      Ökuskóli: "Driving School",
    },
  },
};

interface DashboardProps {
  dispatch: Dispatch;
  authReducer: AuthReducerTypes;
}

const CourseBox = ({
  authReducer,
  courseNumber,
}: {
  authReducer: AuthReducerTypes;
  courseNumber: 1 | 2;
}) => {
  const { userRounds, userCourses, tests } = authReducer;
  const { t } = useTranslation();
  const progressBar = [];
  let counter = 0;
  let message = null;
  let registered = false;
  let link = `/okuskoli-${courseNumber}`;
  let status = "fa fa-lock";
  let buttonText = t("Dashboard.Kaupa aðgang");

  if (!inObject(userCourses, `Ökuskóli ${courseNumber}`)) {
    message = `${t(
      "Dashboard.Þú ert ekki skráð(ur) í Ökuskóla"
    )} ${courseNumber}. ${t(
      "Dashboard.Smelltu á hnappinn hér fyrir neðan til að kaupa aðgang"
    )}`;
    link = `/okuskoli${courseNumber}-upplysingar`;
  } else {
    const tmp = getCourse(userCourses, `Ökuskóli ${courseNumber}`);
    registered = true;
    if (tmp && tmp.status == "Finished") {
      message = `${t("Dashboard.Þú hefur lokið Ökuskóla")} ${courseNumber} ${t(
        "Dashboard.en þú getur alltaf kíkt inn aftur og skoðað námsefnið ef þig langar að rifja upp efnið"
      )}.`;
      status = "fa fa-check-square-o";
      buttonText = t("Dashboard.Skoða");
    } else {
      message = `${t(
        "Dashboard.Þetta er allt að koma! Smelltu á hnappinn hér að neðan til að halda áfram með Ökuskóla"
      )} ${courseNumber}.`;
      status = "fa fa-square-o";
      buttonText = t("Dashboard.Halda áfram");
    }
  }

  userRounds.forEach((round) => {
    let status = "";
    if (round.name === `Ökuskóli ${courseNumber}`) {
      if (round.status === "Finished") {
        status = "finished";
      } else if (round.status === "Ongoing") {
        status = "active";
      } else if (round.status === "Not Started") {
        status = "inactive";
      }
      progressBar[counter] = (
        <div key={counter} className={"pb-section pb-" + status}></div>
      );
    }
    counter++;
  });

  if (progressBar.length === 0) {
    for (let i = 0; i < 7; i++) {
      progressBar[i] = (
        <div key={"progress-" + i} className={"pb-section pb-inactive"}></div>
      );
    }
  }

  let finalTestStatus = "inactive";
  tests.forEach((test) => {
    if (test.name == `Lokapróf Ö${courseNumber}`) {
      if (test.status == "Finished") {
        finalTestStatus = "finished";
      } else if (test.status == "Ongoing" && finalTestStatus !== "finished") {
        finalTestStatus = "active";
      }
    }
  });

  if (registered) {
    counter++;
    progressBar[counter] = (
      <div
        key={"progress-" + counter}
        className={"pb-section pb-" + finalTestStatus}
      ></div>
    );
  }

  const button = (
    <Button className="mg-bottom-20" type="react-router-link" href={link}>
      {buttonText}
    </Button>
  );

  return (
    <div className="course-box">
      <div className="progressbar">{progressBar}</div>
      <h2>
        <i className={status}></i> {t("Dashboard.Ökuskóli")} {courseNumber}
      </h2>
      <p>{message}</p>
      <div className="btn-div">{button}</div>
    </div>
  );
};

const Dashboard = ({ dispatch, authReducer }: DashboardProps) => {
  // Fetch fresh course data when the dashboard is mounted.
  const getUserCourseData = async () => {
    const response = await axios.get("/api/is-logged-in");
    if (response.status === 200) {
      dispatch(
        userIsLoggedIn({
          ...response.data,
        })
      );
    }
  };

  useEffect(() => {
    getUserCourseData();
  }, []);

  return (
    <CourseWrapper title="Stjórnborð">
      <div className="dashboard-container">
        <CourseBox authReducer={authReducer} courseNumber={1} />
        <CourseBox authReducer={authReducer} courseNumber={2} />
      </div>
    </CourseWrapper>
  );
};

function getCourse(courses: UserCourses[], name: string) {
  for (let i = 0; i < courses.length; i++) {
    if (courses[i].name === name) {
      return courses[i];
    }
  }
}

function inObject(arr: UserCourses[], search: string) {
  let len = arr.length;
  while (len--) {
    if (arr[len].name === search) return true;
  }

  return false;
}

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Dashboard);
