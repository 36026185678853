import React, { useState } from "react";
import FormButton from "../../../../reusables/formButton";
import { useApi } from "../../../../hooks";
import { confirmCourse } from "../../../../api/courseApi";
import { useParams } from "react-router-dom";

const ConfirmForm = () => {
  const { userId } = useParams<{ userId: string }>();
  const [form, setForm] = useState({
    course: "1",
  });

  const { status, message, exec: initConfirmCourse } = useApi(confirmCourse);

  const submitForm = async (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    event.preventDefault();
    initConfirmCourse(form.course, parseInt(userId));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
  };

  const { course } = form;
  return (
    <div>
      <h2>Staðfesta námslok á island.is</h2>
      <div style={{ marginBottom: "5px" }}>
        <label>Course </label>
        <select name="course" onChange={handleSelectChange} value={course}>
          <option value="1">Ökuskóli 1</option>
          <option value="2">Ökuskóli 2</option>
        </select>
      </div>

      <FormButton size="tiny" text="Open course" onClick={submitForm} />
      {status === "SUCCESS" && "Course confirmed"}
      {status === "PENDING" && "Loading"}
      {status === "ERROR" && message}
    </div>
  );
};

export default ConfirmForm;
