import React from "react";
import DesktopMenu from "../Navigation";
import MobileMenu from "../MobileMenu";
import Footer from "../Footer";
import Helmet from "../Helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pages } from "../../../routes/routes";
import HeroSection from "./HeroSection";

export const translations = {
  is: {
    Ö2Page: {
      MetaTitle: "Ökuskóli 2",
      "Ökuskóli 2 á netinu": "Ökuskóli 2 á netinu",
      IntroTexti:
        "Framhald af Ökuskóla 1. Efnið hjá okkur er sett fram á myndrænan og gangvirkan hátt.",
      "Ökuskóli 2": "Ökuskóli 2",
      "Fjarnám Urðarbrunns": "Fjarnám Urðarbrunns",
      FjarnámTexti:
        "Ökuskóli 1 og 2 er hluti af ökunámsferlinu og er stórt skref í átt að bílprófi. Ökuskóli 2 í fjarnámi kemur í staðinn fyrir hefðbundið nám í skólastofu og fer í sama efni.",
      "Hvað er kennt í Ökuskóla 2?": "Hvað er kennt í Ökuskóla 2?",
      "Ökuskóla 2 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni":
        "Ökuskóla 2 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni:",
      "Upprifjun á Ökuskóla 1": "Upprifjun á Ökuskóla 1",
      Umferðarsálfræði: "Umferðarsálfræði",
      "Áhættuþættir umferðarinnar": "Áhættuþættir umferðarinnar",
      "Ábyrgð ökumannsins": "Ábyrgð ökumannsins",
      "Opinber viðurlög við brotum": "Opinber viðurlög við brotum",
      "Slys og skyndihjálp": "Slys og skyndihjálp",
      KaflaprófTexti:
        "Í lok hverrar lotu er 10 spurninga kaflapróf og náminu lýkur með lokaprófi úr öllu efni námskeiðsins.",
      SexDagaNámskeið:
        "Við lok hverrar lotu þarf að bíða í næstum einn sólahring (21 tíma) þar til næsta lota opnast. Þetta gerir það að verkum að námskeiðið tekur minnst sex daga. Þetta er gert til að fylgja reglum Samgöngustofu.",
      "Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina":
        "Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina",
      "Út Í Umferðina": "Út Í Umferðina",
      "handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á":
        "handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á",
      "vefverslun Ökukennarafélagsins": "vefverslun Ökukennarafélagsins",
      Inntökuskilyrði: "Inntökuskilyrði",
      "Til þess að taka þátt í Ökuskóla 2 þarf nemandi að hafa":
        "Til þess að taka þátt í Ökuskóla 2 þarf nemandi að hafa:",
      "Klárað Ökuskóla 1": "Klárað Ökuskóla 1",
      Greiðsla: "Greiðsla",
      GreiðslaText:
        "Greiðsla fer fram eftir að aðgangur hefur verið stofnaður. Hægt er að greiða með greiðslukorti eða millifærslu inná reikning ökuskólans. Frekari upplýsingar um greiðslufyrirkomulag má finna eftir skráningu.",
      "Skráðu þig í Ökuskóla 2": "Skráðu þig í Ökuskóla 2",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Einhverjar spurningar? Hringdu í síma 777-9344.",
    },
  },
  en: {
    Ö2Page: {
      MetaTitle: "Driving School 2",
      "Ökuskóli 2 á netinu": "Driving School 2 online",
      "Ökuskóli 2": "Driving School 2",
      IntroTexti:
        "Driving School 2 is a continuation of Driving School 1 (Ökuskóli 2). Learn through visual content and interactive challenges.",
      "Fjarnám Urðarbrunns": "Learn online with us",
      FjarnámTexti:
        "Driving school 1 and 2 (Ökuskóli 1 and 2) are an integral part of the process to get a drivers license.",
      "Hvað er kennt í Ökuskóla 2?": "What is taught in Driving School 2?",
      "Ökuskóla 2 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni":
        "Driving School 2 is split into 6 rounds. Each round covers a different subject:",
      "Upprifjun á Ökuskóla 1": "Driving School 1 Revision",
      Umferðarsálfræði: "Traffic psychology",
      "Áhættuþættir umferðarinnar": "Traffic risk factors",
      "Ábyrgð ökumannsins": "The driver's responsibility",
      "Opinber viðurlög við brotum": "Penalties for traffic violations",
      "Slys og skyndihjálp": "Accidents and first-aid",
      KaflaprófTexti:
        "At the end of each round is a 10 question quiz and at the end of the 6 rounds there is a final test that covers the entire course material.",
      SexDagaNámskeið:
        "After each round you need to wait for about a day (21 hours) until the next one opens. This means the course takes at least six days. This is done to follow the rules set by the Ministry of Transport",
      "Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina":
        "So you do as well as you can in the driving school you should ideally have the book",
      "Út Í Umferðina": "Út Í Umferðina",
      "handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á":
        "at hand. You can buy the book in Icelandic on the",
      "vefverslun Ökukennarafélagsins": "Driving Instructors Association store",
      Inntökuskilyrði: "Admission requirements",
      "Til þess að taka þátt í Ökuskóla 2 þarf nemandi að hafa":
        "To start Driving School 2 you should have:",
      "Klárað Ökuskóla 1": "Finished Driving School 1",
      Greiðsla: "Payment",
      GreiðslaText:
        "You pay after you've signed up and chosen your course. You can pay either with a credit card or bank transfer.",
      "Skráðu þig í Ökuskóla 2": "Sign up to Driving School 2",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Any questions? Call us at 777-9344.",
    },
  },
};

const Okuskoli2SalesPage = () => {
  const { t } = useTranslation();

  return (
    <div className="site-wrap">
      <Helmet pageTitle={t("Ö2Page.MetaTitle")} />
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body default-page sales-page">
          <HeroSection
            headline={t("Ö2Page.Ökuskóli 2 á netinu")}
            introText={t("Ö2Page.IntroTexti")}
            buttonCTA={t("Ö2Page.Skráðu þig í Ökuskóla 2")}
            imageURL="/images/vegur.jpg"
          />
          <div className="default-text-container">
            {/* <h1>{t("Ö2Page.Ökuskóli 2")}</h1>
            <div className="img-and-info">
              <img className="steering-wheel" src="/images/driver-1x1.jpg" />
              <div className="basic-info">
                <p>
                  <i className="fa fa-money" />
                  {t("Ö2Page.Verð")} <b> {t("Ö2Page.VerðPeningar")}</b>
                </p>
                <p>
                  <i className="fa fa-clock-o" /> {t("Ö2Page.Tími")}
                  <b> {t("Ö2Page.Náminu er dreift yfir 6 daga")}</b>
                </p>
              </div> */}
            {/* </div> */}

            <div>
              <h2>{t("Ö2Page.Fjarnám Urðarbrunns")}</h2>
              <p>{t("Ö2Page.FjarnámTexti")}</p>
              <h2>{t("Ö2Page.Hvað er kennt í Ökuskóla 2?")}</h2>
              <p>
                {t(
                  "Ö2Page.Ökuskóla 2 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni"
                )}
              </p>
              <ul>
                <li>{t("Ö2Page.Upprifjun á Ökuskóla 1")}</li>
                <li>{t("Ö2Page.Umferðarsálfræði")}</li>
                <li>{t("Ö2Page.Áhættuþættir umferðarinnar")}</li>
                <li>{t("Ö2Page.Ábyrgð ökumannsins")}</li>
                <li>{t("Ö2Page.Opinber viðurlög við brotum")}</li>
                <li>{t("Ö2Page.Slys og skyndihjálp")}</li>
              </ul>
              <p>{t("Ö2Page.KaflaprófTexti")}</p>
              <p>{t("Ö2Page.SexDagaNámskeið")}</p>
              <p>
                {t(
                  "Ö2Page.Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina"
                )}
                <i> {t("Ö2Page.Út Í Umferðina")} </i>{" "}
                {t(
                  "Ö2Page.handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á"
                )}{" "}
                <a href="https://www.aka.is/namsefni/">
                  {t("Ö2Page.vefverslun Ökukennarafélagsins")}
                </a>
                .
              </p>
              <img
                className="screenshots first-screenshot"
                src="/images/4.PNG"
              />
              <img className="screenshots" src="/images/2.PNG" />
            </div>
            <h2>{t("Ö2Page.Inntökuskilyrði")}</h2>
            <p>
              {t(
                "Ö2Page.Til þess að taka þátt í Ökuskóla 2 þarf nemandi að hafa"
              )}
            </p>
            <ul>
              <li>{t("Ö2Page.Klárað Ökuskóla 1")}</li>
            </ul>

            <h2>{t("Ö2Page.Greiðsla")}</h2>
            <p>{t("Ö2Page.GreiðslaText")}</p>
            <div>
              <div className="button-container">
                <Link className="main-button" to={t(Pages.Registration)}>
                  {t("Ö2Page.Skráðu þig í Ökuskóla 2")}
                </Link>
              </div>
              <p className="spurningar-p">
                {t("Ö2Page.Einhverjar spurningar? Hringdu í síma 777-9344.")}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Okuskoli2SalesPage;
