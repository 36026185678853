/* eslint-env browser */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CourseWrapper from "./../../CourseWrapper";
import axios from "axios";
import {
  okuskoliData,
  stepAnswerFeedback,
  updateStepAnswer,
} from "../../store/redux";
import { PrevStepButton } from "./PrevStepButton";
import { NextStepButton } from "./NextStepButton";
import { SubmitStepButton } from "./SubmitStepButton";
import DOMPurify from "dompurify";
import Card from "../../../reusables/card";
import { RouteComponentProps } from "react-router-dom";
import {
  AuthReducerTypes,
  IndexReducerTypes,
  StepAnswers,
} from "../../store/types";
import { Steps } from "../../store/steps";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import useTimeout from "../../../hooks/useTimeout";
import CorrectAnswers from "./Test/TestQuestions/CorrectAnswers";
import StepAudioPlayer from "./StepAudioPlayer";

interface StepProps
  extends RouteComponentProps<{
    courseId: string;
    roundNr: string;
    stepNr: string;
  }> {
  authReducer: AuthReducerTypes;
  dispatch: Dispatch;
}

export const translations = {
  is: {
    Step: {
      spurningu: "spurningu",
      spurningum: "spurningum",
      "Þú átt eftir að svara": "Þú átt eftir að svara",
      Skref: "Skref",
      Verkefni: "Verkefni",
      "Þarftu aðstoð? Sendu tölvupóst á hallo@urdarbrunnur.is":
        "Þarftu aðstoð? Sendu tölvupóst á hallo@urdarbrunnur.is",
      Lota: "Lota",
      Ö: "Ö",
    },
  },
  en: {
    Step: {
      spurningu: "question",
      spurningum: "questions",
      "Þú átt eftir að svara": "You have yet to answer",
      Skref: "Step",
      Verkefni: "Question",
      "Þarftu aðstoð? Sendu tölvupóst á hallo@urdarbrunnur.is":
        "Need help? Send an email to hallo@urdarbrunnur.is",
      Lota: "Round",
      Ö: "DS",
    },
  },
};

const Step = ({ authReducer, dispatch, match, history }: StepProps) => {
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { roundNr, stepNr, courseId } = match.params;
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    fetchRoundData();
    updateStepStatusIfEmpty();
    updateRoundStatusIfEmpty();
  }, []);

  // Why so many? Because there was a bug that was caused
  // By users not having a user_round set up. When the step answer
  // Was submitted it would put them into a bad state where
  // They weren't able to procede with the course.
  // No good. So this is the solution, a bit excessive, but there you are.
  useTimeout(() => {
    updateRoundStatusIfEmpty();
    updateStepStatusIfEmpty();
  }, 3000);

  useTimeout(() => {
    updateRoundStatusIfEmpty();
    updateStepStatusIfEmpty();
  }, 10000);

  useEffect(() => {
    updateRoundStatusIfEmpty();
    updateStepStatusIfEmpty();
  }, [stepNr]);

  useEffect(() => {
    fetchRoundData();
    updateStepStatusIfEmpty();
    updateRoundStatusIfEmpty();
  }, [roundNr, courseId]);

  // This is here so that no user round has an empty start_time.
  const updateRoundStatusIfEmpty = () => {
    const { userRounds } = authReducer;

    userRounds.forEach((round) => {
      // If round is current round and startime is empty
      if (
        parseInt(roundNr) === round.nr &&
        round.start_time === null &&
        parseInt(courseId) === round.course_id
      ) {
        // Add a start time.
        axios
          .post("/api/update-user-round", {
            roundId: round.round_id,
            roundNr: parseInt(roundNr),
          })
          .then(() => {
            updateStepStatusIfEmpty();
          })
          .catch((error) => {
            if (error) console.error(error);
          });
      }
    });
  };

  const updateStepStatusIfEmpty = () => {
    const { steps } = authReducer;

    // Get step status and id
    let stepId = 1;
    let stepStatus = "Ongoing";
    steps.forEach((step) => {
      if (step.nr === parseInt(stepNr)) {
        stepId = step.id;
        stepStatus = step.user_course_round_steps[0].status;
      }
    });

    if (stepStatus === null) {
      // start the step by sending a msg to the server
      axios
        .post("/api/update-user-step", {
          stepId: stepId,
          stepNr: parseInt(stepNr),
          roundNr: parseInt(roundNr),
          course: parseInt(courseId),
        })
        .catch((error) => {
          if (error) console.error(error);
        });
    }
  };

  const fetchRoundData = () => {
    const { roundNr, stepNr, courseId } = match.params;

    axios
      .get(`/api/round-data?round=${roundNr}&step=${stepNr}&course=${courseId}`)
      .then((res) => {
        dispatch(okuskoliData({ ...res.data }));
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const submitStepAnswers = (answers: StepAnswers[], stepId: string) => {
    axios
      .post("/api/submit-step-answers", { answers, stepId })
      .then((res) => {
        dispatch(stepAnswerFeedback(res.data));
        setErrorMessage(null);

        // This is a bandaid solution.
        // When there are two questions doing the state updates
        // gets quite tough, so in order to avoid a bug where only
        // the first question gets feedback sent back to it
        // The code below checks if there were two questions answered, if so we
        // fetch the relevant data and render the correct feedback.
        let firstId: null | string = null;
        answers.forEach((answer) => {
          // if no id has been sought, pick this first one.
          if (firstId === null) {
            firstId = answer.questionId;
          }
          // If saved id isn't same as the answer.id then reload page to bring in the fresh data.
          if (firstId !== answer.questionId) {
            fetchRoundData();
          }
        });
      })
      .catch((error) => {
        setErrorMessage(JSON.stringify(error));
        console.error(error);
      });
  };

  const answerSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateStepAnswer({
        answerId: e.target.value,
        questionId: e.currentTarget.name,
        id: e.currentTarget.id,
        checked: e.target.checked,
      })
    );
  };

  const submitAssignment = (step: Steps, stepId: any) => {
    const { stepAnswers } = authReducer;

    // Check if there are unanswered questions
    if (stepAnswers.length < step.step_questions.length) {
      const unansweredQuestions: any[] = [];
      step.step_questions.forEach((question, index) => {
        let hasBeenAnswered = false;
        stepAnswers.forEach(function (answer) {
          if (question.id.toString() === answer.questionId) {
            hasBeenAnswered = true;
          }
        });
        if (hasBeenAnswered == false) {
          unansweredQuestions.push(index + 1);
        }
      });
      const count =
        unansweredQuestions.length === 1
          ? t("Step.spurningu")
          : t("Step.spurningum");
      const message = `${t(
        "Step.Þú átt eftir að svara"
      )} ${count} ${unansweredQuestions.toString()}`;
      setErrorMessage(message);
    } else {
      submitStepAnswers(stepAnswers, stepId);
    }
  };

  let stepQuestionRows = null;
  let stepData = null;
  let stepId = null;

  authReducer.steps.forEach((step) => {
    if (step.nr === parseInt(stepNr)) {
      if (step.step_questions.length > 0) {
        stepQuestionRows = step.step_questions.map((question, index) => {
          let imageRows = null;
          if (question.question_images !== null) {
            imageRows = question.question_images.map((image, imageIndex) => {
              return (
                <div key={`image-${imageIndex}`}>
                  <img
                    src={"/images/skilti/" + image.filename}
                    height="75"
                    width="75"
                  />
                </div>
              );
            });
            imageRows = <div>{imageRows}</div>;
          }

          const stepFinished =
            typeof question.user_step_answers !== "undefined" &&
            question.user_step_answers.length > 0;

          let anyWrong = false;

          const stepAnswerRows = question.step_answers.map(
            (answer, subIndex) => {
              let disabled = false;
              let checked = false;
              let answerClass = null;
              // If the user has submitted the answers
              if (stepFinished) {
                question.user_step_answers.forEach((stepAnswer) => {
                  disabled = true;
                  if (stepAnswer.step_answer_id === answer.id) {
                    checked = true;
                    if (answer.correct) {
                      answerClass = "correct";
                    } else {
                      answerClass = "wrong";
                      anyWrong = true;
                    }
                  }
                });
                if (answerClass === null) {
                  // If question hasn't been evaluated, check it
                  if (answer.correct) {
                    answerClass = "wrong";
                    anyWrong = true;
                  } else {
                    answerClass = "correct";
                  }
                }
              } else {
                authReducer.stepAnswers.forEach((userAnswer) => {
                  if (userAnswer.questionId === question.id.toString()) {
                    if (userAnswer.answerId === answer.id.toString()) {
                      checked = true;
                    }
                  }
                });
              }

              return (
                <div key={subIndex}>
                  <li className={"checkbox-button-container " + answerClass}>
                    <label className="step-test-label">
                      <input
                        type="checkbox"
                        name={question.id.toString()}
                        value={answer.id}
                        disabled={disabled}
                        checked={checked}
                        onChange={answerSelected}
                      />
                      {language === "is" ? answer.answer : answer.answer_en}
                    </label>
                  </li>
                </div>
              );
            }
          );

          const questionText =
            language === "is" ? question.question : question.question_en;

          return (
            <div className="step-test-container" key={index}>
              <p className="step-test-question">
                {index + 1 + ". " + questionText}
              </p>
              {imageRows}
              <ul className="questionRow-ul">
                {stepAnswerRows}
                {stepFinished && anyWrong ? (
                  <CorrectAnswers question={question} stepQuestion={true} />
                ) : null}
              </ul>
            </div>
          );
        });
      }
      stepData = language === "is" ? step.data : step.data_en;
      stepId = step.id;
    }
  }, this);

  DOMPurify.addHook("uponSanitizeElement", (node, data) => {
    if (data.tagName === "iframe") {
      const src = node.getAttribute("src") || "";
      if (!src.startsWith("https://www.youtube.com/embed/")) {
        if (node.parentNode) {
          return node.parentNode.removeChild(node);
        }
      }
    }
  });

  const cleanStepData = PurifyString(stepData);

  return (
    <CourseWrapper
      courseId={courseId}
      title={`${t("Step.Lota")} ${roundNr} | ${t("Step.Ö")}${courseId}`}
    >
      <h1>{`${t("Step.Lota")} ${roundNr}`}</h1>
      <div>
        <p className="step">
          {t("Step.Skref")} {stepNr}/10
        </p>
        <StepAudioPlayer school={courseId} round={roundNr} step={stepNr} />
        <div
          className="lotu-content"
          dangerouslySetInnerHTML={{ __html: cleanStepData }}
        ></div>
        <Card>
          <div>
            {stepQuestionRows ? (
              <div>
                <h2>{t("Step.Verkefni")}</h2>
                {stepQuestionRows}
              </div>
            ) : null}
          </div>
          <div className="erorr-message-container">{errorMessage}</div>
          <div className="btn-div">
            <SubmitStepButton
              stepId={stepId}
              stepNr={match.params.stepNr}
              submitAssignment={submitAssignment}
              steps={authReducer.steps}
            />
          </div>
        </Card>
        <PrevStepButton history={history} params={match.params} />
        <NextStepButton
          history={history}
          params={match.params}
          steps={authReducer.steps}
        />
        <div className="get-help-text">
          <p>
            {t("Step.Þarftu aðstoð? Sendu tölvupóst á hallo@urdarbrunnur.is")}
          </p>
        </div>
      </div>
    </CourseWrapper>
  );
};

const PurifyString = (string: string | null) => {
  if (string === null) return "";
  return DOMPurify.sanitize(string, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
  });
};

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Step);
