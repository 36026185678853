import React from "react";
import { Link } from "react-router-dom";
import { Pages } from "../../routes/routes";
import LangToggle from "./LangToggle";

import { useTranslation } from "react-i18next";
import Button from "../../reusables/button";

export const translations = {
  is: {
    Navigation: {
      "Ökuskóli 1": "Ökuskóli 1",
      "Ökuskóli 2": "Ökuskóli 2",
      Ökukennari: "Ökukennari",
      Innskráning: "Innskráning",
      Nýskráning: "Nýskráning",
      "Fara á forsíðuna": "Fara á forsíðuna",
    },
  },
  en: {
    Navigation: {
      "Ökuskóli 1": "Driving School 1",
      "Ökuskóli 2": "Driving School 2",
      Ökukennari: "Driving Instructor",
      Innskráning: "Log in",
      Nýskráning: "Sign up",
      "Fara á forsíðuna": "Go to homepage",
    },
  },
};

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <header>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="logo-container">
          <a href={t(Pages.FrontPage)}>
            <span className="assistive-text">
              {t("Navigation.Fara á forsíðuna")}
            </span>
            <picture>
              <source srcSet="/images/logo.webp" type="image/webp" />
              <source srcSet="/images/logo.jpg" type="image/jpeg" />
              <img id="logo" src="/images/logo.jpg" alt="Urðarbrunnur logo" />
            </picture>{" "}
          </a>
        </div>
        <div className="mobile-only sign-up-cta">
          <Button
            size="small"
            type="react-router-link"
            href={t(Pages.Registration)}
          >
            {t("Navigation.Nýskráning")}
          </Button>
        </div>
        <nav className="navbar">
          <ul>
            <LangToggle />
            <li>
              <Link className="primary-cta" to={t(Pages.Registration)}>
                {t("Navigation.Nýskráning")}
              </Link>
            </li>
            <li>
              <Link to={t(Pages.Login)}>{t("Navigation.Innskráning")}</Link>
            </li>
            <li>
              <Link to={t(Pages.DrivingInstructors)}>
                {t("Navigation.Ökukennari")}
              </Link>
            </li>
            <li>
              <Link to={t(Pages.DrivingSchool2)}>
                {t("Navigation.Ökuskóli 2")}
              </Link>
            </li>
            <li>
              <Link to={t(Pages.DrivingSchool1)}>
                {t("Navigation.Ökuskóli 1")}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navigation;
