import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Message: {
      Jan: "jan",
      Feb: "feb",
      Mar: "mar",
      Apr: "apr",
      Maí: "maí",
      Jún: "jún",
      Júl: "júl",
      Ágú: "agú",
      Sep: "sep",
      Okt: "okt",
      Nóv: "nóv",
      Des: "des",
    },
  },
  en: {
    Message: {
      Jan: "Jan",
      Feb: "Feb",
      Mar: "Mar",
      Apr: "Apr",
      Maí: "May",
      Jún: "June",
      Júl: "Jul",
      Ágú: "Aug",
      Sep: "Sept",
      Okt: "Oct",
      Nóv: "Nov",
      Des: "Dec",
    },
  },
};

interface MessageProps {
  index: number;
  time: number;
  user: string;
  text: string;
}

const Message: FC<MessageProps> = ({ index, user, text, time }) => {
  const { t } = useTranslation();

  const monthNames = [
    t("Message.Jan"),
    t("Message.Feb"),
    t("Message.Mar"),
    t("Message.Apr"),
    t("Message.Maí"),
    t("Message.Jún"),
    t("Message.Júl"),
    t("Message.Ágú"),
    t("Message.Sep"),
    t("Message.Okt"),
    t("Message.Nóv"),
    t("Message.Des"),
  ];

  // Creates contrast between messages.
  let backgroundColor = "#e4e4e4";
  if (index % 2 === 0) {
    backgroundColor = "#cccccc";
  }

  // Formats time stamp.
  const date = new Date(time);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const timeString =
    date.getDate() +
    ". " +
    monthNames[date.getMonth()] +
    " " +
    hours +
    ":" +
    minutes;

  return (
    <div className="message" style={{ backgroundColor: backgroundColor }}>
      <div className="timestamp">{timeString}</div>
      <strong>{user}: </strong>
      <span>{text}</span>
    </div>
  );
};

export default Message;
