import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type DrivingInstructorProps = {
  name: string;
  desc: string;
  price: string;
  car: string;
  phone: string;
  imgSrc: string;
};

const DrivingInstructor: FC<DrivingInstructorProps> = ({
  name,
  desc,
  price,
  car,
  phone,
  imgSrc,
}) => {
  const { t } = useTranslation();

  return (
    <div className="driving-teacher">
      <img className="teacher-image" src={imgSrc} />
      <div className="info">
        <h2>{name}</h2>
        <p>{desc}</p>
        <div className="details">
          <p>
            <i className="fa fa-money"></i>
            {t("DrivingInstructors.Verð")}: {price}{" "}
            {t("DrivingInstructors.kr / kennslustund")}
          </p>
          <p>
            <i className="fa fa-phone-square"></i>
            {t("DrivingInstructors.Sími")}: {phone}
          </p>
          <p>
            <i className="fa fa-car"></i>
            {t("DrivingInstructors.Bíll")}: {car}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DrivingInstructor;
