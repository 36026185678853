import React, { useEffect, useState } from "react";
import Helmet from "./Helmet";
import { Link, useHistory } from "react-router-dom";
import isNumeric from "validator/lib/isNumeric";
import axios from "axios";
import { connect } from "react-redux";
import { userIsLoggedIn } from "../store/redux";
import { IndexReducerTypes } from "../store/types";
import DesktopMenu from "./Navigation";
import MobileMenu from "./MobileMenu";
import Footer from "./Footer";
import { LoginProps, LoginState } from "./Login.types";
import { useTranslation } from "react-i18next";
import { Pages } from "../../routes/routes";

export const translations = {
  is: {
    Login: {
      Error500: "Eitthvað fór úrskeiðis okkar megin!",
      Error400: "Villa: kennitalan eða lykilorðið er vitlaust",
      MetaTitle: "Innskráning",
      Innskráning: "Innskráning",
      Kennitala: "Kennitala",
      Lykilorð: "Lykilorð",
      Innskrá: "Innskrá",
      "Gleymt lykilorð": "Gleymt lykilorð?",
    },
  },
  en: {
    Login: {
      Error500: "Something appears to be wrong with the server. Sorry!",
      Error400: "Either the kennitala or password is wrong",
      MetaTitle: "Log in",
      Innskráning: "Log in",
      Kennitala: "Kennitala",
      Lykilorð: "Password",
      Innskrá: "Sign in",
      "Gleymt lykilorð": "Forgot password?",
    },
  },
};

const Login: React.FC<LoginProps> = (props) => {
  const { t } = useTranslation();

  const [state, setState] = useState<LoginState>({
    ssnFeedback: "",
    ssnValid: true,
    error: false,
    errorMessage: "",
    success: false,
    password: "",
    ssn: "",
  });

  const history = useHistory();

  useEffect(() => {
    redirectIfLoggedIn();
  }, []);

  useEffect(() => {
    redirectIfLoggedIn();
  });

  const redirectIfLoggedIn = () => {
    const { authReducer } = props;
    // redirect to dashboard if logged in.
    if (authReducer.authenticated) {
      history.push("/stjornbord");
    }
  };

  const changeFieldValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "ssn") {
      if (!isNumeric(value) && value.length !== 0) {
        setState((prevState) => ({
          ...prevState,
          ssnValid: false,
          ssnFeedback: "Kennitölur eru einungis tölur", // never triggered
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          ssnValid: true,
          ssnFeedback: "",
        }));
      }
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };

  const onSubmit = () => {
    const { dispatch } = props;
    const { password, ssn } = state;

    setState((prevState) => ({
      ...prevState,
      error: false,
      errorMessage: "",
      success: false,
    }));

    axios
      .post(`/api/sign-in`, {
        password: password,
        ssn: ssn,
      })
      .then((res) => {
        dispatch(userIsLoggedIn({ ...res.data }));
        history.push("/stjornbord");
      })
      .catch((error) => {
        let errorMessage = "";

        if (error.response.state === 500) {
          errorMessage = t("Login.Error500");
        } else {
          errorMessage = t("Login.Error400");
        }
        setState((prevState) => ({
          ...prevState,
          error: true,
          errorMessage,
          success: false,
        }));
      });
  };

  const { ssn, password, error, errorMessage, ssnValid, ssnFeedback } = state;

  return (
    <div>
      <Helmet pageTitle={t("Login.MetaTitle")} />
      <MobileMenu />
      <div className="site-wrap">
        <div className="push-wrap">
          <DesktopMenu />
          <div className="main-body main-content sign-in" onKeyDown={onKeyDown}>
            <div className="sign-in-container">
              <h1>{t("Login.Innskráning")}</h1>
              <div className="form-container">
                <label>
                  <p className="field-name">{t("Login.Kennitala")}</p>
                  <input
                    autoFocus
                    type="number"
                    name="ssn"
                    value={ssn}
                    onChange={changeFieldValue}
                    required
                  />
                  {!ssnValid ? (
                    <p className="feedback-message error-message">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      {ssnFeedback}
                    </p>
                  ) : (
                    ""
                  )}
                </label>
              </div>
              <div className="form-container">
                <label>
                  <p className="field-name">{t("Login.Lykilorð")}</p>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={changeFieldValue}
                    required
                  />
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-submit"
                  onClick={onSubmit}
                >
                  {t("Login.Innskrá")}
                </button>
                <Link
                  to={t(Pages.ForgotPassword)}
                  className="gleymt-lykilord-link"
                >
                  {t("Login.Gleymt lykilorð")}
                </Link>
                <div>
                  {error ? (
                    <p className="feedback-message error-message">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      {errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Login);
