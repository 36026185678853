import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    MessageForm: {
      Senda: "Senda",
      "Skrifið hér og ýtið á enter til að senda":
        "Skrifið hér og ýtið á enter til að senda",
    },
  },
  en: {
    MessageForm: {
      Senda: "Send message",
      "Skrifið hér og ýtið á enter til að senda":
        "Write here (press enter to send)",
    },
  },
};

interface MessageFormProps {
  onMessageSubmit: (message: { text: string }) => void;
}

const MessageForm = ({ onMessageSubmit }: MessageFormProps) => {
  const [text, setText] = useState("");
  const { t } = useTranslation();

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (text.length > 1) {
      onMessageSubmit({ text });
      setText("");
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  return (
    <div className="message_form">
      <form onSubmit={handleSubmit}>
        <input
          onChange={changeHandler}
          value={text}
          placeholder={t(
            "MessageForm.Skrifið hér og ýtið á enter til að senda"
          )}
        />
        <button className="send-message">{t("MessageForm.Senda")}</button>
      </form>
    </div>
  );
};

export default MessageForm;
