import { AuthReducerTypes } from "./types";

// ACTIONS
const USER_IS_LOGGED_IN = "USER_IS_LOGGED_IN";
const OKUSKOLI_DATA = "OKUSKOLI_DATA";
const STEP_ANSWER_FEEDBACK = "STEP_ANSWER_FEEDBACK";
const UPDATE_TEST_ANSWERS = "UPDATE_TEST_ANSWERS";
const UPDATE_TEST_DATA = "UPDATE_TEST_DATA";
const GET_FINAL_TEST_DATA = "GET_FINAL_TEST_DATA";
const UPDATE_FINAL_TEST_ANSWERS = "UPDATE_FINAL_TEST_ANSWERS";
const SHOW_ALERT = "SHOW_ALERT";
const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
const UPDATE_STEP_ANSWER = "UPDATE_STEP_ANSWER";
const UPDATE_TEST_ANSWER = "UPDATE_TEST_ANSWER";
const UPDATE_FINAL_TEST_ANSWER = "UPDATE_FINAL_TEST_ANSWER";
const TOGGLE_DYSLEXIA_FONT = "TOGGLE_DYSLEXIA_FONT";

// State
const initialState: AuthReducerTypes = {
  authenticated: false,
  id: null,
  name: "",
  dyslexiaFont: false,
  userCourses: [],
  tests: [],

  // Timer
  showAlert: false,
  timerHours: 0,
  timerMinutes: 0,

  // Course times
  sinceFirstStart: { days: 1, hours: 0, minutes: 0, seconds: 0 },
  sinceSecondStart: { days: 1, hours: 0, minutes: 0, seconds: 0 },
  extraDaysFirst: 0, // Does course 1 have any extra days?
  extraDaysSecond: 0, // Does course 2 have any extra days?

  // Course data
  step: null,
  steps: [],
  round: [],
  roundId: null,
  roundNr: null,
  stepAnswers: [],
  test: [],
  testAnswers: [],
  userTest: {},
  wrongTestAnswers: null,
  finalTest: [],
  finalTestAnswers: [],
  nextRoundOpen: true,
  currentRound: 1,
  currentRoundStatus: "Not Started",
  currentStep: 1,
  messages: [],
  messageFeatureUsed: false,
  roundsFinished: false,
  userRounds: [],
  currentUserRounds: [],
};

// REDUCER
/*eslint no-case-declarations: "off"*/
export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_ALERT:
      return Object.assign({}, state, {
        showAlert: true,
        ...action.params,
      });

    case USER_IS_LOGGED_IN:
      return Object.assign({}, state, {
        ...action.params,
      });

    case OKUSKOLI_DATA:
      return Object.assign({}, state, {
        ...action.params,
      });

    case GET_FINAL_TEST_DATA:
      return Object.assign({}, state, {
        finalTest: action.params.finalTest,
        userTest: action.params.userTest,
        finalTestAnswers: [],
        wrongTestAnswers: null,
      });

    case UPDATE_TEST_DATA:
      return Object.assign({}, state, {
        test: action.params.test,
        userTest: action.params.userTest,
        testAnswers: [],
        wrongTestAnswers: null,
      });

    case UPDATE_FINAL_TEST_ANSWER:
      const { ftestAnswerId, ftestQuestionId, ftestChecked } = action.params;
      let ftestAlreadyAnswered = false;

      state.finalTestAnswers.forEach((answer, i) => {
        if (
          answer.questionId == ftestQuestionId &&
          answer.answerId == ftestAnswerId
        ) {
          ftestAlreadyAnswered = true;
          if (ftestChecked === false) {
            state.finalTestAnswers.splice(i, 1);
          }
        }
      });

      if (ftestAlreadyAnswered === false) {
        state.finalTestAnswers.push({
          questionId: ftestQuestionId,
          answerId: ftestAnswerId,
        });
      }

      return { ...state };

    case UPDATE_TEST_ANSWER:
      const { testAnswerId, testQuestionId, testId, testChecked } =
        action.params;
      const testImageId = testId === "" ? null : testId;
      let testAlreadyAnswered = false;

      state.testAnswers.forEach((answer, i) => {
        if (
          answer.questionId == testQuestionId &&
          answer.answerId == testAnswerId
        ) {
          testAlreadyAnswered = true;
          if (testChecked === false) {
            state.testAnswers.splice(i, 1);
          }
        }
      });

      if (testAlreadyAnswered === false) {
        state.testAnswers.push({
          questionId: testQuestionId,
          answerId: testAnswerId,
          imageId: testImageId,
        });
      }

      return { ...state };

    case UPDATE_STEP_ANSWER:
      const { answerId, questionId, id, checked } = action.params;
      const imageId = id === "" ? null : id;
      let alreadyAnswered = false;

      state.stepAnswers.forEach((answer, i) => {
        if (answer.questionId === questionId && answer.answerId === answerId) {
          alreadyAnswered = true;

          if (checked === false) {
            state.stepAnswers.splice(i, 1);
          }
        }
      });

      if (!alreadyAnswered) {
        state.stepAnswers.push({
          questionId: questionId,
          answerId: answerId,
          imageId: imageId,
        });
      }

      return { ...state };

    case STEP_ANSWER_FEEDBACK:
      let stepIndex = 0;

      state.steps.forEach((step, index) => {
        if (step.id === action.params.stepId) {
          stepIndex = index;
        }
      });
      const newState = { ...state };

      newState.steps[stepIndex].step_questions[0].user_step_answers =
        action.params.answers;
      newState.steps[stepIndex].user_course_round_steps[0].status = "Finished";
      newState.steps[stepIndex].status = "Finished";
      newState.stepAnswers = []; // clears step answer.

      return newState;

    case UPDATE_TEST_ANSWERS:
      const { answers, roundNr, wrongAnswers, roundFinished } = action.params;
      const newNewState = { ...state };

      newNewState.userRounds.forEach((round) => {
        if (round.nr === parseInt(roundNr) && roundFinished === true) {
          round.status = "Finished";
        }
      });

      newNewState.test[0].questions.forEach((question) => {
        question.answers.forEach((answer) => {
          answers.forEach((testAnswer) => {
            if (answer.id === testAnswer.answer_id) {
              answer.correct = testAnswer.correct;
            }
          });
        });
      });

      newNewState.userTest.status = "Finished";
      newNewState.wrongTestAnswers = wrongAnswers;

      return newNewState;

    case UPDATE_FINAL_TEST_ANSWERS:
      const fAnswers = action.params.answers;
      const fWrongAnswers = action.params.wrongAnswers;
      const fState = { ...state };

      fState.finalTest[0].questions.forEach((question) => {
        question.answers.forEach((answer) => {
          fAnswers.forEach((testAnswer) => {
            if (answer.id === testAnswer.answer_id) {
              answer.correct = testAnswer.correct;
            }
          });
        });
      });

      fState.userTest.status = "Finished";
      fState.wrongTestAnswers = fWrongAnswers;

      return fState;

    case ADD_NEW_MESSAGE:
      const messageState = { ...state };
      messageState.messages.push({ ...action.params });
      messageState.messageFeatureUsed = true;
      return messageState;

    case TOGGLE_DYSLEXIA_FONT:
      const dyslexiaState = { ...state };
      dyslexiaState.dyslexiaFont = !state.dyslexiaFont;
      return dyslexiaState;

    default:
      return state;
  }
};

// ACTION CREATORS
export const userIsLoggedIn = (params: any) => ({
  type: USER_IS_LOGGED_IN,
  params,
});

export const okuskoliData = (params: any) => ({
  type: OKUSKOLI_DATA,
  params,
});

export const stepAnswerFeedback = (params: any) => ({
  type: STEP_ANSWER_FEEDBACK,
  params,
});

export const updateTestAnswers = (params: any) => ({
  type: UPDATE_TEST_ANSWERS,
  params,
});

export const updateTestData = (params: any) => ({
  type: UPDATE_TEST_DATA,
  params,
});

export const getFinalTestData = (params: any) => ({
  type: GET_FINAL_TEST_DATA,
  params,
});

export const updateFinalTestAnswers = (params: any) => ({
  type: UPDATE_FINAL_TEST_ANSWERS,
  params,
});

export const showAlert = (params: any) => ({
  type: SHOW_ALERT,
  params,
});

export const addNewMessage = (params: any) => ({
  type: ADD_NEW_MESSAGE,
  params,
});

export const updateStepAnswer = (params: any) => ({
  type: UPDATE_STEP_ANSWER,
  params,
});

export const updateTestAnswer = (params: any) => ({
  type: UPDATE_TEST_ANSWER,
  params,
});

export const updateFinalTestAnswer = (params: any) => ({
  type: UPDATE_FINAL_TEST_ANSWER,
  params,
});

export const toggleDyslexiaFont = () => ({
  type: TOGGLE_DYSLEXIA_FONT,
});
