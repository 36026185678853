import React, { FC } from "react";
import { TestQuestionsImages } from "../../../../store/test";

interface QuestionImagesProps {
	questionImages: TestQuestionsImages[];
}

const QuestionImages: FC<QuestionImagesProps> = ({ questionImages }) => {
	return (
		<>
			{questionImages.map((image) => {
				return (
					<div key={image.id}>
						<img src={`/images/skilti/${image.filename}`} height="75" width="75" />
					</div>
				);
			})}
		</>
	);
};

export default QuestionImages;
