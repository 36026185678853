import React from "react";
import DesktopMenu from "./Navigation";
import MobileMenu from "./MobileMenu";
import Footer from "./Footer";
import Helmet from "./Helmet";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Terms: {
      "Skilmálar Urðarbrunns": "Skilmálar Urðarbrunns",
      "Ökuskóli Urðarbrunns hefur starfsleyfi frá Samgöngustofu":
        "Ökuskóli Urðarbrunns hefur starfsleyfi frá Samgöngustofu.",
      FirstParagraph:
        "FR/AR ehf. (kt. 591214-0710) er eigandi þessa vefs (urdarbrunnur.is) og er notkun hans aðeins heimil þeim sem hefur skráð sig í Ökuskóla Urðarbrunns. Nemandi þarf að ljúka námi innan 30 daga frá því að námskeið er opnað.",
      SecondParagraph:
        "FR/AR ehf. ber hvorki ábyrgð á tjóni sem rekja má beint eða óbeint til notkunar á vef skólans né tjóni sem rekja má til þess að ekki er hægt að nota vefinn, um skemmri eða lengri tíma.",
      ThirdParagraph:
        "FR/AR ehf. á höfundarréttinn á öllum þeim upplýsingum sem fram koma á vef ökuskólans, nema annað sé sérstaklega tekið fram eða leiða megi af eðli máls. Skriflegt samþykki FR/AR ehf. þarf til að endurbirta upplýsingar sem fram koma á vef skólans, dreifa þeim eða afrita þær. Ekki skiptir máli hvers eðlis þær upplýsingar eru né í hvaða tilgangi ætlunin er að endurbirta þær, afrita þær eða dreifa þeim. Nemendum ökuskólans er þó heimilt að vista upplýsingar af vefnum til einkanota.",
      FourthParagraph:
        "Nemendur geta hætt við kaupin innan 14 daga. Frestur þessi byrjar að líða þegar greiðsla á sér stað.",
      FifthParagraph:
        "Samningur þessi er í samræmi við íslensk lög. Rísi mál vegna hans skal það rekið fyrir Héraðsdómi Reykjaness.",
      "Meðferð persónuupplýsinga": "Meðferð persónuupplýsinga",
      SixthParagraph:
        "Hjá Urðarbrunni skiptir öryggi máli og við höldum þínum persónuupplýsingum öruggum með tryggum forritunaraðferðum.",
      "Hvaða upplýsingum er safnað?": "Hvaða upplýsingum er safnað?",
      SeventhParagraph:
        "Grunnupplýsingum um nemendur, upplýsingum um notkun vefsíðu ogvupplýsingum um framvindu náms.",
      "Hvers vegna er upplýsingum safnað?":
        "Hvers vegna er upplýsingum safnað?",
      EigthParagraph:
        "Til þess að ökukennslan sé í samræmi við lög og relgur sem gilda um ökukennslu á netinu. Svo við getum fylgst með virkni kerfa og til að tryggja rekjanleika aðgerða.",
      "Hvernig verða upplýsingarnar notaðar?":
        "Hvernig verða upplýsingarnar notaðar?",
      NinthParagraph:
        "Upplýsingar eru einungis notaðar til að uppfylla lagaskyldu og kröfur í samningum.",
      "Með hverjum verður upplýsingum deilt?":
        "Með hverjum verður upplýsingum deilt?",
      TenthParagraph:
        "Urðarbrunnur deilir ekki upplýsingum með öðrum nema lög eða reglugerðir krefjist þess. Vegna laga deilum við einungis upplýsingum þínum með:",
      "Ökukennara þínum": "Ökukennara þínum",
      Samgöngustofu: "Samgöngustofu",
    },
  },
  en: {
    Terms: {
      "Skilmálar Urðarbrunns": "Terms & Conditions",
      "Ökuskóli Urðarbrunns hefur starfsleyfi frá Samgöngustofu":
        "Urðarbrunnur Driving school has a license from The Icelandic Transport Authority (Samgöngustofa).",
      FirstParagraph:
        "FR/AR ehf. (kt. 591214-0710) is the owner of this webpage (urdarbrunnur.is) and its usage is only permitted to those who have registered with Urðarbrunnur Driving school. Students must finish a course within 30 days once they finish registering.",
      SecondParagraph:
        "FR/AR ehf. is not liable for damage that can be traced either directly or indirectly to usage of the school's website nor damage traceable to the website not functioning correctly for a shorter or longer period of time.",
      ThirdParagraph:
        "FR/AR ehf. has copyright of all information displayed on the school's website unless otherwise stated. A written approval from FR/AR ehf. is needed to publish, share or copy information from the school's website. It does not matter what kind of information nor for what purpose it is published, copied, or shared. Students of the driving school are however permitted to save information from the website for personal use.",
      FourthParagraph:
        "Students can cancel the purchase within 14 days from the day the payment was made.",
      FifthParagraph:
        "This contract is in accordance with Icelandic law. If a case arises it shall be brought to Reykjanes District Court.",
      "Meðferð persónuupplýsinga": "Treatment of personal information",
      SixthParagraph:
        "At Urðarbrunnur, information security is a priority, thus we keep all personal information safe with trustworthy programming methods.",
      "Hvaða upplýsingum er safnað?": "Which information is collected?",
      SeventhParagraph:
        "Basic information about students, information on the usage of the website and information regarding study progress.",
      "Hvers vegna er upplýsingum safnað?": "Why is information collected?",
      EigthParagraph:
        "Information is collected to ensure that the driving school is compliant with rules and regulations concerning online driving schools. Information is also collected to observe if all systems are working correctly and to ensure traceability of operations.",
      "Hvernig verða upplýsingarnar notaðar?":
        "How will my information be used?",
      NinthParagraph:
        "Information is only used to fulfil legal requirements and claims in the contract.",
      "Með hverjum verður upplýsingum deilt?":
        "With whom is information shared?",
      TenthParagraph:
        "Urðarbrunnur does not share your information with anyone unless law and regulations require it. In accordance with regulations we only share your information with:",
      "Ökukennara þínum": "Your driving instructor",
      Samgöngustofu: "The Icelandic Transport Authority (Samgöngustofa)",
    },
  },
};

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="site-wrap">
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <Helmet pageTitle="Skilmálar" />
        <div className="main-body default-page">
          <div className="default-text-container">
            <h1>{t("Terms.Skilmálar Urðarbrunns")}</h1>
            <p>
              {t(
                "Terms.Ökuskóli Urðarbrunns hefur starfsleyfi frá Samgöngustofu"
              )}
            </p>
            <p>{t("Terms.FirstParagraph")}</p>
            <p>{t("Terms.SecondParagraph")}</p>
            <p>{t("Terms.ThirdParagraph")}</p>
            <p>{t("Terms.FourthParagraph")}</p>
            <p>{t("Terms.FifthParagraph")}</p>
            <h2>{t("Terms.Meðferð persónuupplýsinga")}</h2>
            <p>{t("Terms.SixthParagraph")}</p>
            <h3>{t("Terms.Hvaða upplýsingum er safnað?")}</h3>
            <p>{t("Terms.SeventhParagraph")}</p>
            <h3>{t("Terms.Hvers vegna er upplýsingum safnað?")}</h3>
            <p>{t("Terms.EigthParagraph")}</p>
            <h3>{t("Terms.Hvernig verða upplýsingarnar notaðar?")}</h3>
            <p>{t("Terms.NinthParagraph")}</p>
            <h3>{t("Terms.Með hverjum verður upplýsingum deilt?")}</h3>
            <p>{t("Terms.TenthParagraph")}</p>
            <ol>
              <li>{t("Terms.Ökukennara þínum")}</li>
              <li>{t("Terms.Samgöngustofu")}</li>
            </ol>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Terms;
