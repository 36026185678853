import React, { FC } from "react";

type FormButtonProps = {
	className?: string;
	onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	text: string;
	size?: "tiny" | "small" | "medium" | "big";
	name?: string;
};

const FormButton: FC<FormButtonProps> = ({ onClick, className, text, size, name }) => {
	// If no button size is specified, default to big.
	let sizeClassName = "";
	if (!size) {
		sizeClassName = "btn-big";
	} else {
		sizeClassName = `btn-${size}`;
	}

	return (
		<input
			type="button"
			id="btn-component"
			name={name}
			onClick={onClick}
			value={text}
			className={`${className} ${sizeClassName}`}
		/>
	);
};

export default FormButton;
