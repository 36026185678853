import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { userIsLoggedIn } from "../components/store/redux";
import { AuthReducerTypes, IndexReducerTypes } from "../components/store/types";
import { Dispatch } from "redux";

export const authenticate = async () => {
  try {
    const res = await axios.get("/api/is-logged-in");
    return res.data;
  } catch (error) {
    console.error({ error });
    return false;
  }
};

type AuthBeforeRenderProps = {
  dispatch: Dispatch;
  authReducer: AuthReducerTypes;
  render: Function;
};

type AuthBeforeRenderState = {
  loading: boolean;
};

// This component is mounted when
// The redux state says the user is unauthenticated but is trying to access a protected route.
// The component sends a request to the back end to double check if the user is actually unauthenticated.
// If unauthenticated, redirect him to the login page. If authenticated, user can continue on his merry way.
class AuthBeforeRender extends Component<
  AuthBeforeRenderProps,
  AuthBeforeRenderState
> {
  constructor(props: AuthBeforeRenderProps) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    authenticate().then((data) => {
      dispatch(
        userIsLoggedIn({
          ...data,
        })
      );
      this.setState({ loading: false });
    });
  }

  render() {
    const { authenticated } = this.props.authReducer;

    if (this.state.loading) {
      return null;
    } else {
      return authenticated ? (
        this.props.render()
      ) : (
        <Redirect
          to={{
            pathname: "/innskraning",
          }}
        />
      );
    }
  }
}

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(AuthBeforeRender);
