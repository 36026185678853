import React from "react";
import Template from "./PostTemplate";
import marked from "marked";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

const markdown = `
Til þess að keyra á götum Íslands þá þarftu bílpróf og ef þú vilt fá bílpróf þá þarftu að klára ökunámið.

Samkvæmt lögum má hefja ökunám 16 ára, en þú færð ekki bílpróf fyrr en þú verður 17 ára og hefur lokið ökunáminu.

Í **stuttu máli** er ökunáminu skipt upp í

- 15+ verklega ökutíma með ökukennara
- 3 námskeið (2 bókleg og 1 verklegt námskeið)
- Æfingarakstur
- 2 próf (1 verklegt og 1 bóklegt)

Í heildina <a href="/blogg/hvad-kostar-bilprof">kostar allur pakkinn frá 270.000 kr upp í 400.000 kr.</a> 

Til þess að hefja ökunám er lang best að heyra í góðum ökukennara sem getur leitt þig í gegnum ferlið. 


## Bílpróf í lengra máli

Að læra á bíl getur verið ruglandi. Margir aðilar koma að því og það er erfitt að átta sig á hvar maður er í ferlinu. 

Til að einfalda málið er hægt að skipta ökunáminu niður í 11 skref:

1. <a href="#okukennari">Finna sér ökukennara</a>
2. <a href="#namsleyfi">Fá námsheimild frá sýslumanni</a>
3. <a href="#okuskoli1">Ökuskóli 1</a>
4. <a href="#aefingaakstur">Æfingaakstur</a>
5. <a href="#okuskoli2">Ökuskóli 2</a>
6. Klára 12 ökutíma
7. <a href="#okuskoli3">Ökuskóli 3</a>
8. Klára 5 fleiri ökutíma
9. <a href="#skriflegt-prof">Skriflegt próf</a>
10. <a href="#verklegt-prof">Verklegt próf</a>
11. <a href="#bilprof">Bílprófi náð (Bráðarbirgðar- og fullnaðarskírteini)</a>

### <span id="okukennari">Finna sér ökukennara</span>

Fyrsta skrefið í ökunáminu er að finna sér ökukennara sem passar við mann. Almennt er best að spyrja vini sína hvaða ökukennara þeir séu hjá og heyra í þeim. 

Full ástæða er til að vanda valið á ökukennaranum því að hann fylgir þér gegnum allt ökunámið. Gott er að hafa í huga hvort kennsluáætlun hans samræmist þínum þörfum, hvort tími dagsins sem hann kennir henti þér og hver kostnaður ökutímanna er.

### <span id="namsleyfi">Fá námsheimild frá sýslumanni</span>

Áður en þú hefur nám af alvöru skaltu fá námsheimild frá Sýslumanni. Þú gerir það inn á ísland.is og ferð svo á skrifstofu sýslumanns með passamynd. Þú ættir að fá námsheimild áður en þú hefur námið.

Þú getur sótt um námsheimild hérna: https://island.is/fyrsta-okuskirteini-namsheimild

### <span id="okuskoli1">Ökuskóli 1</span>
Ökuskóli 1 er fyrsti hluti bóklegs ökunáms. Algengt er að nemendur taki ökuskóla 1 á netinu (t.d hjá okkur og öðrum netökuskólum), en það er enn þá hægt að sitja tíma í kennslustofu.

Hægt er að lesa meira um hvernig <a href="/okuskoli1">ökuskóla 1 er háttað hjá okkur hérna.</a>

<img src="/images/blogg/mosi-og-vegur.jpg" />

### <span id="aefingaakstur">Æfingaakstur</span>

Æfingaakstur fer þannig fram að nemandinn æfir sig að aka venjulegum bíl með leiðbeinanda sem gjarnan er foreldri eða forráðamaður. 

Þegar Ö1 og að minnsta kosti 10 verklegum tímum er lokið má sækja um leyfi til æfingaaksturs. Ökukennarinn metur hvort og hvenær nemandi er tilbúinn að hefja æfingaakstur og staðfestir það með sérstöku vottorði.

Þegar tryggingafélag hefur staðfest að vátrygging sé í gildi vegna æfingaaksturs er hægt að sækja um heimild til sýslumanns.

Æfingaakstur er ekki skylda í ökunámi en það er góður valkostur til að fá sem bestan undirbúning fyrir akstur í framtíðinni. 

Athugið að leiðbeinandinn verður að uppfylla eftirfarandi skilyrði:

- hafa náð 24 ára aldri
- hafa gild ökuréttindi og a.m.k. 5 ára reynslu af akstri
- ekki hafa verið án ökuskírteinis eða verið refsað fyrir vítaverðan akstur síðastliðið ár

### <span id="okuskoli2">Ökuskóli 2</span>

Ökuskóli 2 er framhald af ökuskóla 1 og er háttað á sama máta. Nemandi þarf að ljúka þeim báðum áður en hann getur tekið skriflega prófið.

### <span id="okuskoli3">Ökuskóli 3</span>
Nemandi þarf að hafa lokið 12 ökutímum til að geta farið í Ökuskóla 3. 

Ökuskóli 3 er verklegt námskeið sem fer fram í ökugerði. Tilgangur ökugerðisins er að líkja eftir aðstæðum þar sem nemandi upplifir það að missa vald á bílnum. Það er gert með hálkubrautum eða skrikbíll, malarvegum og veltubíl. 

Það þarf að klára ökuskóla 3 áður en nemandi má taka verklegt próf. 

### <span id="skriflegt-prof">Skriflegt ökupróf</span>

Skriflega prófið er 30 spurninga krossapróf sem skiptist í A og B-hluta.

Í A-hluta eru 15 spurningar um umferðarmerki, yfirborðsmerkingar á vegum og forgang í umferð. Í B-hluta eru 15 almennar spurningar um umferðina, bílinn, ökumanninn og skyndihjálp.

Við hverri spurningu eru gefnir þrír valmöguleikar og geta ýmist einn þeirra, tveir eða allir þrír verið réttir. Það telst villa ef hakað er við röng svör en einnig ef ekki er hakað við rétt svör.

Nemandi má að hámarki vera með 7 villur í prófinu öllu og aðeins tvær villur í A-hluta. Niðurstöðuna fær nemandi strax að loknu prófi.

Ef nemandi stenst ekki prófið verður að líða a.m.k. ein vika þangað til hann getur gert aðra tilraun. 

Það er ansi algengt að nemendur falli í þessu prófi og við mælum með því að æfa sig vel fyrir prófið. Hver próftaka kostar 6,550 kr, þannig það getur orðið dýrt að falla endurtekið. 
 
### <span id="verklegt-prof">Verklegt ökupróf</span>
Til þess að geta farið í verklegt próf þarf maður að hafa lokið 15 verklegum ökutímum og klárað Ökuskóla 1, 2 og 3. 

Verklega ökuprófið skiptist í tvo hluta; munnlegt próf og verklegt próf. Munnlega prófið er tekið í bílnum. Prófdómarinn spyr um ýmislegt í sambandi við bílinn; t.d. ljósabúnað, stjórntæki, öryggisbúnað og viðhald. Ef munnlega prófið gengur vel tekur verklega prófið við í beinu framhaldi. Ekin er viss leið sem prófdómari ákveður og hann skráir hjá sér plúsa og mínusa. Verklega prófið kostar 17.370 kr.

Prófdómarinn kveður upp úrskurðinn strax að loknu prófi.

### <span id="bilprof">Bráðarbirgðar- og fullnaðarskírteini</span>
<img alt="Ökuskírteini" src="/images/lotur/Okuskirteini.png">
<p class="image-caption">Ökuskírteini</p>

Þegar prófunum er náð og náminu lokið fær nemandi fyrsta ökuskírteinið sem er svokallað bráðabirgðaskírteini. 

Bráðabirgðaskírteini gildir í þrjú ár og á meðan þarf færri punkta til þess að missa ökuréttindin. Hafi ökumaður með bráðabirgðaskírteini ekki fengið punkt vegna umferðarlagabrota í eitt ár og hafi ekki verið sviptur ökuréttindum í 12 mánuði getur hann farið í akstursmat hjá ökukennara. 

Eftir akstursmatið fær hann fullnaðarskírteini sem gildir í 15 ár. 

Þá er formlegu námi lokið.


### Annað efni 
<a href="https://www.samgongustofa.is/umferd/nam-og-rettindi/okunam/">Á vef Samgöngustofu er hægt að finna allskonar efni um ökunámið</a>

<a href="/blogg/hvad-kostar-bilprof">Hvað kostar bílpróf?</a> 

Ef þú hefur frekari spurningar getur þú líka sent okkur tölvupóst á <a href="mailto:hallo@urdarbrunnur.is">hallo@urdarbrunnur.is</a>.


<span class="last-updated">Uppfært 15. desember 2023</span>
`;

const markdownEn = `
To drive on Icelandic roads and streets, you will first need a driving licence and if you want to acquire one you need to go through a formal process to learn how to drive.

You can start learning to drive at 16 years old, but to be granted a driving licence you must have reached the age of 17.

**In short** the driving school is split into

- 15+ practical driving lessons with a driving instructor
- 3 courses (2 academic and 1 practical)
- Learner driving (*Æfingaakstur*)
- 2 tests (1 written and 1 practical)

In total the <a href="/blogg/hvad-kostar-bilprof">cost of driving school ranges from 270.000 kr up to 400.000 kr</a> 

To start your driving education it is best to contact a driving instructor who can guide you through the process.


## The long road to a driving licence 

Að læra á bíl getur verið ruglandi. Margir aðilar koma að því og það er erfitt að átta sig á hvar maður er í ferlinu. 
Learning how to drive a car can be confusing. Many different parties are involved in the process and it can be difficult to know what you need to do next.

The process can be broken down into 11 steps::

1. <a href="#okukennari">Find a driving instructor</a>
2. <a href="#namsleyfi">Acquire a study permit (námsheimild) from the district commissioners office (sýslumaður)</a>
3. <a href="#okuskoli1">Driving School 1 (Ökuskóli 1)</a>
4. <a href="#aefingaakstur">Learners driving (Æfingaakstur)</a>
5. <a href="#okuskoli2">Driving School 2 (Ökuskóli 2)</a>
6. Finish 12 practical lessons with an instructor
7. <a href="#okuskoli3">Driving School 3 (Ökuskóli 3)</a>
8. Finish 5 more practical lessons.
9. <a href="#skriflegt-prof">Written exam</a>
10. <a href="#verklegt-prof">Practical exam</a>
11. <a href="#bilprof">You get a driving licence!</a>

### <span id="okukennari">Finding a driving instructor</span>

The first step is to find a driving instructor who suits you. Generally is it easiest to ask your friends or family which driving instructor they have used and contact them.

It is a good idea to think through the choice of instructors as they will guide you through the process. You need to consider if their teaching style suits you, that their schedule fits with yours and how much they charge per lesson.

### <span id="namsleyfi">Applying for a study permit</span>

Before you start you need to apply for a study permit at the district commissioners office. You apply on island.is and then head to the district commissioners office with a pass photo. You should have the study permit before you start your studies.

You can apply for a study permit here: https://island.is/fyrsta-okuskirteini-namsheimild

### <span id="okuskoli1">Driving School (Ökuskóli 1)</span>
Driving School 1 (Ökuskóli 1) is the first of two written courses that you need to complete. It is common that students take driving school 1 online (with us or at other online driving schools), but you can choose to attend classes in person.
You can read more about how we <a href="/okuskoli1">arrange Driving School 1 here.</a>

<img src="/images/blogg/mosi-og-vegur.jpg" />

### <span id="aefingaakstur">Learner driving (Æfingaakstur)</span>

Learner driving is when a student practices driving a regular car with someone else in the car who usually is a parent or guardian. 

You can apply for a learner driver's permission when you've finished Driving School 1 and at least 10 practical lessons. Ökukennarinn metur hvort og hvenær nemandi er tilbúinn að hefja æfingaakstur og staðfestir það með sérstöku vottorði. 

When your insurance company has confirmed that your insurance covers a learner driver you can apply for a permit with the district commissioner's office.

Learner driving is not obligatory to finish driving school, however, it is a fantastic way to prepare yourself for driving in the future.

Note that your learner driver instructor must:

- Be a minimum of 24 years old
- Have a valid driving licence and at least 5 years of driving experience
- Not had their licence revoked or been penalized for reckless driving in the past year

### <span id="okuskoli2">Driving School 2 (Ökuskóli 2)</span>

Driving school 2 is a continuation of Driving school 1 and is structured in the same way. The student needs to complete both courses before taking the written exam.

### <span id="okuskoli3">Driving School 3 (Ökuskóli 3)</span>
Student needs to have finished 12 practical lessons before starting Driving school 3. 

Driving school 3 is a practical course that takes place inside an open-air driving area. The purpose of the exercise area is to simulate situations where the student will experience losing control of the vehicle. This is done with skid-tracks, skid-car, gravel tracks and roll-over simulator.

The student needs to finish Driving school 3 to be able to take the practical exam.

### <span id="skriflegt-prof">Written exam</span>

The written exam is a 30 question multiple-choice examination that is split into parts A and B.

Part A has 15 questions about traffic signs, surface markings on roads and priority rules in traffic. Part B has 15 general questions about traffic, the car, the driver and first-aid.

For each question there are three multiple-choice answers and either one, two or all three options may be correct. It counts as an error if the wrong options are chosen but also if the right options are not chosen.

The student can have a maximum of 7 errors on the exam and only 2 errors in part A. The student will receive their exam results immediately after the exam.

If the student does not pass the exam, at least one week must pass before another attempt is made.

It is common that students fail the written exam so it is recommended to practice well for the exam. Each attempt costs 6,550 kr, so it can get expensive if you fail often. 
 
### <span id="verklegt-prof">Practical exam</span>
To be able to take the practical exam you need to have finished 15 practical driving lessons and have finished Driving school 1, 2 and 3. 

The practical driving exam is split into two parts; an oral exam and a practical exam. The oral exam is taken in the vehicle where the examiner asks questions concerning the vehicle, e.g.  lighting systems, control systems, safety mechanisms and vehicle maintenance. If the oral exam goes well the practical exam will begin. A predetermined route chosen by the examiner is driven. While the student is driving the examiner registers points and deductions during the exam. The practical exam costs 17.370 kr. 

The examiner tells the student the exam results immediately. 

### <span id="bilprof">Temporary- and Full driving licence</span>
<img alt="An Icelandic driving licence" src="/images/lotur/Okuskirteini.png">
<p class="image-caption">An Icelandic driving licence.</p>

When the exams have been passed and the studies concluded the student receives their first driving licence which is a temporary driving licence.

The temporary licence is valid for three years, during which fewer points are needed to have the licence revoked. If the driver has not received a point for traffic violation or had their licence revoked within a year the driver can take a driving assessment with their driving instructor. 

After this assessment the driver receives a full driving licence that is valid for 15 years.

At this point the driver's formal education is finished.


### Further reading 
<a href="https://www.samgongustofa.is/umferd/nam-og-rettindi/okunam/">On the Samgöngustofa (The Icelandic Transport Authority) webpage you can find all kinds of helpful material on the process.</a>

<a href="/blogg/hvad-kostar-bilprof">How much does it cost to get a driving licence?</a> 

Further questions? Reach out to us at <a href="mailto:hallo@urdarbrunnur.is">hallo@urdarbrunnur.is</a>.


<span class="last-updated">Updated 15. December 2023</span>
`;

export const translations = {
  is: {
    Process: {
      BlogPost: markdown,
      Title: "Hvernig fær maður bílpróf?",
      Description: "Allt sem þú þarft að vita um ökunámið",
    },
  },
  en: {
    Process: {
      BlogPost: markdownEn,
      Title: "How do you get a driving license in Iceland?",
      Description:
        "Everything you need to know about learning to drive in Iceland",
    },
  },
};

const Process = () => {
  const { t } = useTranslation();
  const keywords = ["bílpróf", "b réttindi", "ökunám", "æfingarakstur"];
  const imageOptions = {
    imgCaption: "Ökunámið í einfaldri mynd",
    imgAlt: "bílpróf",
    imgSrc: "/images/blogg/bilprof.jpg",
  };
  const parseMarkdown = marked(t("Process.BlogPost"));
  const cleanMarkdown = DOMPurify.sanitize(parseMarkdown);

  return (
    <Template
      title={t("Process.Title")}
      description={t("Process.Description")}
      keywords={keywords}
      imageOptions={imageOptions}
    >
      <article dangerouslySetInnerHTML={{ __html: cleanMarkdown }}></article>
    </Template>
  );
};

export default Process;
