import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getPageFromPath, getPath } from "../../../pages";
import { useHistory } from "react-router-dom";
import { useGetLang } from "../../hooks/useGetLang";
import axios from "axios";

export const translations = {
  is: {
    LangToggle: {
      "Change language to English": "Change language to English",
    },
  },
  en: {
    LangToggle: {
      "Change language to English": "Breyta tungumáli í Íslensku",
    },
  },
};

const LangToggle = ({ onlyShowFlag = true }) => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const lang = useGetLang();

  const changeLanguage = () => {
    const newLang = lang === "en" ? "is" : "en";

    const page = getPageFromPath({ path: location.pathname, pathLang: lang });
    const newPath = getPath({ page, lang: newLang });

    i18n.changeLanguage(newLang);
    localStorage.setItem("language", newLang);
    history.push(newPath);

    // Update the backend.
    axios.post("/api/user-information", { field: "language", value: newLang });

    changeLanguageInHtmlTag(newLang);
  };

  const changeLanguageInHtmlTag = (lang: "is" | "en") => {
    const html = document.querySelector("html");
    html?.setAttribute("lang", lang);
  };

  const showCorrectFlag = () => {
    const en = "/images/united-kingdom-flag-icon.webp";
    const is = "/images/iceland-flag-icon.webp";

    return lang === "is" ? en : is;
  };
  const correctFlag = showCorrectFlag();

  if (onlyShowFlag) {
    return (
      <li>
        <img
          style={{
            marginTop: "2px",
            cursor: "pointer",
          }}
          width="32px"
          role="button"
          data-cy="toggle-lang"
          tabIndex={0}
          onClick={changeLanguage}
          onKeyDown={(e) => {
            e.key === "Enter" ? changeLanguage() : "";
          }}
          src={correctFlag}
        />
      </li>
    );
  } else {
    return (
      <a
        style={{
          cursor: "pointer",
          fontSize: "18px",
        }}
        onClick={changeLanguage}
        onKeyDown={(e) => {
          e.key === "Enter" ? changeLanguage() : "";
        }}
      >
        <img
          style={{
            position: "relative",
            top: "5px",
            marginRight: "3px",
          }}
          width="32px"
          role="button"
          data-cy="toggle-lang"
          tabIndex={0}
          src={correctFlag}
        />
        {t("LangToggle.Change language to English")}
      </a>
    );
  }
};

export default LangToggle;
