import { Helmet as ReactHelmet } from "react-helmet";
import React from "react";

interface HelmetProps {
  pageTitle: string;
  keywords?: string;
  image?: string;
}

// TODO: Make "Urðarbrunnur ökuskóli" change with the language
// TODO: Make The keywords and site name change with the language

const Helmet = ({ pageTitle, keywords, image }: HelmetProps) => {
  const title = `${pageTitle} | Urðarbrunnur | Ökuskóli 1 og 2`;

  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta name="description" content={title} />
      <meta
        name="keywords"
        content={
          keywords
            ? keywords
            : "Ökuskóli, ökuskóli 1, ökuskóli 2, bílpróf á netinu, netökuskóli, Ökuskóli á netinu"
        }
      />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content={image ? image : "http://urdarbrunnur.is/images/icon-logo.gif"}
      />
      <meta property="og:site_name" content="Urðarbrunnur — Ökuskóli 1 og 2" />
    </ReactHelmet>
  );
};

export default Helmet;
