import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import Card from "../../../reusables/card";
import FormButton from "../../../reusables/formButton";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    TextSetting: {
      "Loading...": "Loading...",
      Breyta: "Breyta",
      "Hætta við": "Hætta við",
      Vista: "Vista",
      "er ekki á réttu formi": "er ekki á réttu formi",
    },
  },
  en: {
    TextSetting: {
      "Loading...": "Loading...",
      Breyta: "Change",
      "Hætta við": "Cancel",
      Vista: "Save",
      "er ekki á réttu formi": "is not in the correct format",
    },
  },
};

type TextSettingProps = {
  label: string;
  userData: string;
  name: string;
  updateUserData: Function;
};

const TextSetting: FC<TextSettingProps> = ({
  label,
  userData,
  name,
  updateUserData,
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");
  const [isDiff, setIsDiff] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  const toggleEditing = () => {
    setEditing(!editing);
    setError(false);
    setErrorMessage("");
    if (!value) {
      setValue(userData);
    }
  };

  // This ensures you're not submitting data to the API
  // unless an actual change has been made.
  useEffect(() => {
    if (value !== userData) {
      setIsDiff(true);
    } else {
      setIsDiff(false);
    }
  }, [value, userData]);

  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
  };

  const updateUserSettings = () => {
    if (isDiff) {
      // Set loading, clear error.
      setIsLoading(true);
      setError(false);
      setErrorMessage("");

      axios
        .post("/api/user-information", { field: name, value })
        .then((res) => {
          const { field, value } = res.data;
          updateUserData(field, value);
          toggleEditing();
        })
        .catch((err) => {
          setError(true);
          if (err.response.status === 400) {
            setErrorMessage(
              `${label} ${t("TextSetting.er ekki á réttu formi")}.`
            );
          } else {
            setErrorMessage(err.message);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // If there's no data to update, toggle out of editing mode.
      toggleEditing();
    }
  };

  return (
    <Card className="text-setting mb-5">
      <p className="label">
        <b>{label}</b>
      </p>
      <div className="text-input">
        {/* Replace input with loading indicator when submitting to backend */}
        {isLoading ? (
          <span className="loading-text">{t("TextSetting.Loading...")}</span>
        ) : editing ? (
          <input
            style={{ lineHeight: "22px", padding: "4px 2px" }}
            type="text"
            value={value}
            onChange={handleUserInput}
          ></input>
        ) : (
          <span>{userData}</span>
        )}
      </div>

      {editing ? (
        <FormButton
          size="small"
          text={t("TextSetting.Vista")}
          onClick={updateUserSettings}
        />
      ) : (
        ""
      )}
      <button className="edit-button" onClick={toggleEditing}>
        {editing ? t("TextSetting.Hætta við") : t("TextSetting.Breyta")}
      </button>
      <p className="error">{error && errorMessage}</p>
    </Card>
  );
};

export default TextSetting;
