import { useEffect, FC } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

interface ChildComponentProps {
	history: History;
}

const ScrollToTop: FC<ChildComponentProps> = ({ history }) => {
	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return () => {
			unlisten();
		};
	}, []);

	return null;
};

export default withRouter(ScrollToTop);
