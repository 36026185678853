import React, { FC } from "react";
import { Helmet } from "react-helmet";
import DesktopMenu from "./Navigation";
import MobileMenu from "./MobileMenu";
import Footer from "./Footer";

type TemplateProps = {
  title: string;
  url: string;
  description: string;
  keywords: Array<String>;
};

const PageTemplate: FC<TemplateProps> = ({
  title,
  url,
  description,
  keywords,
  children,
}) => {
  const keywordsString = keywords.join(", ");

  return (
    <div className="site-wrap">
      <Helmet>
        <title>{title} | Urðarbrunnur Ökuskóli</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywordsString} />
        <meta name="og:title" content={`${title} | Urðarbrunnur Ökuskóli`} />
        <meta name="og:type" content="website" />
        <meta name="og:url" content={url} />
        <meta name="og:site_name" content="Urðarbrunnur Ökuskóli" />
      </Helmet>
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body default-page">
          <div className="default-text-container">{children}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PageTemplate;
