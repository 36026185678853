import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    StartAgain: {
      "Byrja upp á nýtt": "Byrja upp á nýtt",
    },
  },
  en: {
    StartAgain: {
      "Byrja upp á nýtt": "Restart course",
    },
  },
};

interface StartAgainProps {
  daysLeft: number;
  courseStatus: "Finished" | "Expired" | "Not Started" | "Ongoing";
  startAgain: () => void;
}

export const StartAgain: FC<StartAgainProps> = ({
  daysLeft,
  courseStatus,
  startAgain,
}) => {
  const { t } = useTranslation();

  let startAgainJsx = null;
  if (
    courseStatus === "Expired" ||
    (daysLeft <= 0 && courseStatus !== "Finished")
  ) {
    startAgainJsx = (
      <span className="btn restart-course" onClick={startAgain}>
        <i className="fa fa-refresh" aria-hidden="true"></i>{" "}
        {t("StartAgain.Byrja upp á nýtt")}
      </span>
    );
  }

  return <>{startAgainJsx}</>;
};

export default StartAgain;
