import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../reusables/button";
import { Pages } from "../../../routes/routes";

export const translations = {
  is: {
    HeroSection: {
      "Náminu er dreift yfir 6 daga": "Náminu er dreift yfir 6 daga",
      VerðPeningar: "Námskeiðið kostar 12.500 kr.",
      "Myndrænt og gangvirkt nám": "Myndrænt og gangvirkt nám",
    },
  },
  en: {
    HeroSection: {
      "Náminu er dreift yfir 6 daga": "Lessons spread across 6 days",
      VerðPeningar: "It costs 12.500 kr.",
      "Myndrænt og gangvirkt nám": "Visual and interactive content",
    },
  },
};

interface HeroSectionProps {
  headline: string;
  introText: string;
  buttonCTA: string;
  imageURL: string;
}

const HeroSection = ({
  headline,
  introText,
  buttonCTA,
  imageURL,
}: HeroSectionProps) => {
  const { t } = useTranslation();

  return (
    <div className="salespage-hero-section">
      <div className="first-box">
        <h1 style={{ marginTop: "0" }}>{headline}</h1>
        <p style={{ maxWidth: "80%" }}>{introText}</p>
        <div className="points">
          {/* These will be the same */}
          <p>
            <i className="fa fa-clock-o" />
            {t("HeroSection.Náminu er dreift yfir 6 daga")}
          </p>
          <p>
            <i className="fa fa-money" />
            {t("HeroSection.VerðPeningar")}
          </p>
          <p style={{ marginBottom: "44px" }}>
            <i className="fa fa-picture-o " />
            {t("HeroSection.Myndrænt og gangvirkt nám")}
          </p>

          <Button
            type="react-router-link"
            href={t(Pages.Registration)}
            size="big"
          >
            {buttonCTA}{" "}
            <i
              className="fa fa-chevron-right"
              aria-hidden="true"
              style={{ color: "white", fontSize: "16px" }}
            ></i>
          </Button>
        </div>
      </div>
      <div className="hide-on-mobile second-box">
        <img className="main-image" src={imageURL}></img>
      </div>
    </div>
  );
};

export default HeroSection;
