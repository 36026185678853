import React, { FC } from "react";

type CardProps = {
	className?: string;
};

const Card: FC<CardProps> = ({ children, className }) => {
	const appliedClassName = "card " + className;
	return <div className={appliedClassName}>{children}</div>;
};

export default Card;
