import React, { useEffect, useState } from "react";
import CourseWrapper from "../../CourseWrapper";
import axios from "axios";

const Content = () => {
  const [courseContent, setCourseContent] = useState<any>(null);

  useEffect(() => {
    axios.get("/api/admin/course-content").then((res) => {
      console.log(res.data);
      setCourseContent(res.data);
    });
  }, []);

  return (
    <CourseWrapper title={"Skref yfirlit"}>
      {/* TODO: List steps*/}
      <h1>Skref</h1>

      {courseContent !== null
        ? courseContent.map((courseRound) => {
            return (
              <div key={courseRound.id}>
                <p>
                  <b>
                    Ö{courseRound.course_id} {courseRound.name}
                  </b>
                </p>
                {courseRound.steps.map((step) => {
                  return (
                    <>
                      <a
                        href={`/command/content/skref/${step.id}`}
                        key={step.id}
                      >
                        {step.nr}
                      </a>{" "}
                    </>
                  );
                })}
              </div>
            );
          })
        : null}
    </CourseWrapper>
  );
};

export default Content;
