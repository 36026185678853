import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type NavItemProps = {
  courseId: string;
  roundNr: number;
  roundStatus: "Not Started" | "Finished" | "Ongoing" | "Expired";
  isCurrentRound: boolean;
  nextRoundOpen: boolean;
  messageFeatureUsed: boolean;
  currentStep: number;
};

const NavItem: FC<NavItemProps> = ({
  courseId,
  roundNr,
  roundStatus,
  isCurrentRound,
  nextRoundOpen,
  messageFeatureUsed,
  currentStep,
}) => {
  let icon = "fa fa-square-o";
  let status = "enabled-link";
  let step = "1";
  const { t } = useTranslation();

  if (roundStatus === "Not Started") {
    icon = "fa fa-lock";
    status = "disabled-link";
  }

  if (nextRoundOpen && isCurrentRound) {
    icon = "fa fa-square-o";
    status = "enabled-link";
    step = currentStep.toString();
  }

  if (roundStatus === "Finished") {
    icon = "fa fa-check-square-o";
  }

  if (parseInt(courseId) === 2 && !messageFeatureUsed) {
    return (
      <Link
        to={`/okuskoli-${courseId}/lota-${roundNr}/skref-${step}`}
        className="disabled-link"
      >
        <p>
          <i className="fa fa-lock"></i>{" "}
          {`${t("DashNavigation.Lota")} ${roundNr}`}
        </p>
      </Link>
    );
  } else {
    return (
      <Link
        to={`/okuskoli-${courseId}/lota-${roundNr}/skref-${step}`}
        className={`${status}`}
      >
        <p>
          <i className={icon}></i> {`${t("DashNavigation.Lota")} ${roundNr}`}
        </p>
      </Link>
    );
  }
};

export default NavItem;
