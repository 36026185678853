import React from "react";
import CourseWrapper from "../CourseWrapper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MagnetForm from "./MagnetForm";

export const translations = {
  is: {
    Done: {
      "Vel gert": "Til hamingju!",
      "Þú hefur lokið Ökuskóla 1": "Þú hefur lokið Ökuskóla 1",
      Text1:
        "Nú getur þú byrjað að æfa þig í æfingarakstri eða skráð þig í Ökuskóla 2 og haldið áfram að sýna prýðilegan árgangur á sviði ökunáms.",
      Text2:
        "Ef þú vilt fá æfingaaksturssegulinn sendan heim til þín þá getur þú fyllt út formið fyrir neðan með heimilisfanginu þínu og við skulum senda þér hann alveg ókeypis!",
      Text3:
        "Það tekur venjulega 4-5 virka daga fyrir segulinn að koma með pósti.",
      "Aftur á stjórnborð": "Aftur á stjórnborð",
      Segulinn: "Segulinn",
      Æfingaaskturssegulinn: "Æfingaasktur segul",
    },
  },
  en: {
    Done: {
      "Vel gert": "Well done!",
      "Þú hefur lokið Ökuskóla 1": "You've finished Driving School 1",
      Text1:
        "You can now begin practicing by doing Learner Driving (æfingaakstur). You can now also begin Driving School 2.",
      Text2:
        "If you want to have the Learner Driving magnet sent home to you then you can fill out the form below with your address and we will send it to you for free!",
      Text3: "It usually takes 4-5 working days for the magnet to arrive.",
      "Aftur á stjórnborð": "Back to dashboard",
      Segulinn: "The magnet",
      Æfingaaskturssegulinn: "Learner Driving magnet",
    },
  },
};

const Done = () => {
  const { t } = useTranslation();

  return (
    <CourseWrapper courseId="1" title={t("Done.Vel gert")}>
      <div>
        <h1>{t("Done.Vel gert")}</h1>
        <h2>🎉 {t("Done.Þú hefur lokið Ökuskóla 1")} 🎉</h2>
        <p>{t("Done.Text1")}</p>

        <h2>{t("Done.Segulinn")}</h2>
        <p>{t("Done.Text2")}</p>
        <p>{t("Done.Text3")}</p>

        <img
          alt="æfingaaksturssegull"
          src="/images/lotur/aefingarakstur.jpg"
          width="300px"
        ></img>
        <p className="caption" style={{ marginTop: 0 }}>
          {t("Done.Æfingaaskturssegulinn")}
        </p>

        <MagnetForm />

        <Link to="/stjornbord" className="btn">
          <i className="fa fa-tachometer"></i> {t("Done.Aftur á stjórnborð")}
        </Link>
      </div>
    </CourseWrapper>
  );
};

export default Done;
