import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Helmet from "./Helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SelectSearch from "react-select-search";

import { AuthReducerTypes, IndexReducerTypes } from "../store/types";
import DesktopMenu from "./Navigation";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";
import RegistrationError from "./RegistrationError";
import { useTranslation } from "react-i18next";
import { Pages } from "../../routes/routes";
import { useGetLang } from "../../hooks/useGetLang";

/* eslint-env browser */
if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-40901004-10");
}

interface RegistrationProps {
  authReducer: AuthReducerTypes;
}

type Teacher = {
  id: number;
  name: string;
  town: string;
  zip_code: number;
};

type RegistrationState = {
  errors: [];
  error: boolean;
  ssnError: string;
  passwordError: string;
  teacherError: string;
  emailExistsError: string;
  isLoading: boolean;
  teachers: Teacher[];
  isFormValid: boolean;
  form: {
    name: {
      value: string;
      valid: boolean;
    };
    email: {
      value: string;
      valid: boolean;
    };
    teacher: {
      value: number | null;
      valid: boolean;
    };
    kennitala: {
      value: string;
      valid: boolean;
    };
    password: {
      value: string;
      valid: boolean;
    };
    skilmalar: {
      value: boolean;
      valid: boolean;
    };
    namsheimild: {
      value: boolean;
      valid: boolean;
    };
  };
};

export const translations = {
  is: {
    Registration: {
      Nýskráning: "Nýskráning",
      Nafn: "Fullt nafn",
      Netfang: "Netfang",
      "Ökukennarinn þinn": "Ökukennarinn þinn",
      Aðgangur: "Aðgangur",
      "Kennitala nemanda": "Kennitala nemanda",
      Lykilorð: "Lykilorð",
      "Ég samþykki": "Ég samþykki",
      "skilmála Urðarbrunns": "skilmála Urðarbrunns",
      Nýskrá: "Nýskrá",
      "Veldu ökukennara": "Veldu ökukennara",
      Námsheimild: "Ég hef farið í einn eða fleiri verklegan ökutíma.",
    },
  },
  en: {
    Registration: {
      Nýskráning: "Register",
      Nafn: "Full name",
      Netfang: "Email",
      "Ökukennarinn þinn": "Your driving instructor",
      Aðgangur: "Your account",
      "Kennitala nemanda": "Kennitala",
      Lykilorð: "Password",
      "Ég samþykki": "I agree to the schools",
      "skilmála Urðarbrunns": "terms and conditions",
      Nýskrá: "Nýskrá",
      "Veldu ökukennara": "Choose instructor",
      Námsheimild: "I have gone to one or more driving instructor lessons.",
    },
  },
};

const Registration: React.FC<RegistrationProps> = ({ authReducer }) => {
  const { t } = useTranslation();
  const lang = useGetLang();
  const history = useHistory();

  const [lastStateChange, setLastStateChange] = useState(Date.now()); // Hacky way to subscribe to changes in the form.
  const [state, setState] = useState<RegistrationState>({
    errors: [],
    error: false,
    ssnError: "",
    passwordError: "",
    teacherError: "",
    emailExistsError: "",
    isLoading: false,
    teachers: [],
    isFormValid: false,
    form: {
      name: {
        value: "",
        valid: true,
      },
      email: {
        value: "",
        valid: true,
      },
      teacher: {
        value: null,
        valid: false,
      },
      kennitala: {
        value: "",
        valid: true,
      },
      password: {
        value: "",
        valid: true,
      },
      skilmalar: {
        value: false,
        valid: false,
      },
      namsheimild: {
        value: false,
        valid: false,
      },
    },
  });

  useEffect(() => {
    fetchDrivingInstructors();
    redirectIfLoggedIn();
    checkIfFormIsValid();
  }, []);

  useEffect(() => {
    checkIfFormIsValid();
  }, [lastStateChange]);

  const redirectIfLoggedIn = () => {
    if (authReducer.authenticated) {
      history.push("/stjornbord");
    }
  };

  const checkIfFormIsValid = () => {
    const { form } = state;
    let isFormValid = true;
    Object.keys(form).forEach((element) => {
      if (!form[element].valid) {
        isFormValid = false;
      }
    });
    setState((prevState) => ({ ...prevState, isFormValid }));
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    const { isFormValid } = state;
    if (isFormValid && event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { form } = state;
    const { name } = event.target;
    let isFieldValid = false;

    if (event.target.value.length > 0) {
      isFieldValid = true;
    }

    setState({
      ...state,
      form: {
        ...form,
        [name]: {
          ...form[name],
          value: event.target.value,
          valid: isFieldValid,
        },
      },
    });
    setLastStateChange(Date.now());
  };

  const updateCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { form } = state;
    const { name } = event.target;

    setState({
      ...state,
      form: {
        ...form,
        [name]: {
          ...form[name],
          value: !form[name].value,
          valid: !form[name].valid,
        },
      },
    });
    setLastStateChange(Date.now());
  };

  const handleSelectChange = (chosenTeacher: number) => {
    const { form } = state;
    setState({
      ...state,
      form: { ...form, teacher: { value: chosenTeacher, valid: true } },
    });
    setLastStateChange(Date.now());
  };

  const fetchDrivingInstructors = () => {
    axios.get(`/api/teachers`).then((res) => {
      const teachers = res.data.response;
      setState((prevState) => ({ ...prevState, teachers }));
    });
  };

  const handleSubmit = (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    const { name, email, kennitala, password, teacher } = state.form;

    setState({ ...state, error: false, isLoading: true });
    axios
      .post(`/api/register-user`, {
        name: name.value,
        ssn: kennitala.value,
        password: password.value,
        email: email.value,
        teacherId: teacher.value,
        language: lang,
      })
      .then(() => {
        ReactGA.event({
          category: "Sign up flow",
          action: "User sign up",
        });
        ReactGA.event("sign_up");
        setState({ ...state, error: false, isLoading: false });
        window.location.href = "/stjornbord";
      })
      .catch((error) => {
        const errorArray = error.response.data.errors;

        setState({
          ...state,
          teacherError: "",
          emailExistsError: "",
          passwordError: "",
          ssnError: "",
          errors: error.response.data.errors,
          error: true,
          isLoading: false,
        });

        errorArray.forEach((errorItem: any) => {
          if (errorItem.hasOwnProperty("email")) {
            setState({ ...state, emailExistsError: errorItem.email });
          }
          if (errorItem.hasOwnProperty("password")) {
            setState({ ...state, passwordError: errorItem.password });
          }
          if (errorItem.hasOwnProperty("ssn")) {
            setState({ ...state, ssnError: errorItem.ssn });
          }
          if (errorItem.hasOwnProperty("teacher")) {
            setState({ ...state, teacherError: errorItem.teacher });
          }
        });
      });
  };

  const { form, teachers, isFormValid, isLoading, error } = state;
  const { name, email, kennitala, password, skilmalar, namsheimild, teacher } =
    form;

  const options = teachers.map((teacherItem) => ({
    name: teacherItem.name,
    value: teacherItem.id,
  }));

  return (
    <div className="site-wrap">
      <div className="push-wrap">
        <Helmet pageTitle={t("Registration.Nýskráning")} />
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body">
          <div className="skraning-section">
            <div>
              <h1>{t("Registration.Nýskráning")}</h1>
              <form className="skraning-form" onKeyDown={onKeyDown}>
                <div className="field-container">
                  <label>
                    <p className="field-name">{t("Registration.Nafn")}</p>
                    <input
                      type="text"
                      required
                      id="name"
                      name="name"
                      value={name.value}
                      onChange={handleTextFieldChange}
                    />
                  </label>
                </div>
                <div className="field-container">
                  <label>
                    <p className="field-name">{t("Registration.Netfang")}</p>
                    <input
                      className={
                        state.emailExistsError === "" ? "" : "has-error"
                      }
                      type="text"
                      required
                      id="email"
                      name="email"
                      value={email.value}
                      onChange={handleTextFieldChange}
                    />
                  </label>
                  <RegistrationError errorMessage={state.emailExistsError} />
                </div>
                <div className="field-container">
                  <p className="field-name">
                    {t("Registration.Ökukennarinn þinn")}
                  </p>
                  <SelectSearch
                    options={options}
                    search={true}
                    onChange={(value) =>
                      handleSelectChange(value as unknown as number)
                    }
                    value={
                      teacher.value !== null
                        ? teacher.value.toString()
                        : undefined
                    }
                    placeholder={t("Registration.Veldu ökukennara")}
                  />
                  <RegistrationError errorMessage={state.teacherError} />
                </div>

                <h3>{t("Registration.Aðgangur")}</h3>
                <div className="field-container">
                  <label>
                    <p className="field-name">
                      {t("Registration.Kennitala nemanda")}
                    </p>
                    <input
                      className={state.ssnError == null ? "" : "has-error"}
                      type="text"
                      required
                      id="kennitala"
                      name="kennitala"
                      value={kennitala.value}
                      onChange={handleTextFieldChange}
                      maxLength={11}
                    />
                  </label>
                  <RegistrationError errorMessage={state.ssnError} />
                </div>
                <div className="field-container last-field-container">
                  <label>
                    <p className="field-name">{t("Registration.Lykilorð")}</p>
                    <input
                      className={state.passwordError == null ? "" : "has-error"}
                      type="password"
                      required
                      id="password"
                      name="password"
                      value={password.value}
                      onChange={handleTextFieldChange}
                    />
                  </label>
                  <RegistrationError errorMessage={state.passwordError} />
                </div>
                <div className="checkbox-container">
                  <label htmlFor="namsheimild">
                    <input
                      type="checkbox"
                      id="namsheimild"
                      name="namsheimild"
                      required
                      checked={namsheimild.value}
                      onChange={updateCheckbox}
                    />
                    {t("Registration.Námsheimild")}
                  </label>
                </div>
                <div className="checkbox-container">
                  <label htmlFor="skilmalar">
                    <input
                      type="checkbox"
                      id="skilmalar"
                      name="skilmalar"
                      required
                      checked={skilmalar.value}
                      onChange={updateCheckbox}
                    />
                    {t("Registration.Ég samþykki")}{" "}
                    <Link
                      to={t(Pages.TermsAndConditions)}
                      target="_blank"
                      className="skilmala-link"
                    >
                      {t("Registration.skilmála Urðarbrunns")}.
                    </Link>
                  </label>
                </div>
                <div className="btn-div">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    id="submit"
                    className="btn"
                    disabled={!isFormValid}
                  >
                    {isLoading ? (
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {t("Registration.Nýskrá")}
                  </button>
                  {error ? (
                    <RegistrationError
                      errorMessage={
                        "Það kom upp villa, sjáðu frekar í forminu ↑"
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Registration);
