import React, { FC } from "react";
import { Steps } from "../../store/steps";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    SubmitStepButton: {
      Svara: "Svara",
    },
  },
  en: {
    SubmitStepButton: {
      Svara: "Submit answers",
    },
  },
};

interface SubmitStepButtonProps {
  stepId: string | null;
  stepNr: string;
  steps: Steps[];
  submitAssignment: (arg1: Steps, arg2: string | null) => void;
}

export const SubmitStepButton: FC<SubmitStepButtonProps> = ({
  stepId,
  stepNr,
  steps,
  submitAssignment,
}) => {
  const shouldDisplay = shouldDisplayButton(steps, stepNr);
  const stepIndex = indexOfStep(steps, stepNr);
  const { t } = useTranslation();

  if (shouldDisplay) {
    return (
      <button
        type="submit"
        id="answer"
        className="btn"
        onClick={() => submitAssignment(steps[stepIndex], stepId)}
      >
        {t("SubmitStepButton.Svara")}
      </button>
    );
  }

  return null;
};

function indexOfStep(steps: Steps[], stepNr: string): number {
  let stepIndex = 0;
  steps.forEach((step, index) => {
    if (step.nr === parseInt(stepNr)) {
      stepIndex = index;
    }
  });
  return stepIndex;
}

function shouldDisplayButton(steps: Steps[], stepId: string): boolean {
  let shouldDisplay = true;
  steps.forEach((step) => {
    if (step.nr === parseInt(stepId)) {
      if (step.user_course_round_steps[0].status === "Finished") {
        shouldDisplay = false;
      }
    }
  });
  return shouldDisplay;
}

export default SubmitStepButton;
