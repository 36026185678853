import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

export const translations = {
  is: {
    PrevStepButton: {
      "Til baka": "Til baka",
    },
  },
  en: {
    PrevStepButton: {
      "Til baka": "Back",
    },
  },
};

interface PrevStepButtonProps {
  history: RouteComponentProps["history"]; // eslint-disable-line
  params: { courseId: string; roundNr: string; stepNr: string };
}

export const PrevStepButton: FC<PrevStepButtonProps> = ({
  history,
  params,
}) => {
  const { courseId, roundNr, stepNr } = params;
  const { t } = useTranslation();

  function prevStep() {
    const prevStep = parseInt(stepNr) - 1;
    history.push(`/okuskoli-${courseId}/lota-${roundNr}/skref-${prevStep}`);
  }

  if (parseInt(stepNr) > 1) {
    return (
      <button type="submit" id="previous" className="btn" onClick={prevStep}>
        <i className="fa fa-arrow-left"></i> {t("PrevStepButton.Til baka")}
      </button>
    );
  }

  return null;
};

export default PrevStepButton;
