import React, { useEffect } from "react";
import CourseWrapper from "../CourseWrapper";
import { useApi } from "../../hooks";
import { getUsers } from "../../api/courseApi";
import Button from "../../reusables/button";

type userTypes = {
  name: string;
  email: string;
  ssn: string;
  address: string;
  zip_code: number;
  id: number;
  created_date: string;
};

const Admin = () => {
  const { data: users, status, message, exec: initGetUsers } = useApi(getUsers);
  useEffect(() => {
    initGetUsers();
  }, []);

  return (
    <CourseWrapper title="Yfirlit nemendur">
      <div className="main-content">
        <div className="lotu-container">
          <div className="admin-page">
            <h1>Nemendur</h1>
            <div>
              {status === "PENDING" && "loading"}
              {status === "ERROR" && message}
              {users &&
                users.map(
                  ({
                    name,
                    email,
                    ssn,
                    address,
                    zip_code,
                    id,
                    created_date,
                  }: userTypes) => {
                    const date = new Date(created_date);
                    return (
                      <div className="student" key={id}>
                        <p>
                          {" "}
                          {name} | {ssn} | {id} | {email} | {address},{" "}
                          {zip_code} | {date.toLocaleString()}
                        </p>
                        <Button
                          size="tiny"
                          type="react-router-link"
                          href={`/command/nemandi/${id}`}
                        >
                          Skoða betur
                        </Button>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </div>
    </CourseWrapper>
  );
};

export default Admin;
