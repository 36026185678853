import React, { useState } from "react";
import DesktopMenu from "./Navigation";
import MobileMenu from "./MobileMenu";
import axios from "axios";
import Helmet from "./Helmet";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    ForgotPassword: {
      "Gleymt Lykilorð": "Gleymt Lykilorð",
      ForgotPasswordText:
        "Sláðu inn kennitöluna þína og við munum senda þér hlekk sem þú getur smellt á til að breyta um lykilorð.",
      Senda: "Senda",
      "Þessi kennitala er ekki á skrá": "Þessi kennitala er ekki á skrá",
      SuccessMessage:
        "Tölvupóstur hefur verið sendur á netfangið sem þú skráðir með frekari leiðbeiningum.",
      "Urðarbrunnur Ökuskóli": "Urðarbrunnur Driving School",
      Kennitala: "Kennitala",
    },
  },
  en: {
    ForgotPassword: {
      "Gleymt Lykilorð": "Forgotten Password",
      ForgotPasswordText:
        "Put your kennitala into the field below and we'll send you a link to your email which you can follow to reset your password.",
      Senda: "Send email",
      "Þessi kennitala er ekki á skrá":
        "We don't have any record of this kennitala",
      SuccessMessage:
        "Tölvupóstur hefur verið sendur á netfangið sem þú skráðir með frekari leiðbeiningum.",
      "Urðarbrunnur Ökuskóli": "Urðarbrunnur Driving School",
      Kennitala: "Kennitala",
    },
  },
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [ssn, setSsn] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function changeSsn(event: React.ChangeEvent<HTMLInputElement>) {
    const ssn = event.target.value.replace(/(\d{6})(\d+)/, "$1-$2");
    setSsn(ssn);
  }

  function submitSsn(e: React.FormEvent) {
    e.preventDefault();

    axios
      .post(`/api/forgot-password`, { ssn: ssn })
      .then(() => {
        setError(false);
        setErrorMessage("");
        setSuccess(true);
        setSuccessMessage(t("ForgotPassword.SuccessMessage"));
      })
      .catch((error) => {
        let newErrorMessage;
        if (error.response.status === 404) {
          newErrorMessage = t(`ForgotPassword.Þessi kennitala er ekki á skrá`);
        } else {
          newErrorMessage = `Villa: ${error.response.status} - ${error.response.statusText}.`;
        }
        setError(true);
        setErrorMessage(newErrorMessage);
        setSuccess(false);
        setSuccessMessage("");
      });
  }

  return (
    <div className="site-wrap">
      <Helmet pageTitle={t("ForgotPassword.Gleymt Lykilorð")} />
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body default-page forgot-password-page">
          <div>
            <h1>{t("ForgotPassword.Gleymt Lykilorð")}</h1>
            <form onSubmit={submitSsn}>
              <div className="form-container">
                <p>{t("ForgotPassword.ForgotPasswordText")}</p>
                <label>
                  <p className="field-name">{t("ForgotPassword.Kennitala")}</p>
                  <input
                    autoFocus
                    id="kennitala-field"
                    type="text"
                    maxLength={11}
                    name="ssn"
                    placeholder="999999-9999"
                    required
                    value={ssn}
                    onChange={changeSsn}
                  />
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-submit"
                  // onClick={submitSsn}
                >
                  {t("ForgotPassword.Senda")}
                </button>
              </div>
            </form>
            {success ? (
              <p className="feedback-message success-message">
                <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                {successMessage}
              </p>
            ) : (
              ""
            )}
            {error ? (
              <p className="feedback-message error-message">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>{" "}
                {errorMessage}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
