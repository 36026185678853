import "./i18n";
import "lazysizes";

/* eslint-env browser */
import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";

import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import combineReducers from "./index-reducers";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WZK7TZB",
};

// Apply logger if it's not in production.
let store;
if (process.env.NODE_ENV === "production") {
  store = createStore(combineReducers);
} else {
  store = createStore(combineReducers, applyMiddleware(logger));
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://e1aeec67347f4c6aa830300e6728ee1f@o368748.ingest.sentry.io/5408270",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  TagManager.initialize(tagManagerArgs);
}

// Fixes Google Translate issue
// See here: https://github.com/facebook/react/issues/11538
if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot remove a child from a different parent",
          child,
          this
        );
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("react-main-mount")
);
