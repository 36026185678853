import React, { useEffect, useState } from "react";
import { useApi } from "../../hooks";
import { getStatistics } from "../../api/courseApi";
import Card from "../../reusables/card";

interface MonthlyStats {
  month: string;
  count: string;
  total: string;
}

interface DailyStats {
  day: string;
  count: string;
  total: string;
}

interface TeacherStats {
  teacher: {
    id: number;
    name: string;
  };
  studentCount: string;
  total: string;
  isNewTeacher: boolean;
}

interface CurrentMonthStats {
  count: number;
  total: number;
  projectedCount: number;
  projectedTotal: number;
  daysInMonth: number;
  daysPassed: number;
  dailyAverage: number;
}

const Statistics = () => {
  const { data: response, exec: initGetStatistics } = useApi(getStatistics);
  const [monthlyStats, setMonthlyStats] = useState<MonthlyStats[]>([]);
  const [dailyStats, setDailyStats] = useState<DailyStats[]>([]);
  const [teacherStats, setTeacherStats] = useState<TeacherStats[]>([]);
  const [currentMonth, setCurrentMonth] = useState<CurrentMonthStats | null>(
    null
  );

  useEffect(() => {
    initGetStatistics();
  }, []);

  useEffect(() => {
    if (response) {
      setMonthlyStats(response.lastThreeMonths);
      setDailyStats(response.last14Days);
      setTeacherStats(response.teacherStats);
      setCurrentMonth(response.currentMonth);
    }
  }, [response]);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("is-IS", {
      style: "currency",
      currency: "ISK",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("is-IS", {
      year: "numeric",
      month: "long",
    });
  };

  const formatDayDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("is-IS", {
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Tölfræði</h1>

      {/* Current Month Statistics */}
      {currentMonth && (
        <Card className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Yfirlit mánaðarins</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-sm text-gray-600">
                Sala það sem af er mánuði
              </div>
              <div className="text-2xl font-bold">
                {formatCurrency(currentMonth.total)}
              </div>
              <div className="text-sm text-gray-600">
                {currentMonth.count} sölur
              </div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-sm text-gray-600">Meðaltal á dag</div>
              <div className="text-2xl font-bold">
                {formatCurrency(currentMonth.dailyAverage)}
              </div>
              <div className="text-sm text-gray-600">
                {currentMonth.daysPassed} dagar liðnir af{" "}
                {currentMonth.daysInMonth}
              </div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-sm text-gray-600">
                Áætluð heildarsala mánaðarins
              </div>
              <div className="text-2xl font-bold">
                {formatCurrency(currentMonth.projectedTotal)}
              </div>
              <div className="text-sm text-gray-600">
                Áætlaðar sölur: {currentMonth.projectedCount}
              </div>
            </div>
          </div>
        </Card>
      )}

      {/* Last 14 Days Statistics */}
      <Card className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Síðustu 14 dagar</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Dagur
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fjöldi viðskipta
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Heildarsala
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {dailyStats.map((stat, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatDayDate(stat.day)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {stat.count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatCurrency(parseInt(stat.total || "0"))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>

      {/* Last Three Months Statistics */}
      <Card>
        <h2 className="text-xl font-semibold mb-4">Síðustu 13 mánuðir</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Mánuður
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fjöldi viðskipta
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Heildarsala
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {monthlyStats.map((stat, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatDate(stat.month)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {stat.count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatCurrency(parseInt(stat.total))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>

      {/* Teacher Statistics */}
      <Card className="mt-8">
        <h2 className="text-xl font-semibold mb-4">
          Kennarar síðustu 12 mánuði
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Kennari
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fjöldi nemenda
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Heildarsala
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {teacherStats.map((stat, index) => (
                <tr
                  key={index}
                  className={stat.isNewTeacher ? "bg-yellow-50" : ""}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <div className="flex items-center">
                      <span
                        className={`${
                          stat.isNewTeacher
                            ? "font-semibold text-yellow-800"
                            : "text-gray-900"
                        }`}
                      >
                        {stat.teacher.name}
                      </span>
                      {stat.isNewTeacher && (
                        <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                          Nýr kennari
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {stat.studentCount}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {formatCurrency(parseInt(stat.total))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Statistics;
