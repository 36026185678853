import React, { FC } from "react";
import Navigation from "./navigation";
import { Helmet } from "react-helmet";
import FeedbackBox from "./courses/feedback";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    CourseWrapper: {
      "Urðarbrunnur Ökuskóli": "Urðarbrunnur Ökuskóli",
    },
  },
  en: {
    CourseWrapper: {
      "Urðarbrunnur Ökuskóli": "Urðarbrunnur Driving School",
    },
  },
};

type CourseWrapperProps = {
  title?: string;
  courseId?: string;
  paymentStep?: "step-1" | "step-2" | "step-3";
  children: React.ReactNode;
};

const CourseWrapper: FC<CourseWrapperProps> = ({
  title,
  courseId,
  paymentStep,
  children,
}) => {
  const { t } = useTranslation();

  const buildMetatitle = () => {
    if (title) {
      return `${title} | ${t("CourseWrapper.Urðarbrunnur Ökuskóli")}`;
    } else {
      return t("CourseWrapper.Urðarbrunnur Ökuskóli");
    }
  };

  const metaTitle = buildMetatitle();

  let isPaymentFlow;
  paymentStep === undefined ? (isPaymentFlow = false) : (isPaymentFlow = true);

  return (
    <div className="user-area">
      <Navigation
        courseId={courseId}
        paymentStep={paymentStep}
        isPaymentFlow={isPaymentFlow}
      />
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <div className="main-content">
        <div className="lotu-container">{children}</div>
      </div>
      <FeedbackBox />
    </div>
  );
};

export default CourseWrapper;
