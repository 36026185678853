import React from "react";
import DesktopMenu from "../Navigation";
import MobileMenu from "../MobileMenu";
import Footer from "../Footer";
import Helmet from "../Helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pages } from "../../../routes/routes";
import HeroSection from "./HeroSection";
import SocialProof from "./SocialProof";
import FAQ from "./FAQ";

export const translations = {
  is: {
    Ö1Page: {
      MetaTitle: "Ökuskóli 1",
      "Ökuskóli 1 á netinu": "Ökuskóli 1 á netinu",
      IntroTexti:
        "Bóklega námið byrjar með Ökuskóla 1. Efnið er sett fram á myndrænan og gangvirkan hátt.",
      "Fjarnám Urðarbrunns": "Fjarnám Urðarbrunns",
      FjarnámTexti:
        "Ökuskóli 1 er hluti af ökunámsferlinu og er stórt skref í átt að bílprófi. Ökuskóli 1 í fjarnámi kemur í staðinn fyrir hefðbundið nám í skólastofu og fer í sama efni.",
      "Hvað er kennt í Ökuskóla 1?": "Hvað er kennt í Ökuskóla 1?",
      "Ökuskóla 1 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni":
        "Ökuskóla 1 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni:",
      Ökunámsferlið: "Ökunámsferlið",
      "Bíll og búnaður": "Bíll og búnaður",
      Umferðarheild: "Umferðarheild",
      Umferðarreglur: "Umferðarreglur",
      "Viðhorf gagnvart umferðinni": "Viðhorf gagnvart umferðinni",
      Umferðarskilti: "Umferðarskilti",
      KaflaprófTexti:
        "Í lok hverrar lotu er 10 spurninga kaflapróf og náminu lýkur með lokaprófi úr öllu efni námskeiðsins.",
      SexDagaNámskeið:
        "Við lok hverrar lotu þarf að bíða í næstum einn sólahring (21 tíma) þar til næsta lota opnast. Þetta gerir það að verkum að námskeiðið tekur minnst sex daga. Þetta er gert til að fylgja reglum Samgöngustofu.",
      "Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina":
        "Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina",
      "Út Í Umferðina": "Út Í Umferðina",
      "handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á":
        "handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á",
      "vefverslun Ökukennarafélagsins": "vefverslun Ökukennarafélagsins",
      Inntökuskilyrði: "Inntökuskilyrði",
      "Til þess að taka þátt í Ökuskóla 1 þarf nemandi að hafa":
        "Til þess að taka þátt í Ökuskóla 1 þarf nemandi að hafa:",
      "Námsheimild frá sýslumanni": "Námsheimild frá sýslumanni",
      "Klárað 1 ökutíma hjá ökukennara": "Klárað 1 ökutíma hjá ökukennara",
      Greiðsla: "Greiðsla",
      GreiðslaText:
        "Greiðsla fer fram eftir að aðgangur hefur verið stofnaður. Hægt er að greiða með greiðslukorti eða millifærslu inná reikning ökuskólans. Frekari upplýsingar um greiðslufyrirkomulag má finna eftir skráningu.",
      "Skráðu þig í Ökuskóla 1": "Skráðu þig í Ökuskóla 1",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Einhverjar spurningar? Hringdu í síma 777-9344.",
      StudentCount: "Meira en 1,500 nemendur hafa tekið Ö1 hjá okkur",
      Testimonial1Text:
        "Efnið er vandað en einnig skemmtilegt sem ég er viss um að hafi hjálpað mér að ná bílprófinu í fyrstu tilraun.",
      Testimonial2Text:
        "Námskeiðið hefur fullt af myndum og góðum útskýringum sem gerir það auðskiljanlegt, sérstaklega þar sem ég er með myndrænt minni",
    },
  },
  en: {
    Ö1Page: {
      MetaTitle: "Driving School 1",
      "Ökuskóli 1 á netinu": "Driving School 1 online",
      IntroTexti:
        "Begin your driving studies with Driving School 1 (Ökuskóli 1). Learn through visual content and interactive challenges.",
      "Í stuttu máli": "Summary",
      "Fjarnám Urðarbrunns": "Learn online with us in English",
      FjarnámTexti:
        "Driving school 1 and 2 (Ökuskóli 1 and 2) are an integral part of the process to get a drivers license.",
      "Hvað er kennt í Ökuskóla 1?": "What is taught in Driving School 1?",
      "Ökuskóla 1 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni":
        "Driving School 1 is split into 6 rounds. Each round covers a different subject:",
      Ökunámsferlið: "The process of learning to drive",
      "Bíll og búnaður": "Cars and Equipment",
      Umferðarheild: "Traffic as a whole",
      Umferðarreglur: "Traffic laws",
      "Viðhorf gagnvart umferðinni": "Behaviour in traffic",
      Umferðarskilti: "Traffic signs",
      KaflaprófTexti:
        "At the end of each round is a 10 question quiz and at the end of the 6 rounds there is a final test that covers the entire course material.",
      SexDagaNámskeið:
        "After each round you need to wait for about a day (21 hours) until the next one opens. This means the course takes at least six days. This is done to follow the rules set by the Ministry of Transport",
      "Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina":
        "So you do as well as you can in the driving school you should ideally have the book",
      "Út Í Umferðina": "Út Í Umferðina",
      "handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á":
        "at hand. You can buy the book in Icelandic on the",
      "vefverslun Ökukennarafélagsins": "Driving Instructors Association store",
      Inntökuskilyrði: "Admission requirements",
      "Til þess að taka þátt í Ökuskóla 1 þarf nemandi að hafa":
        "To start Driving School 1 you should have:",
      "Námsheimild frá sýslumanni": "A study permit",
      "Klárað 1 ökutíma hjá ökukennara":
        "Finished 1 driving lesson with a driving instructor",
      Greiðsla: "Payment",
      GreiðslaText:
        "You pay after you've signed up and chosen your course. You can pay either with a credit card or bank transfer.",
      "Skráðu þig í Ökuskóla 1": "Sign up to Driving School 1",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Any questions? Call us at 777-9344.",
      StudentCount: "Over 1,500 students have taken Driving School 1 with us",
      Testimonial1Text:
        "The material is well-made and fun, which I'm sure helped me pass the driver's test on my first attempt.",
      Testimonial2Text:
        "The course has lots of images and clear explanations which makes it easy to understand, especially since I'm a visual learner",
    },
  },
};

const ScreenShots = () => {
  return (
    <>
      <img className="screenshots first-screenshot" src="/images/4.PNG" />
      <img className="screenshots" src="/images/2.PNG" />
    </>
  );
};

const Okuskoli1SalesPage = ({ school = 1 }) => {
  const { t } = useTranslation();

  return (
    <div className="site-wrap">
      <Helmet pageTitle={t(`Ö${school}Page.MetaTitle`)} />
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body default-page sales-page">
          <HeroSection
            headline={t("Ö1Page.Ökuskóli 1 á netinu")}
            introText={t("Ö1Page.IntroTexti")}
            buttonCTA={t("Ö1Page.Skráðu þig í Ökuskóla 1")}
            imageURL="/images/kona-ad-keyra.jpg"
          />
          <SocialProof drivingSchool={1} />
          <div className="default-text-container">
            <div className="img-and-info">
              <h2>{t("Ö1Page.Fjarnám Urðarbrunns")}</h2>
              <p>{t("Ö1Page.FjarnámTexti")}</p>
            </div>

            <div>
              <h2>{t("Ö1Page.Hvað er kennt í Ökuskóla 1?")}</h2>
              <p>
                {t(
                  "Ö1Page.Ökuskóla 1 er skipt upp í 6 lotur. Hver lota fjallar um mismunandi efni"
                )}{" "}
              </p>
              <ul>
                <li>{t("Ö1Page.Ökunámsferlið")}</li>
                <li>{t("Ö1Page.Bíll og búnaður")}</li>
                <li>{t("Ö1Page.Umferðarheild")}</li>
                <li>{t("Ö1Page.Umferðarreglur")}</li>
                <li>{t("Ö1Page.Viðhorf gagnvart umferðinni")}</li>
                <li>{t("Ö1Page.Umferðarskilti")}</li>
              </ul>
              <p>{t("Ö1Page.KaflaprófTexti")}</p>
              <p>{t("Ö1Page.SexDagaNámskeið")}</p>
              <p>
                {t(
                  "Ö1Page.Svo að þú standir þig sem best í ökuskólanum ættir þú að hafa bókina"
                )}
                <i> {t("Ö1Page.Út Í Umferðina")}</i>{" "}
                {t(
                  "Ö1Page.handbæra. Hún fylgir ekki með skráningu hjá okkur en það er hægt að kaupa hana á"
                )}{" "}
                <a href="https://www.aka.is/namsefni/">
                  {t("Ö1Page.vefverslun Ökukennarafélagsins")}
                </a>
                .
              </p>
              <ScreenShots />
            </div>
            <h2>{t("Ö1Page.Inntökuskilyrði")}</h2>
            <p>
              {t(
                "Ö1Page.Til þess að taka þátt í Ökuskóla 1 þarf nemandi að hafa"
              )}
            </p>
            <ul>
              <li>{t("Ö1Page.Námsheimild frá sýslumanni")}</li>
              <li>{t("Ö1Page.Klárað 1 ökutíma hjá ökukennara")}</li>
            </ul>

            <h2>{t("Ö1Page.Greiðsla")}</h2>
            <p>{t("Ö1Page.GreiðslaText")}</p>
          </div>
          <div className="faq-section">
            <FAQ />
          </div>
          <div>
            <div className="button-container">
              <Link className="main-button" to={t(Pages.Registration)}>
                {t("Ö1Page.Skráðu þig í Ökuskóla 1")}
              </Link>
            </div>
            <p className="spurningar-p">
              {t("Ö1Page.Einhverjar spurningar? Hringdu í síma 777-9344.")}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Okuskoli1SalesPage;
