import React, { useState } from "react";
import Button from "../../reusables/button";
import axios from "axios";
import { useTranslation } from "react-i18next";
import RegistrationError from "../../components/site/RegistrationError";

export const translations = {
  is: {
    MagnetForm: {
      "Fáðu segulinn heim": "Fáðu segulinn sendan heim",
      Heimilisfang: "Heimilisfangið þitt",
      Póstnúmer: "Póstnúmer",
      example: "t.d.",
      Senda: "Senda",
      ErrorMessage:
        "Það kom upp villa! Vinsamlegast reyndu aftur eða sendu okkur tölvupóst á hallo@urdarbrunnur.is.",
      Success: "Takk fyrir. Við sendum á þig segulinn sem allra fyrst.",
    },
  },
  en: {
    MagnetForm: {
      "Fáðu segulinn heim": "Get the Learner Driving magnet",
      Heimilisfang: "Your address",
      Póstnúmer: "Postcode",
      example: "e.g.",
      Senda: "Send",
      ErrorMessage:
        "There was an error! Please try again or contact us at hallo@urdarbrunnur.is.",
      Success: "Thank you! We will send you the magnet as soon as possible.",
    },
  },
};

const MagnetForm = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState({
    value: "",
    valid: false,
  });
  const [postcode, setPostcode] = useState({
    value: "",
    valid: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let isFieldValid = false;

    if (event.target.value.length > 0) {
      isFieldValid = true;
    }

    setAddress({
      value,
      valid: isFieldValid,
    });
  };

  const handlePostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let isFieldValid = false;

    if (event.target.value.length > 2) {
      isFieldValid = true;
    }

    setPostcode({
      value,
      valid: isFieldValid,
    });
  };

  const submitForm = () => {
    setSubmitting(true);
    setErrorMessage("");
    setSuccess(false);

    axios
      .post(`/api/send-magnet`, {
        address: address.value,
        postcode: postcode.value,
      })
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrorMessage(t("MagnetForm.ErrorMessage"));
        console.error(error.response);
      });
  };

  return (
    <div className="magnet-form">
      <h2>{t("MagnetForm.Fáðu segulinn heim")}</h2>
      {!success ? (
        <>
          <div className="field-container">
            <label>
              <p className="field-name">{t("MagnetForm.Heimilisfang")}</p>
              <input
                type="text"
                required
                id="address"
                name="address"
                placeholder={`${t("MagnetForm.example")} Vonarstræti 1`}
                value={address.value}
                onChange={handleAddressChange}
              />
            </label>
          </div>
          <div className="field-container">
            <label>
              <p className="field-name">{t("MagnetForm.Póstnúmer")}</p>
              <input
                type="text"
                required
                id="postcode"
                name="postcode"
                placeholder={`${t("MagnetForm.example")} 101 Reykjavík`}
                value={postcode.value}
                onChange={handlePostcodeChange}
              />
            </label>
          </div>
          <Button
            disabled={!address.valid || !postcode.valid}
            type="onClick"
            onClick={submitForm}
          >
            <i style={{ marginRight: "5px" }} className="fa fa-paper-plane"></i>
            {"  "}
            {submitting ? "Sendi..." : t("MagnetForm.Senda")}
          </Button>
        </>
      ) : (
        <p>{t("MagnetForm.Success")}</p>
      )}
      <RegistrationError errorMessage={errorMessage} />
    </div>
  );
};

export default MagnetForm;
