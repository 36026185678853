import React, { FC } from "react";
import {
  UserTestQuestions,
  TestAnswers,
  UserTest,
} from "../../../../store/test";
import CorrectAnswers from "./CorrectAnswers";
import { useGetLang } from "../../../../../hooks/useGetLang";

type AnswerCheckboxProps = {
  question: UserTestQuestions;
  testAnswers: TestAnswers[];
  userTest: UserTest;
  disabled: boolean;
  answerSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

// This receives 1 question and the 2 - 4 question answers associated with it.
const AnswerCheckboxes: FC<AnswerCheckboxProps> = ({
  question,
  testAnswers,
  userTest,
  disabled,
  answerSelected,
}) => {
  const lang = useGetLang();

  let wasQuestionWrong = false;
  return (
    <ul className="answerRows">
      {question.answers.map((answer, answerIndex) => {
        let checked = false;
        let answerClass = null;
        // The thing below does two things:
        // 1. Updates UI when answers are checked in the UI
        // 2. Checks if answers are correct when test is submitted.
        testAnswers?.forEach((userAnswer) => {
          if (parseInt(userAnswer.answerId) === answer.id) {
            checked = true; // If a answer has been checked, reflect that in UI.
            if (
              typeof answer.correct !== "undefined" &&
              userTest.status === "Finished"
            ) {
              answerClass = answer.correct ? "correct" : "wrong";
              if (answer.correct === false) {
                wasQuestionWrong = true;
              }
            }
          }
        });

        // Evaluate non-checked answers, are they correct or not?
        if (userTest.status === "Finished" && answerClass === null) {
          answerClass = answer.correct ? "wrong" : "correct";
          if (answer.correct === true) {
            wasQuestionWrong = true;
          }
        }

        const answerId = `a${answer.id}`;
        return (
          <li
            className={`checkbox-button-container ${answerClass}`}
            key={answerIndex}
          >
            <label htmlFor={answerId}>
              <input
                className="checkbox-button"
                type="checkbox"
                name={question.id.toString()}
                checked={checked}
                disabled={disabled}
                value={answer.id}
                id={answerId}
                onChange={answerSelected}
              />
              <span>{lang === "is" ? answer.answer : answer.answer_en}</span>
            </label>
          </li>
        );
      })}
      {wasQuestionWrong && <CorrectAnswers question={question} />}
    </ul>
  );
};

export default AnswerCheckboxes;
