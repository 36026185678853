import { useState, useEffect } from "react";

type ConfigType = {
	immediate?: boolean;
	initialValue?: any;
	initialParams?: any[];
	responseAdapter?: any;
};

enum Status {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export const useApi = (method: any, config: ConfigType = {}) => {
	const { immediate = false, initialValue, initialParams, responseAdapter } = config;
	const [data, setData] = useState<any>(initialValue);
	const [message, setMessage] = useState<null | boolean | string>(null);
	const [status, setStatus] = useState<keyof typeof Status>("IDLE");

	const exec = async (...args: any[]) => {
		try {
			setStatus("PENDING");
			setMessage(false);
			let payload = [];

			if (args.length) {
				payload = args;
			} else if (immediate && initialParams?.length) {
				payload = initialParams;
			}

			const response = await method(...payload);
			const result =
				typeof responseAdapter === "function" ? responseAdapter(response) : response.data;
			setStatus("SUCCESS");
			setData(result);
			return {
				data,
				error: false,
				message: null,
			};
		} catch (error) {
			console.error(error);
			setStatus("ERROR");
			setMessage(error.message);
			return {
				data: null,
				error: true,
				message: error.message,
			};
		}
	};

	useEffect(() => {
		if (immediate) exec();
	}, []);

	return { data, status, message, exec };
};
