import React, { useState, useEffect } from "react";
import CourseWrapper from "../CourseWrapper";
import TextSetting from "./TextSetting";
import { useApi } from "../../hooks";
import { getUserSettings } from "../../api/courseApi";
import LangToggle from "../site/LangToggle";
import { useTranslation } from "react-i18next";
import DyslexiaSetting from "./DyslexiaSetting";
import Card from "../../reusables/card";

export const translations = {
  is: {
    Settings: {
      Stillingar: "Stillingar",
      Notendaupplýsingar: "Notendaupplýsingar",
      "Úbs! Eithvað fór úrskeiðis": "Úbs! Eithvað fór úrskeiðis:",
      Nafn: "Nafn",
      Tölvupóstur: "Tölvupóstur",
      Notendaupplifun: "Notenda stillingar",
      Language: "Tungumál",
    },
  },
  en: {
    Settings: {
      Stillingar: "Settings",
      Notendaupplýsingar: "User profile",
      "Úbs! Eithvað fór úrskeiðis": "Oops! Something went wrong:",
      Nafn: "Name",
      Tölvupóstur: "Email",
      Notendaupplifun: "User settings",
      Language: "Language",
    },
  },
};

const Settings = () => {
  const {
    data: response,
    status,
    message,
    exec: initGetUserSettings,
  } = useApi(getUserSettings);

  const { t } = useTranslation();

  useEffect(() => {
    initGetUserSettings();
  }, []);

  const [user, setUser] = useState({
    name: "Loading...",
    email: "Loading...",
  });

  // When useFetch has fetched, run this code.
  useEffect(() => {
    if (response?.user) {
      const { user } = response;
      setUser(user);
    }
  }, [response]);

  // If there's an error
  // Clear "loading" from default state.
  useEffect(() => {
    if (status === "ERROR") {
      setUser({
        name: "",
        email: "",
      });
    }
  }, [status]);

  const updateUserData = (field: string, value: string) => {
    setUser((prevUser) => {
      const fieldToUpdate = { [field]: value };
      return { ...prevUser, ...fieldToUpdate };
    });
  };

  const { name, email } = user;
  return (
    <CourseWrapper title={t("Settings.Stillingar")}>
      <div className="main-content">
        <div className="lotu-container">
          <div className="settings-page">
            <h1>{t("Settings.Stillingar")}</h1>
            <h3>{t("Settings.Notendaupplýsingar")}</h3>
            {status === "ERROR" && (
              <p className="error">
                {t("Settings.Úbs! Eithvað fór úrskeiðis")} {message}
              </p>
            )}
            <TextSetting
              label={t("Settings.Nafn")}
              userData={name}
              name="name"
              updateUserData={updateUserData}
            />
            <TextSetting
              label={t("Settings.Tölvupóstur")}
              userData={email}
              name="email"
              updateUserData={updateUserData}
            />
            <div>
              <h3>{t("Settings.Notendaupplifun")}</h3>
              <Card className="mb-5">
                <p className="mt-0 mb-0 font-bold">{t("Settings.Language")}</p>
                <LangToggle onlyShowFlag={false} />
              </Card>
              <Card>
                <DyslexiaSetting />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </CourseWrapper>
  );
};

export default Settings;
