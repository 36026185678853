import React, { FC } from "react";

interface Props {
  errorMessage: string | null | undefined;
}

// Note: This component is also used inside the app, to signal an error on the magnet request form.
const RegistrationError: FC<Props> = ({ errorMessage }) => {
  return (
    <div className="skraning-form-error">
      {errorMessage ? (
        <p>
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>{" "}
          {errorMessage}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default RegistrationError;
