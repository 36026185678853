import React, { FC } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import AuthBeforeRender from "./AuthBeforeRender";
import { AuthReducerTypes, IndexReducerTypes } from "../components/store/types";

type AuthRouteProps = {
	component: React.ComponentType<RouteComponentProps> | React.ComponentType<any>;
	exact?: boolean;
	path: string;
	authReducer: AuthReducerTypes;
};

export const AuthRoute: FC<AuthRouteProps> = (props) => {
	const { component, exact, path } = props;
	const Component = component;
	const { authenticated } = props.authReducer;

	return (
		<Route
			exact={exact}
			path={path}
			render={(props) =>
				authenticated ? (
					<Component {...props} />
				) : (
					// If they're not authenticated according to current state
					// Check with the server before denying access - only then we can either redirect them or render the component.
					// See this Stack Overflow for more info: https://tinyurl.com/yxug6lgn
					<AuthBeforeRender render={() => <Component {...props} />} />
				)
			}
		/>
	);
};

const mapStateToProps = (state: IndexReducerTypes) => {
	const { authReducer } = state;
	return {
		authReducer,
	};
};

export default connect(mapStateToProps)(AuthRoute);
