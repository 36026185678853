import React, { useState, ChangeEvent, FormEvent } from "react";
import DesktopMenu from "./Navigation";
import MobileMenu from "./MobileMenu";
import axios from "axios";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pages } from "../../routes/routes";
import Helmet from "./Helmet";

interface ResetPasswordProps extends RouteComponentProps<{ token: string }> {
  token: string;
}

export const translations = {
  is: {
    ResetPassword: {
      "Breyta lykilorði": "Breyta lykilorði",
      "Nýtt lykilorð": "Nýtt lykilorð",
      "Staðfesta lykilorð": "Staðfesta lykilorð",
      "Uppfæra lykilorð": "Uppfæra lykilorð",
      "Lykilorðin eru ekki eins": "Lykilorðin eru ekki eins",
      "Lykilorð þarf að vera 8 stafir að lágmarki":
        "Lykilorð þarf að vera 8 stafir að lágmarki",
    },
  },
  en: {
    ResetPassword: {
      "Breyta lykilorði": "Change Password",
      "Nýtt lykilorð": "New password",
      "Staðfesta lykilorð": "Confirm password",
      "Uppfæra lykilorð": "Update password",
      "Lykilorðin eru ekki eins": "The passwords do not match",
      "Lykilorð þarf að vera 8 stafir að lágmarki":
        "The password needs to be at least 8 letters long",
    },
  },
};

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const { history, match } = props;
  const token = match.params.token;
  const { t } = useTranslation();

  const [newPasswordError, setNewPasswordError] = useState<
    string | JSX.Element
  >("");
  const [retypePasswordError, setRetypePasswordError] = useState<
    string | JSX.Element
  >("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>(token);

  const changePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setNewPasswordError("");
    setRetypePasswordError("");

    if (name === "newPasswordConfirm" || name === "newPassword") {
      setNewPassword(value);
    }
  };

  const changePasswordConfirm = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setNewPasswordError("");
    setRetypePasswordError("");

    if (name === "newPasswordConfirm" || name === "newPassword") {
      setNewPasswordConfirm(value);
    }
  };

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let error = false;
    let newPasswordError: string | JSX.Element = "";
    let retypePasswordError: string | JSX.Element = "";

    if (newPassword.length < 8) {
      error = true;
      newPasswordError = (
        <div>
          <i className="fa fa-exclamation-circle"></i>
          {t("ResetPassword.Lykilorð þarf að vera 8 stafir að lágmarki")}
        </div>
      );
    }
    if (newPassword !== newPasswordConfirm && newPasswordConfirm.length > 0) {
      error = true;
      retypePasswordError = (
        <div>
          <i className="fa fa-exclamation-circle"></i>
          {t("ResetPassword.Lykilorðin eru ekki eins")}
        </div>
      );
    }

    if (error) {
      setNewPasswordError(newPasswordError);
      setRetypePasswordError(retypePasswordError);
    } else {
      setNewPasswordError("");
      setRetypePasswordError("");

      axios
        .post("/nytt-lykilord/" + token, { password: newPassword })
        .then(() => {
          history.push(t(Pages.Login));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <div className="site-wrap">
      <div className="push-wrap">
        <Helmet pageTitle={t("ResetPassword.Breyta lykilorði")} />
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body default-page reset-page">
          <form method="post" onSubmit={submitForm}>
            <div>
              <h1>{t("ResetPassword.Breyta lykilorði")}</h1>
              <div className="form-container">
                <label>
                  <p className="field-name">
                    {t("ResetPassword.Nýtt lykilorð")}
                  </p>
                  <input
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    required
                    onChange={changePassword}
                  />
                  <div className="reset-form-error-short">
                    {newPasswordError}
                  </div>
                </label>
              </div>
              <div className="form-container">
                <label>
                  <p className="field-name">
                    {t("ResetPassword.Staðfesta lykilorð")}
                  </p>
                  <input
                    type="password"
                    name="newPasswordConfirm"
                    value={newPasswordConfirm}
                    required
                    onChange={changePasswordConfirm}
                  />
                  <div className="reset-form-error">{retypePasswordError}</div>
                </label>
              </div>
              <div>
                <button id="submit" type="submit" className="btn">
                  {t("ResetPassword.Uppfæra lykilorð")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
