import React, { useEffect } from "react";
import CourseWrapper from "../../CourseWrapper";
import { useApi } from "../../../hooks";
import { getUserDetails } from "../../../api/courseApi";
import { useParams } from "react-router-dom";
import Button from "../../../reusables/button";
import PrettyData from "../../../reusables/prettyData";
import OpenForm from "./openForm";
import CloseButtons from "./closeButtons";
import ConfirmForm from "./confirmForm";

const Student = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: userDetails,
    status,
    message,
    exec: initGetUserDetails,
  } = useApi(getUserDetails, {
    immediate: true,
    initialParams: [userId],
  });

  useEffect(() => {
    initGetUserDetails();
  }, []);

  const user = userDetails?.user;
  const userTransactions = userDetails?.userTransactions;
  const userCourses = userDetails?.userCourses;
  const userCourseRounds = userDetails?.userCourseRounds;

  return (
    <CourseWrapper title={user?.name}>
      <div className="main-content">
        <div className="lotu-container">
          <div className="settings-page">
            <h1>Nemandi</h1>
            <div>
              {status === "PENDING" && "loading"}
              {status === "ERROR" && message}
              <OpenForm />
              <CloseButtons />
              <ConfirmForm />

              <h2>User</h2>
              {user && <PrettyData json={user} />}
              <h2>User transactions</h2>
              {userTransactions && <PrettyData json={userTransactions} />}
              <h2>User courses</h2>
              {userCourses && <PrettyData json={userCourses} />}
              <h2>User course rounds</h2>
              {userCourseRounds && <PrettyData json={userCourseRounds} />}
            </div>
            <Button size="tiny" type="react-router-link" href={`/command/`}>
              ⬅ Til baka
            </Button>
          </div>
        </div>
      </div>
    </CourseWrapper>
  );
};

export default Student;
