import React from "react";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    SocialProof: {
      StudentCount1: "Meira en 1,250 nemendur hafa tekið Ö1 hjá okkur",
      StudentCount2: "Meira en 1,250 nemendur hafa tekið Ö2 hjá okkur",
      Testimonial1Text:
        "Efnið er vandað en einnig skemmtilegt sem ég er viss um að hafi hjálpað mér að ná bílprófinu í fyrstu tilraun.",
      Testimonial2Text:
        "Námskeiðið hefur fullt af myndum og góðum útskýringum sem gerir það auðskiljanlegt, sérstaklega þar sem ég er með myndrænt minni",
    },
  },
  en: {
    SocialProof: {
      StudentCount1: "Over 1,250 students have taken Driving School 1 with us",
      StudentCount2: "Over 1,250 students have taken Driving School 2 with us",
      Testimonial1Text:
        "The material is well-made and fun, which I'm sure helped me pass the driver's test on my first attempt.",
      Testimonial2Text:
        "The course has lots of images and clear explanations which makes it easy to understand, especially since I'm a visual learner",
    },
  },
};

interface SocialProofProps {
  drivingSchool?: 1 | 2;
}

const SocialProof: React.FC<SocialProofProps> = ({ drivingSchool = 1 }) => {
  const { t } = useTranslation();

  return (
    <div className="social-proof">
      <img
        src="/images/car.jpg"
        alt="Car"
        style={{
          width: "300px",
          marginTop: "20px",
          borderRadius: "4px",
        }}
      />
      <h2>{t(`SocialProof.StudentCount${drivingSchool}`)}</h2>
      <div className="testimonials">
        <div className="testimonial">
          <div className="testimonial-content">
            <p>&ldquo;{t("SocialProof.Testimonial1Text")}&rdquo;</p>
            <div className="testimonial-author">- Ágústa I.</div>
          </div>
        </div>
        <div className="testimonial">
          <div className="testimonial-content">
            <p>&ldquo;{t("SocialProof.Testimonial2Text")}&rdquo;</p>
            <div className="testimonial-author">- Þorsteinn K.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialProof;
