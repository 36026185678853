import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    DaysLeft: {
      "Þú náðir ekki að klára Ökuskóla": "Þú náðir ekki að klára Ökuskóla",
      "innan 30 daga frá því að þú hófst námið":
        "innan 30 daga frá því að þú hófst námið.",
      "Þú getur byrjað námið aftur endurgjaldslaust með því að smella á 'Byrja upp á nýtt' hér að neðan.":
        "Þú getur byrjað námið aftur endurgjaldslaust með því að smella á 'Byrja upp á nýtt' hér að neðan.",
      "Þú hefur": "Þú hefur",
      "til að klára Ökuskóla": "til að klára Ökuskóla",
      dag: "dag",
      daga: "daga",
    },
  },
  en: {
    DaysLeft: {
      "Þú náðir ekki að klára Ökuskóla": "Þú náðir ekki að klára Ökuskóla",
      "innan 30 daga frá því að þú hófst námið":
        "innan 30 daga frá því að þú hófst námið.",
      "Þú getur byrjað námið aftur endurgjaldslaust með því að smella á 'Byrja upp á nýtt' hér að neðan.":
        "Þú getur byrjað námið aftur endurgjaldslaust með því að smella á 'Byrja upp á nýtt' hér að neðan.",
      "Þú hefur": "You have",
      "til að klára Ökuskóla": "to finish Driving School",
      dag: "day",
      daga: "days",
    },
  },
};

interface DaysLeftComponent {
  daysLeft: number;
  courseStatus: "Finished" | "Expired" | "Not Started" | "Ongoing";
  courseId: string;
}

export const DaysLeft: FC<DaysLeftComponent> = ({
  daysLeft,
  courseStatus,
  courseId,
}) => {
  const { t } = useTranslation();

  let daysLeftText;
  if (
    courseStatus === "Expired" ||
    (daysLeft <= 0 && courseStatus !== "Finished")
  ) {
    daysLeftText = (
      <div className="course-expired">
        <p>
          {t("DaysLeft.Þú náðir ekki að klára Ökuskóla")} {courseId}{" "}
          {t("DaysLeft.innan 30 daga frá því að þú hófst námið")}
        </p>
        <p>
          {t(
            "DaysLeft.Þú getur byrjað námið aftur endurgjaldslaust með því að smella á 'Byrja upp á nýtt' hér að neðan."
          )}
        </p>
      </div>
    );
  } else if (courseStatus !== "Finished") {
    let formatDaysLeft;
    if (daysLeft === 1 || daysLeft === 21) {
      formatDaysLeft = `${daysLeft} ${t("DaysLeft.dag")}`;
    } else {
      formatDaysLeft = `${daysLeft} ${t("DaysLeft.daga")}`;
    }
    daysLeftText = (
      <div className="daysleft-alert-box">
        <div className="daysleft-alert-text-container">
          <p>
            <i className="fa fa-calendar"></i>
            {`  ${t("DaysLeft.Þú hefur")} ${formatDaysLeft} ${t(
              "DaysLeft.til að klára Ökuskóla"
            )} ${courseId}.`}
          </p>
        </div>
      </div>
    );
  } else {
    daysLeftText = null;
  }

  return <>{daysLeftText}</>;
};

export default DaysLeft;
