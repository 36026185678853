import axios from "axios";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { userIsLoggedIn } from "./components/store/redux";
import { IndexReducerTypes, AuthReducerTypes } from "./components/store/types";
import Routes from "./routes/routes";

type AppProps = {
  dispatch: Dispatch;
  authReducer: AuthReducerTypes;
};

class App extends Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {
    this.isLoggedin();
  }

  isLoggedin() {
    const { dispatch } = this.props;
    axios.get("/api/is-logged-in").then((res) => {
      dispatch(
        userIsLoggedIn({
          ...res.data,
        })
      );
    });
  }

  render() {
    return (
      <div id={this.props.authReducer.dyslexiaFont ? "show-dyslexia-font" : ""}>
        <Helmet>
          <title>Urðarbrunnur Ökuskóli</title>
          <meta name="description" content="Urðarbrunnur Ökuskóli" />
          <meta name="keywords" content="Urðarbrunnur Ökuskóli" />
          <meta property="og:title" content="Urðarbrunnur Ökuskóli" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://www.urdarbrunnur.is/" />
          <meta
            property="og:image"
            content="http://urdarbrunnur.herokuapp.com/images/icon-logo.gif"
          />
          <meta property="og:site_name" content="Urðarbrunnur Ökuskóli" />
        </Helmet>
        <Routes />
      </div>
    );
  }
}

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(App);
