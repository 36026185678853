import React from "react";
import { useTranslation } from "react-i18next";

import PageTemplate from "../PageTemplate";
import DrivingInstructor from "./DrivingInstructor";

export const translations = {
  is: {
    DrivingInstructors: {
      Ökukennarar: "Ökukennari",
      SalesText:
        "Vantar þig ökukennara? Vertu þá með alla kennsluna á einum stað. Ef þú ert í kennslu hjá ökukennurum Urðarbrunns færðu 2.500 kr. í afslátt af Ökuskóla 1 og 2!",
      HilmarDesc:
        "Hilmar hefur kennt meira en 1.000 nemendum að keyra frá árinu 1986. Hann er einnig skólastjóri Urðarbrunns. Hann kennir yfirleitt á kvöldin og um helgar.",
      Verð: "Verð",
      "kr / kennslustund": "kr / kennslustund",
      Sími: "Sími",
      Bíll: "Bíll",
    },
  },
  en: {
    DrivingInstructors: {
      Ökukennarar: "Driving Instructors",
      SalesText:
        "Do you need a driving instructor? Do the whole package with us. If you are taught by one of our driving instructors you get 2.500 kr. off Driving School 1 og 2!",
      HilmarDesc:
        "Since 1986 Hilmar has taught more than 1.000 nemendum to drive. He is also the principal of Urðarbrunnur Driving school. He usually teaches in the evenings and during weekends.",
      Verð: "Verð",
      "kr / kennslustund": "kr / kennslustund",
      Sími: "Sími",
      Bíll: "Bíll",
    },
  },
};

const DrivingInstructors = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate
      title={t("DrivingInstructors.Ökukennarar")}
      url="http://urdarbrunnur.is/okukennarar"
      description="Ökukennarar hjá ökuskóla Urðarbrunns"
      keywords={["Ökukennari, ökukennsla, ökuskóli"]}
    >
      <>
        <h1>{t("DrivingInstructors.Ökukennarar")}</h1>
        <p>{t("DrivingInstructors.SalesText")}</p>
        <p></p>
        <DrivingInstructor
          name="Hilmar Guðjónsson"
          desc={t("DrivingInstructors.HilmarDesc")}
          imgSrc="/images/starfsmenn/hilmar.jpg"
          price="12.900"
          car="Hyundai i30"
          phone="893-2700"
        />
      </>
    </PageTemplate>
  );
};

export default DrivingInstructors;
