import React, { useEffect, useState } from "react";
import CourseWrapper from "../../CourseWrapper";
import axios from "axios";
import { StepQuestions } from "../../../components/store/steps";

const Content = ({ match }) => {
  const { stepId } = match.params;
  const [skrefContent, setSkrefContent] = useState("");
  const [skrefQuestions, setSkrefQuestions] = useState<StepQuestions[]>([]);
  const [skrefContentEn, setSkrefContentEn] = useState("");

  useEffect(() => {
    axios.post("/api/admin/step-content", { stepId }).then((res) => {
      setSkrefQuestions(res.data.step_questions);
      setSkrefContent(res.data.data);
      setSkrefContentEn(res.data.data_en);
    });
  }, []);

  const submitStepContent = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post("/api/admin/update-step-content", {
        stepData: skrefContent,
        stepId,
      })
      .catch((error) => {
        console.error(error);
      });

    skrefQuestions.forEach((question) => {
      axios
        .post("/api/admin/update-step-question", {
          question: question.question,
          questionId: question.id,
          lang: "is",
        })
        .catch((error) => {
          console.error(error);
        });

      // For each question, update the answers too.
      question.step_answers.forEach((answer) => {
        axios
          .post("/api/admin/update-step-answer", {
            answer: answer.answer,
            answerId: answer.id,
            lang: "is",
          })
          .catch((error) => {
            console.error(error);
          });
      });
    });
  };

  const submitStepContentEn = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post("/api/admin/update-step-content-en", {
        stepDataEnglish: skrefContentEn,
        stepId,
      })
      .catch((error) => {
        console.error(error);
      });

    skrefQuestions.forEach((question) => {
      axios
        .post("/api/admin/update-step-question", {
          question: question.question_en,
          questionId: question.id,
          lang: "en",
        })
        .catch((error) => {
          console.error(error);
        });

      // For each question, update the answers too.
      question.step_answers.forEach((answer) => {
        axios
          .post("/api/admin/update-step-answer", {
            answer: answer.answer_en,
            answerId: answer.id,
            lang: "en",
          })
          .catch((error) => {
            console.error(error);
          });
      });
    });
  };

  // Only updates the question text
  const updateStepQuestion = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    isEnglish: boolean
  ) => {
    const targetField = isEnglish ? "question_en" : "question";

    setSkrefQuestions((prevProp) => {
      const newOne = { ...prevProp[index], [targetField]: e.target.value };
      const newProp = [...prevProp];

      newProp[index] = newOne;
      return newProp;
    });
  };

  // Only updates the answer text
  const updateAnswer = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    answerIndex: number,
    isEnglish: boolean
  ) => {
    const field = isEnglish ? "answer_en" : "answer";

    setSkrefQuestions((prevProp) => {
      const newState = [...prevProp];
      newState[index].step_answers[answerIndex][field] = e.target.value;

      return newState;
    });
  };

  return (
    <CourseWrapper title="Skrefa editor">
      <h1>Skref ID: {stepId}</h1>
      <div style={{ marginBottom: "20px" }}>
        <a href="/command/content">⇜ Aftur í yfirlit</a>
      </div>

      <form onSubmit={submitStepContent}>
        <label htmlFor="skrefContent">Efni</label>
        <textarea
          id="skrefContent"
          name="skrefContent"
          rows={40}
          cols={100}
          value={skrefContent}
          onChange={(e) => setSkrefContent(e.target.value)}
        ></textarea>
        <h2>Verkefni</h2>
        <div>
          {skrefQuestions
            ? skrefQuestions.map((skrefQuestion, index) => {
                return (
                  <div key={skrefQuestion.id}>
                    Spurning:
                    <input
                      type="text"
                      style={{ marginBottom: "20px" }}
                      size={100}
                      height={20}
                      value={skrefQuestion.question}
                      onChange={(e) => updateStepQuestion(e, index, false)}
                    ></input>
                    Svör (grænt sýnir rétt svar):
                    {skrefQuestion.step_answers.map((answer, answerIndex) => {
                      return (
                        <div key={answer.id}>
                          <input
                            type="text"
                            style={{
                              marginTop: "5px",
                              backgroundColor: answer.correct ? "#c1ffc1" : "",
                            }}
                            size={100}
                            height={20}
                            value={answer.answer}
                            onChange={(e) =>
                              updateAnswer(e, index, answerIndex, false)
                            }
                          ></input>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : null}
        </div>

        <button
          type="submit"
          style={{ marginTop: "8px", marginBottom: "15px" }}
        >
          Uppfæra efni
        </button>
      </form>

      <form onSubmit={submitStepContentEn} style={{ marginTop: "20px" }}>
        <label htmlFor="skrefContentEn">Efni á Ensku</label>
        <textarea
          id="skrefContentEn"
          name="skrefContentEn"
          rows={40}
          cols={100}
          value={skrefContentEn}
          onChange={(e) => setSkrefContentEn(e.target.value)}
        ></textarea>
        <div>
          {skrefQuestions
            ? skrefQuestions.map((skrefQuestion, index) => {
                return (
                  <div key={skrefQuestion.id}>
                    Spurning:
                    <input
                      type="text"
                      style={{ marginBottom: "20px" }}
                      size={100}
                      height={20}
                      value={skrefQuestion.question_en}
                      onChange={(e) => updateStepQuestion(e, index, true)}
                    ></input>
                    Svör (grænt sýnir rétt svar):
                    {skrefQuestion.step_answers.map((answer, answerIndex) => {
                      return (
                        <div key={answer.id}>
                          <input
                            type="text"
                            style={{
                              marginTop: "5px",
                              backgroundColor: answer.correct ? "#c1ffc1" : "",
                            }}
                            size={100}
                            height={20}
                            value={answer.answer_en}
                            onChange={(e) =>
                              updateAnswer(e, index, answerIndex, true)
                            }
                          ></input>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : null}
        </div>
        <button
          style={{ marginTop: "8px", marginBottom: "15px" }}
          type="submit"
        >
          Uppfæra efni
        </button>
      </form>
    </CourseWrapper>
  );
};

export default Content;
