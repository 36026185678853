import React, { FC, useEffect, useRef } from "react";
import { useGetLang } from "../../../hooks/useGetLang";

interface StepAudioPlayerProps {
  school: string;
  round: string;
  step: string;
}

const StepAudioPlayer: FC<StepAudioPlayerProps> = ({ school, round, step }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const lang = useGetLang();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }
  }, [school, round, step]);

  console.log({ school, round });

  if (lang === "en") {
    return null;
  }

  return (
    <div className="step-audio-player">
      <p>Hlustaðu á efnið</p>
      <audio controls ref={audioRef}>
        <source
          src={`https://ub-assets.s3.eu-north-1.amazonaws.com/okuskoli${school}-lota${round}-skref${step}.mp3`}
          type="audio/mpeg"
        />
        <p>
          Your browser does not support HTML audio, but you can
          <a href="audio-file.mp3">download the audio file here.</a>.
        </p>
      </audio>
    </div>
  );
};

export default StepAudioPlayer;
