import React, { FC } from "react";
import { Link } from "react-router-dom";

type PaymentStepProps = {
	stepNr: number;
	state: "Finished" | "Active" | "Unfinished";
	path: string;
};

const PaymentStep: FC<PaymentStepProps> = ({ stepNr, state, path }) => {
	const className = state.toLowerCase();
	let disabled = false;

	if (state === "Unfinished") {
		disabled = true;
	}

	return (
		<div className={className}>
			{disabled && stepNr !== 2 ? (
				<>
					<span className="payment-step-icon">{state === "Finished" ? "✓" : `${stepNr}`}</span>
				</>
			) : (
				<Link to={path}>
					<span className="payment-step-icon">{state === "Finished" ? "✓" : `${stepNr}`}</span>
				</Link>
			)}
		</div>
	);
};

export default PaymentStep;
