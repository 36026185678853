import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const translations = {
  is: {
    NextRoundButton: {
      "Byrja Lotu 1": "Byrja Lotu 1",
      "Kynntu þig fyrir neðan til að byrja":
        "Kynntu þig fyrir neðan til að byrja",
      "Fara í lokapróf": "Fara í lokapróf",
      "Halda áfram með Lotu": "Halda áfram með Lotu ",
      "Byrja Lotu": "Byrja Lotu ",
      "Fara í krossaprof lotu": "Fara í krossaprof lotu",
    },
  },
  en: {
    NextRoundButton: {
      "Byrja Lotu 1": "Begin Round 1",
      "Kynntu þig fyrir neðan til að byrja":
        "Introduce yourself below to start",
      "Fara í lokapróf": "Go to final test",
      "Halda áfram með Lotu": "Continue with Round ",
      "Byrja Lotu": "Begin Round ",
      "Fara í krossaprof lotu": "Go to test for round",
    },
  },
};

interface NextRoundButtonProps {
  daysLeft: number;
  courseStatus: "Finished" | "Expired" | "Not Started" | "Ongoing";
  currentRoundStatus: "Finished" | "Expired" | "Not Started" | "Ongoing";
  nextRoundOpen: boolean;
  courseId: string;
  currentRound: number;
  roundsFinished: boolean;
  currentStep: number;
  messageFeatureUsed: boolean;
}

export const NextRoundButton: FC<NextRoundButtonProps> = ({
  daysLeft,
  courseStatus,
  currentRoundStatus,
  nextRoundOpen,
  courseId,
  currentRound,
  roundsFinished,
  currentStep,
  messageFeatureUsed,
}) => {
  if (
    (nextRoundOpen || currentRound === 1) &&
    courseStatus !== "Finished" &&
    courseStatus !== "Expired" &&
    daysLeft > 0
  ) {
    const { t } = useTranslation();
    let roundText = "";

    if (parseInt(courseId) === 2 && !messageFeatureUsed) {
      return (
        <Link
          to={`/`}
          onClick={(e) => e.preventDefault()}
          className="btn first-btn disabled-btn"
          disabled
        >
          {t("NextRoundButton.Byrja Lotu 1")}
          <p>
            <i className="fa fa-exclamation-triangle"></i>{" "}
            {t("NextRoundButton.Kynntu þig fyrir neðan til að byrja")}
          </p>
        </Link>
      );
    }

    if (roundsFinished) {
      return (
        <Link to={`/okuskoli-${courseId}/lokaprof`} className="btn first-btn">
          {t("NextRoundButton.Fara í lokapróf")}
        </Link>
      );
    }
    let thisRoundsStep = currentStep;
    if (
      currentStep === 11 &&
      !nextRoundOpen &&
      currentRoundStatus !== "Finished"
    ) {
      return (
        <Link
          to={`/okuskoli-${courseId}/lota-${currentRound}/krossaprof`}
          className="btn first-btn"
        >
          {t("NextRoundButton.Fara í krossaprof lotu")} {currentRound}
        </Link>
      );
    }

    if (!roundsFinished) {
      if (currentRoundStatus === "Finished") {
        return null;
      }
      if (currentRoundStatus === "Ongoing") {
        roundText = t("NextRoundButton.Halda áfram með Lotu");
      } else if (currentRoundStatus === "Not Started") {
        roundText = t("NextRoundButton.Byrja Lotu");
        thisRoundsStep = 1;
      }

      if (roundText !== "") {
        return (
          <Link
            to={`/okuskoli-${courseId}/lota-${currentRound}/skref-${thisRoundsStep}`}
            className="btn first-btn"
          >
            {roundText + currentRound}
          </Link>
        );
      }
    }
  }
  return null;
};

export default NextRoundButton;
