import React, { FC } from "react";

import { Message } from "../../store/types";
import ChatBox from "./chatbox/ChatBox";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    MainText: {
      Markmið: "Markmið",
      MarkmiðText:
        "Markmið ökuskóla Urðarbrunns er að gera þig að góðum ökumanni sem ekur af öryggi og tillitsemi. Í Ökuskóla 1 leggur þú grunninn að áframhaldandi ökunámi þínu en almennt hafa ökumenn ekki náð fullri færni í umferðinni fyrr en eftir sjö ár. Traustur grunnur er mikilvægur og þess vegna skaltu vanda þig frá upphafi.",
      MarkmiðTextÖ2:
        "Markmið ökuskóla Urðarbrunns er að gera þig að góðum ökumanni sem ekur af öryggi og tillitsemi. Í Ökuskóla 2 leggur þú grunninn að áframhaldandi ökunámi þínu en almennt hafa ökumenn ekki náð fullri færni í umferðinni fyrr en eftir sjö ár. Traustur grunnur er mikilvægur og þess vegna skaltu vanda þig frá upphafi.",
      "Hafðu í huga að": "Hafðu í huga að",
      HafðuíhugaText:
        "Samkvæmt reglugerðum þarf að klára ökuskólann 30 dögum. Ef þú hefur ekki klárað ökuskólann eftir 30 daga getur þú endurtekið námskeiðið hjá okkur án þess að borga fyrir það aftur.",
      "Út í Umferðina": "Út í Umferðina",
      "Svo að þú standir þig sem best í skriflega ökuprófinu þarft þú að hafa bókina":
        "Svo að þú standir þig sem best í ökuskólanum þarft þú að hafa bókina ",
      "þér við hlið. Við vísum í hana töluvert og við gerum ráð fyrir að nemendur okkar eigi hana. Hægt er að nálgast bókina hjá":
        "þér við hlið. Við vísum í hana töluvert og við gerum ráð fyrir að nemendur okkar eigi hana. Hægt er að nálgast bókina hjá",
      "Ökukennarafélagi Íslands": "Ökukennarafélagi Íslands.",
      "Þú getur líka athugað hvort einhver vinur þinn eigi hana":
        "Þú getur líka athugað hvort einhver vinur þinn eigi hana.",
      "Þarftu aðstoð": "Þarftu aðstoð?",
      AðstoðTexti:
        "Ef þú vilt heyra í Hilmari (ökukennara okkar) þá er hægt að hringja á milli 20:00 og 22:00 á öllum virkum dögum í síma 893-2700 eða þú getur sent okkur tölvupóst á hallo@urdarbrunnur.is",
      Spjallið: "Spjallið",
      SpjalliðText:
        "Svo að þú getir átt samskipti við samnemendur getur þú notað spjallið hér fyrir neðan. Til að hefja ökuskóla 2 þarftu að taka þátt í spjallinu. Kynntu þig og segðu okkur hvaðan þú ert!",
    },
  },
  en: {
    MainText: {
      Markmið: "Goal",
      MarkmiðText:
        "The goal of Urðarbrunnur Driving School is to guide you along the path of becoming a safe and polite driver. In Driving School 1 you should lay the foundation to your continued driving education as generally drivers don't become as good as they'll get until they've been on the road for 7 years! A strong foundation is important and therefore we suggest you take your time and study carefully from the start.",
      MarkmiðTextÖ2:
        "The goal of Urðarbrunnur Driving School is to guide you along the path of becoming a safe and polite driver. In Driving School 2 you should lay the foundation to your continued driving education as generally drivers don't become as good as they'll get until they've been on the road for 7 years! A strong foundation is important and therefore we suggest you take your time and study carefully from the start.",
      "Hafðu í huga að": "The 30 day limit",
      HafðuíhugaText:
        "You'll need to finish the driving school in 30 days. This is a rule we need to follow from the Ministry of Transport. But not to worry, if you don't finish the driving school in 30 days you can always retake the course with us without paying again.",
      "Út í Umferðina": "Út í Umferðina",
      "Svo að þú standir þig sem best í skriflega ökuprófinu þarft þú að hafa bókina":
        "For best results in the driving school you should have the book",
      "þér við hlið. Við vísum í hana töluvert og við gerum ráð fyrir að nemendur okkar eigi hana. Hægt er að nálgast bókina hjá":
        "by your side. It's in Icelandic, but we reference the book quite a bit. You can get the book from",
      "Ökukennarafélagi Íslands": "The driving instructor's association.",
      "Þú getur líka athugað hvort einhver vinur þinn eigi hana": "",
      "Þarftu aðstoð": "Need any help?",
      AðstoðTexti:
        "If you want to hear from Hilmar (our driving instructor) then you can call him between 20:00 and 22:00 on all working days at 893-2700 or you can send us an email at hallo@urdarbrunnur.is",
      Spjallið: "The Chat",
      SpjalliðText:
        "You can use the below chat to communicate with your co-students. To start Driving School 2 you need to take part in the chat. Introduce yourself and tell us where you're from!",
    },
  },
};

interface MainTextProps {
  courseId: string;
  messages: Message[];
}

const MainText: FC<MainTextProps> = ({ courseId, messages }) => {
  const { t } = useTranslation();
  let text = <></>;
  if (parseInt(courseId) === 1) {
    text = (
      <div>
        <h2>{t("MainText.Markmið")}</h2>
        <p>{t("MainText.MarkmiðText")}</p>

        <h2>{t("MainText.Hafðu í huga að")}</h2>
        <p>{t("MainText.HafðuíhugaText")} </p>

        <h2>{t("MainText.Út í Umferðina")}</h2>
        <p>
          {t(
            "MainText.Svo að þú standir þig sem best í skriflega ökuprófinu þarft þú að hafa bókina"
          )}{" "}
          <em>{t("MainText.Út í Umferðina")}</em>{" "}
          {t(
            "MainText.þér við hlið. Við vísum í hana töluvert og við gerum ráð fyrir að nemendur okkar eigi hana. Hægt er að nálgast bókina hjá"
          )}{" "}
          <a href="https://www.aka.is/namsefni/">
            {t("MainText.Ökukennarafélagi Íslands")}{" "}
          </a>
          {t(
            "MainText.Þú getur líka athugað hvort einhver vinur þinn eigi hana"
          )}
        </p>

        <h2>{t("MainText.Þarftu aðstoð")}</h2>
        <p>{t("MainText.AðstoðTexti")}</p>
      </div>
    );
  }

  if (parseInt(courseId) === 2) {
    text = (
      <div>
        <h2>{t("MainText.Spjallið")}</h2>
        <p>{t("MainText.SpjalliðText")}</p>
        <ChatBox messages={messages} />
        <h2>{t("MainText.Markmið")}</h2>
        <p>{t("MainText.MarkmiðTextÖ2")}</p>
        <h2>{t("MainText.Hafðu í huga að")}</h2>
        <p>{t("MainText.HafðuíhugaText")} </p>

        <h2>{t("MainText.Út í Umferðina")}</h2>
        <p>
          {t(
            "MainText.Svo að þú standir þig sem best í skriflega ökuprófinu þarft þú að hafa bókina"
          )}{" "}
          <em>{t("MainText.Út í Umferðina")}</em>{" "}
          {t(
            "MainText.þér við hlið. Við vísum í hana töluvert og við gerum ráð fyrir að nemendur okkar eigi hana. Hægt er að nálgast bókina hjá"
          )}{" "}
          <a href="https://www.aka.is/namsefni/">
            {t("MainText.Ökukennarafélagi Íslands")}{" "}
          </a>
          {t(
            "MainText.Þú getur líka athugað hvort einhver vinur þinn eigi hana"
          )}
        </p>
        <h2>{t("MainText.Þarftu aðstoð")}</h2>
        <p>{t("MainText.AðstoðTexti")}</p>
      </div>
    );
  }

  return text;
};

export default MainText;
