import React, { FC } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Pages } from "../../routes/routes";

type LinkTypes = {
  roundNr: number;
  roundStatus: "Finished" | "Not Started" | "Ongoing" | "Expired";
  nextRoundOpen: boolean;
  isCurrentRound: boolean;
};

type PropTypes = {
  links: Array<LinkTypes>;
  finalTestStatus: string;
  finalTestEnabled: string;
  courseId: string | undefined;
  messageFeatureUsed: boolean;
  courseStatus: string;
};

const MobileNav: FC<PropTypes> = ({
  links,
  finalTestStatus,
  finalTestEnabled,
  courseId,
  messageFeatureUsed,
  courseStatus,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (linkTo?: string) => {
    if (linkTo) history.push(linkTo);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    axios
      .get(`/logout`)
      .then(() => {
        history.push("/");
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  return (
    <div className="mobile-menu">
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: "#444444", fontWeight: "bold" }}
      >
        {t("DashNavigation.Valmynd")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          handleClose();
        }}
      >
        {courseStatus === "Expired" || courseId === undefined
          ? ""
          : links.map((link) => {
              const { roundNr, roundStatus, nextRoundOpen, isCurrentRound } =
                link;
              let icon;
              let disabled;
              icon = "fa fa-square-o";

              if (roundStatus === "Not Started") {
                icon = "fa fa-lock";
                disabled = true;
              }

              if (nextRoundOpen && isCurrentRound) {
                icon = "fa fa-square-o";
                disabled = false;
              }

              // If no message has been sent then...
              if (parseInt(courseId) === 2 && !messageFeatureUsed) {
                icon = "fa fa-lock";
                disabled = true;
              }

              if (roundStatus === "Finished") {
                icon = "fa fa-check-square-o";
              }
              const linkTo = `/okuskoli-${courseId}/lota-${roundNr}/skref-1/`;

              return (
                <MenuItem
                  key={roundNr}
                  onClick={() => handleClose(linkTo)}
                  disabled={disabled}
                >
                  <Link to={linkTo}>
                    <p>
                      <i className={icon}></i>
                      {` ${t("DashNavigation.Lota")} ${roundNr}`}
                    </p>
                  </Link>
                </MenuItem>
              );
            })}
        {links.length === 0 ||
        courseStatus === "Expired" ||
        courseId === undefined ? (
          ""
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            disabled={finalTestEnabled === "disabled-link" ? true : false}
          >
            <p>
              <Link to={`/okuskoli-${courseId}/lokaprof`}>
                <i className={finalTestStatus}></i> {t("DashNavigation.Próf")}
              </Link>
            </p>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleClose(t(Pages.Settings))}>
          <p>
            <i className="fa fa-cog"></i> {t("DashNavigation.Stillingar")}
          </p>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <p>
            <i className="fa fa-sign-out"></i> {t("DashNavigation.Skrá út")}
          </p>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MobileNav;
