import React, { FC } from "react";
import Helmet from "../Helmet";
import DesktopMenu from "../Navigation";
import MobileMenu from "../MobileMenu";
import Footer from "../Footer";

type TemplateProps = {
  title: string;
  description: string;
  imageOptions: {
    imgAlt: string;
    imgSrc: string;
    imgCaption?: string;
  };
  keywords: Array<String>;
};

const Template: FC<TemplateProps> = ({
  title,
  description,
  keywords,
  imageOptions,
  children,
}) => {
  const keywordsString = keywords.join(", ");

  return (
    <div className="site-wrap">
      <Helmet
        pageTitle={title}
        keywords={keywordsString}
        image={`https://www.urdarbrunnur.is${imageOptions.imgSrc}`}
      />
      <div className="push-wrap">
        <MobileMenu />
        <DesktopMenu />
        <div className="main-body blog-text">
          <h1>{title}</h1>
          <span className="sub-title">{description}</span>
          <img alt={imageOptions.imgAlt} src={imageOptions.imgSrc} />
          <p className="image-caption">{imageOptions.imgCaption}</p>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Template;
