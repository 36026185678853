import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    FAQ: {
      Title: "Algengar spurningar",
      Q1: "Hentar Ökuskóli 1 lesblindum?",
      A1: "Já, það er hægt að nota lesblindu textan OpenDyslexic með því að fara í stillingar. Að auki er hægt að hlusta á mest allt efni Ökuskóla 1 en það er lesið upp af leikaranum Hallvarði Jes Gíslasyni.",
      Q2: "Mig vantar ökukennara á höfuðborgarsvæðinu. Bjóðið þið upp á ökukennslu þar?",
      A2: "Já við bjóðum svo sannarlega upp á <a href='/okukennarar'>ökukennslu á höfuðborgarsvæðinu</a>.",
      Q3: "Hvað kostar ökunámið?",
      A3: "Heppilega höfum við skrifað heila grein um þetta. Þú <a href='/blogg/hvad-kostar-bilprof'>getur lesið um það hérna</a>. Ef þú vilt spara smá pening (og hver vil það ekki?) þá getur þú líka lesið <a href='/blog/how-to-get-a-cheap-drivers-license-in-Iceland'>þessa grein sem fjallar um hvernig það er mögulega hægt er að nýta námsstyrki frá stéttarfélögum</a> til þess að lækka kostnaðinn á ökunáminu þínu.",
      Q4: "Hvernig skrái ég mig í skólann?",
      A4: "Fyrst ferðu á <a href='/registration'>innskráningarsíðuna</a> og síðan hefurðu kost á að greiða með greiðslukorti eða með millifærslu.",
      Q5: "Bjóðið þið upp á meirapróf eða mótorhjólapróf?",
      A5: "Nei, því miður ekki. Við einbeitum okkur að Ökuskóla 1 og 2. Það eru aðrir ágætir skólar sem geta kennt þér á mótorhjól og allskonar.",
      Q6: "Ég hef útskrifast úr Ökuskóla 1 en mig vantar staðfestingu í rafrænu ökunámsbókina. Hvernig fæ ég hana?",
      A6: "Já, það er ekkert mál. Hringdu í okkur eða sendu okkur tölvupóst á hallo@urdarbrunnur.is og við skulum koma því í lag.",
      Q7: "Er hægt að taka námskeiðin á ensku?",
      A7: "Já! Við héldum að þú myndir aldrei spyrja. Við bjóðum svo sannarlega upp á námskeiðið á ensku. Við settum mikið púður í að þýða allt efnið vel og vandlega, meira að segja greinarnar okkar og alla heimasíðuna. Það er ekkert Google Translate hér á bæ.",
      Q8: "Mig vantar æfingaaksturs segulinn. Hvar fæ ég hann?",
      A8: "Allir nemendur okkar fá segla. Við erum líka tilbúin að senda ykkur auka segull ef sá fyrsti týnist eða einfaldlega flýgur af bílnum, eins og gerist stundum. Sendu okkur bara tölvupóst á hallo@urdarbrunnur.is með nafni og heimilisfangi og við græjum málið.",
    },
  },
  en: {
    FAQ: {
      Title: "Frequently Asked Questions",
      Q1: "Does Driving School 1 suit those with dyslexia?",
      A1: "Yes, you can use a dyslexia font called OpenDyslexic by going into settings.",
      Q2: "I need a driving instructor in the greater Reykjavík area. Do you offer driving lessons there?",
      A2: "Yes, we do! Have a look at <a href='/okukennarar'>this page</a> for more information.",
      Q3: "How much does learning to drive cost in total?",
      A3: "Luckily we've gone into some depth on the subject in an article that you <a href='/blogg/hvad-kostar-bilprof'>can read here</a>. If you want to save some money (and who doesn't?) then you can also read <a href='/blog/how-to-get-a-cheap-drivers-license-in-Iceland'>this article that tells you how your union can help you</a> potentially lower the cost of learning to drive.",
      Q4: "How do I start Driving School 1?",
      A4: "First you <a href='/registration'>sign up</a> and then you can pay with either a payment card or bank transfer.",
      Q5: "Do you offer motorcycle or 'meirapróf' courses?",
      A5: "Unfortunately not. We focus especially on Driving School 1 and 2. There are other great schools out there that can teach you to drive a motorcycle and more.",
      Q6: "I've completed Driving School 1 but it doesn't show in my electronic driving book. Can you fix it?",
      A6: "Yes, that's no problem. Just call us or send us an email at hallo@urdarbrunnur.is and we'll sort it out.",
      Q7: "Is it possible to take the courses in English?",
      A7: "Yes! We thought you'd never ask. In fact we put a lot of effort into translating the writing and UI properly into English. As you may have noticed we also translated our articles and the entire website.",
      Q8: "I need a learners magnet (also known as the 'æfingaakstur' magnet) to put at the back of my car. Where can I get one?",
      A8: "All our students get driver's magnets. We're even happy to send you another one if you lose it or if it flies off the car, as they have an unfortunate tendency to do. Just send us an email at hallo@urdarbrunnur.is with your name and address and we'll send it to you via post!",
    },
  },
};

const FAQ = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const questions = Array.from({ length: 8 }, (_, i) => ({
    question: t(`FAQ.Q${i + 1}`),
    answer: t(`FAQ.A${i + 1}`),
  }));

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <div className="main-body">
        <h2>{t("FAQ.Title")}</h2>
        <div className="faq-grid">
          {questions.map((item, index) => (
            <div
              key={index}
              className={`faq-item ${openIndex === index ? "open" : ""}`}
            >
              <div
                className="faq-question"
                onClick={() => toggleQuestion(index)}
                style={{ cursor: "pointer" }}
              >
                <h3>{item.question}</h3>
                <span className="faq-icon">
                  {openIndex === index ? "−" : "+"}
                </span>
              </div>
              <div
                className="faq-answer"
                onClick={(e) => e.stopPropagation()}
                style={{ cursor: "text" }}
              >
                <p dangerouslySetInnerHTML={{ __html: item.answer }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
