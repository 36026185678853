import React from "react";
import CourseWrapper from "../CourseWrapper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    DoneOkuskoliTwo: {
      "Vel gert": "Vel gert!",
      "Þú hefur lokið Ökuskóla 2": "Þú hefur lokið Ökuskóla 2",
      Text1:
        "Nú getur þú haldið áfram að æfa þig í æfingarakstri, tekið skriflega ökuprófið eða farið í Ökuskóla 3 og haldið áfram að sýna prýðilegan árgangur á sviði ökunáms.",
      "Aftur á stjórnborð": "Aftur á stjórnborð",
    },
  },
  en: {
    DoneOkuskoliTwo: {
      "Vel gert": "Well done!",
      "Þú hefur lokið Ökuskóla 2": "You have finished Driving School 2",
      Text1:
        "You can now continue practicing through Learner Driving (æfingaakstur). You can also take the written exam and go to Driving School 3.",
      "Aftur á stjórnborð": "Back to dashboard",
    },
  },
};

const Done2 = () => {
  const { t } = useTranslation();

  return (
    <CourseWrapper courseId="2" title={t("Done.Vel gert")}>
      <div className="">
        <h1>{t("DoneOkuskoliTwo.Vel gert")}</h1>
        <h2>{t("DoneOkuskoliTwo.Þú hefur lokið Ökuskóla 2")}</h2>
        <p>{t("DoneOkuskoliTwo.Text1")}</p>
        <Link to="/stjornbord" className="btn">
          {t("DoneOkuskoliTwo.Aftur á stjórnborð")}
        </Link>
      </div>
    </CourseWrapper>
  );
};

export default Done2;
