import React, { FC } from "react";

type PrettyDataProps = {
	json: {};
};

const PrettyData: FC<PrettyDataProps> = ({ json }) => {
	return (
		<div>
			<pre>{JSON.stringify(json, null, 2)}</pre>
		</div>
	);
};

export default PrettyData;
