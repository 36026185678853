import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

/**
 * Returns number in Icelandic currency format
 * e.g. 10000 => 12.000 kr.
 */
export const toCorrectISKFormat = (number: number) => {
  let str = number.toLocaleString("en-US");
  str = str.replace(/,/g, ".");
  return str + " kr.";
};
