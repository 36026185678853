import React, { FC } from "react";
import axios from "axios";
import { RouteComponentProps } from "react-router-dom";
import { Steps } from "../../store/steps";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    NextStepButton: {
      "Næsta skref": "Næsta skref",
      "Fara í krossapróf": "Fara í krossapróf",
    },
  },
  en: {
    NextStepButton: {
      "Næsta skref": "Next step",
      "Fara í krossapróf": "Go to round test",
    },
  },
};

interface NextStepButtonProps {
  history: RouteComponentProps["history"]; // eslint-disable-line
  params: { courseId: string; roundNr: string; stepNr: string };
  steps: Steps[];
}

export const NextStepButton: FC<NextStepButtonProps> = ({
  history,
  params,
  steps,
}) => {
  const { courseId, roundNr } = params;
  const stepNr = parseInt(params.stepNr);
  const { t } = useTranslation();

  const changeStep = (
    stepNr: number,
    stepId: number | null,
    stepStatus: string | null,
    roundNr: string,
    course: number
  ) => {
    if (stepStatus === null) {
      axios
        .post("/api/update-user-step", {
          stepId: stepId,
          stepNr: stepNr,
          roundNr: roundNr,
          course: course,
        })
        .then((res) => {
          res.data.stepNr = stepNr;
          history.push(`/okuskoli-${courseId}/lota-${roundNr}/skref-${stepNr}`);
        })
        .catch((error) => {
          if (error) console.error(error);
        });
    } else {
      history.push(`/okuskoli-${courseId}/lota-${roundNr}/skref-${stepNr}`);
    }
  };

  const goToNextStep = () => {
    if (stepNr === 10) {
      history.push(`/okuskoli-${courseId}/lota-${roundNr}/krossaprof`);
    } else {
      let stepId = null;
      let stepStatus = null;
      let nextStep = stepNr + 1;
      steps.forEach((step) => {
        if (step.nr == nextStep) {
          stepId = step.id;
          stepStatus = step.user_course_round_steps[0].status;
        }
      });
      changeStep(nextStep, stepId, stepStatus, roundNr, 1);
    }
  };

  const shouldDisplayButton = (steps: Steps[], stepNr: number) => {
    let shouldDisplay = false;
    steps.forEach((step) => {
      if (step.nr === stepNr) {
        if (step.user_course_round_steps[0].status === "Finished") {
          shouldDisplay = true;
        }
      }
    });
    return shouldDisplay;
  };

  let nextButtonText = "";
  if (stepNr < 10) {
    nextButtonText = t("NextStepButton.Næsta skref");
  } else if (stepNr === 10) {
    nextButtonText = t("NextStepButton.Fara í krossapróf");
  }

  // Should display button
  const shouldDisplay = shouldDisplayButton(steps, stepNr);
  if (shouldDisplay) {
    return (
      <button type="submit" id="next" className="btn" onClick={goToNextStep}>
        {nextButtonText} <i className="fa fa-arrow-right"></i>
      </button>
    );
  }
  return null;
};

export default NextStepButton;
