import React, { useEffect, useState } from "react";
import axios from "axios";
import CourseWrapper from "../CourseWrapper";
import {
  updateFinalTestAnswer,
  updateFinalTestAnswers,
  getFinalTestData,
} from "../store/redux";
import { connect } from "react-redux";
import { TestQuestions } from "./rounds/Test/TestQuestions";
import { RouteComponentProps } from "react-router-dom";
import { AuthReducerTypes, IndexReducerTypes } from "../store/types";
import { Dispatch } from "redux";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    FinalTest: {
      MetaTitle: "Lokapróf Ö",
      "Þú átt eftir að svara": "Þú átt eftir að svara",
      spurningu: "spurningu",
      spurningum: "spurningum",
      "Lokapróf Ökuskóla": "Lokapróf Ökuskóla",
      "Þú mátt ekki fá fleiri en 3 villur til að ná prófinu":
        "Þú mátt ekki fá fleiri en 3 villur til að ná prófinu.",
      "Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið Ökuskóla":
        "Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið Ökuskóla",
      "Halda áfram": "Halda áfram",
      "Vel gert! Þú náðir lokaprófinu og hefur nú lokið Ökuskóla":
        "Vel gert! Þú náðir lokaprófinu og hefur nú lokið Ökuskóla",
      "Þú varst með": "Þú varst með",
      villu: "villu",
      villur: "villur",
      "Taka aftur": "Taka aftur",
      Svara: "Svara",
    },
  },
  en: {
    FinalTest: {
      MetaTitle: "Final Test DS",
      "Þú átt eftir að svara": "You haven't answered",
      spurningu: "question",
      spurningum: "questions",
      "Lokapróf Ökuskóla": "Final Test - Driving School",
      "Þú mátt ekki fá fleiri en 3 villur til að ná prófinu":
        "To pass the final test you can't have more than 3 wrong answers.",
      "Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið Ökuskóla":
        "Well done! You answered all the questions correctly and have now finished Driving School",
      "Halda áfram": "Continue",
      "Vel gert! Þú náðir lokaprófinu og hefur nú lokið Ökuskóla":
        "Well done! You passed the final test and have now finished Driving School",
      "Þú varst með": "You had",
      villu: "wrong answer",
      villur: "wrong answers",
      "Taka aftur": "Try again",
      Svara: "Answer",
    },
  },
};

interface FinalTestProps extends RouteComponentProps<{ courseId: string }> {
  dispatch: Dispatch;
  authReducer: AuthReducerTypes;
}

const FinalTest = ({
  authReducer,
  dispatch,
  match,
  history,
}: FinalTestProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { courseId } = match.params;
  const { userTest, wrongTestAnswers, finalTestAnswers, finalTest } =
    authReducer;
  const test = authReducer.finalTest[0];
  const { status } = userTest;
  const { t } = useTranslation();

  useEffect(() => {
    fetchTestQuestions();
  }, []);

  const fetchTestQuestions = () => {
    axios
      .get(`/api/final-test?course=${courseId}&startTest=true`)
      .then((res) => {
        dispatch(
          getFinalTestData({
            finalTest: res.data.finalTest,
            userTest: res.data.userTest,
          })
        );
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  const answerSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const answerId = e.target.value;
    const questionId = e.currentTarget.name;

    dispatch(
      updateFinalTestAnswer({
        ftestAnswerId: answerId,
        ftestQuestionId: questionId,
        ftestChecked: e.target.checked,
      })
    );
  };

  const displayValidationError = () => {
    const unansweredQuestions: string[] = [];

    // Build a list of the unanswered question id's
    finalTest[0].questions.forEach((question, index) => {
      let hasBeenAnswered = false;
      finalTestAnswers.forEach((answer) => {
        if (question.id === parseInt(answer.questionId)) {
          hasBeenAnswered = true;
        }
      });
      if (!hasBeenAnswered) {
        unansweredQuestions.push(" " + (index + 1));
      }
    });
    const questionsString =
      unansweredQuestions.length === 1
        ? t("FinalTest.spurningu")
        : t("FinalTest.spurningum");
    const message = `${t(
      "FinalTest.Þú átt eftir að svara"
    )} ${questionsString} ${unansweredQuestions.toString()}`;
    setErrorMessage(message);
  };

  const submitTest = () => {
    // Check if there are unanswered questions
    if (finalTestAnswers.length < 30) {
      displayValidationError();
    } else {
      const testId = authReducer.finalTest[0].id;
      const userTestId = authReducer.userTest.id;

      axios
        .post("/api/submit-final-test", {
          answers: finalTestAnswers,
          testId: testId,
          userTestId: userTestId,
          course: parseInt(courseId),
        })
        .then((res) => {
          dispatch(
            updateFinalTestAnswers({
              ...res.data,
            })
          );
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  };

  const retakeTest = () => fetchTestQuestions();
  const forward = () => history.push(`/okuskoli-${courseId}/vel-gert`);

  let submitTestButton: null | JSX.Element = (
    <input
      type="submit"
      id="submitTest"
      value={t("FinalTest.Svara")}
      className="btn"
      onClick={submitTest}
    />
  );
  let retakeTestButton = null;
  let disabled = false;

  if (userTest !== null && status === "Finished") {
    disabled = true;
    submitTestButton = null;
    retakeTestButton = (
      <input
        type="submit"
        id="retake"
        value={t("FinalTest.Taka aftur")}
        className="btn"
        onClick={retakeTest}
      />
    );
  }

  let wrongAnswers = null;
  let maxErrors = null;
  let roundFinished = null;
  let roundFinishedButton = null;
  let isTestSubmitted = false;

  if (wrongTestAnswers !== null) {
    isTestSubmitted = true;
  }

  // Test has been submitted and if there was more than 0 wrong answers
  if (isTestSubmitted && wrongTestAnswers !== null && wrongTestAnswers > 0) {
    let wrongAnswerCount = " " + t("FinalTest.villur");
    if (wrongTestAnswers === 1) {
      wrongAnswerCount = " " + t("FinalTest.villu");
    }
    wrongAnswers = (
      <div>
        {t("FinalTest.Þú varst með") +
          " " +
          authReducer.wrongTestAnswers +
          " " +
          wrongAnswerCount}
      </div>
    );
    if (wrongTestAnswers < 4) {
      roundFinished = (
        <div className="positive-alert">
          <p>{`${t(
            "FinalTest.Vel gert! Þú náðir lokaprófinu og hefur nú lokið Ökuskóla"
          )} ${courseId}!`}</p>
        </div>
      );
      roundFinishedButton = (
        <input
          type="submit"
          id="forward"
          value={t("FinalTest.Halda áfram")}
          className="btn"
          onClick={forward}
        />
      );
    } else {
      maxErrors = (
        <div className="negative-alert">
          <p>
            {t(
              "FinalTest.Þú mátt ekki fá fleiri en 3 villur til að ná prófinu"
            )}
          </p>
        </div>
      );
    }
  } else if (isTestSubmitted && wrongTestAnswers === 0) {
    // If all answers were answered right then show this message.
    roundFinished = (
      <div className="positive-alert">
        <p>{`${t(
          "FinalTest.Vel gert! Þú svaraðir öllum spurningum rétt og hefur nú lokið Ökuskóla"
        )} ${courseId}!`}</p>
      </div>
    );
    roundFinishedButton = (
      <input
        type="submit"
        id="forward"
        value={t("FinalTest.Halda áfram")}
        className="btn"
        onClick={forward}
      />
    );
  }

  return (
    <CourseWrapper
      courseId={courseId}
      title={`${t("FinalTest.MetaTitle")}${courseId}`}
    >
      <div className="test-container">
        <h1>
          {t("FinalTest.Lokapróf Ökuskóla")} {courseId}
        </h1>
        <ul className="questionRow-ul">
          {test && (
            <TestQuestions
              testAnswers={finalTestAnswers}
              userTest={userTest}
              disabled={disabled}
              test={test}
              answerSelected={answerSelected}
              testType={"FinalTest"}
            />
          )}
        </ul>
        <div>{errorMessage}</div>
        {wrongAnswers}
        {maxErrors}
        {roundFinished}
        <div className="btn-div">
          {submitTestButton}
          {retakeTestButton}
          {roundFinishedButton}
        </div>
      </div>
    </CourseWrapper>
  );
};

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(FinalTest);
