import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Alert: {
      Lota: "Lota",
      "er nú opin": "er nú opin",
      "Smelltu á Byrja Lotu": "Byrjaðu Lotu",
      "fyrir ofan til að byrja": "með því að endurhlaða śiðuna",
      mínútur: "mínútur",
      mínúta: "mínúta",
      eru: "eru",
      er: "er",
      "tímar og": "tímar og",
      "tími og": "tími og",
      Það: "Það",
      "í að lota": "í að lota",
      opnist: "opnist",
      "Í millitíðinni getur þú endurtekið lotu":
        "Í millitíðinni getur þú endurtekið lotu",
      "Æfingin skapar umferðarmeistarann": "Æfingin skapar umferðarmeistarann",
      "Samkvæmt reglum Samgöngustofu um netökuskóla verður að vera sólahringur á milli lota":
        "Samkvæmt reglum Samgöngustofu um netökuskóla verður að vera sólahringur á milli lota.",
    },
  },
  en: {
    Alert: {
      Lota: "Round",
      "er nú opin": "is now open",
      "Smelltu á Byrja Lotu": "Refresh to begin Round",
      "fyrir ofan til að byrja": "",
      mínútur: "minutes",
      mínúta: "minutes",
      eru: "are",
      er: "is",
      "tímar og": "hours and",
      "tími og": "hour and",
      Það: "There",
      "í að lota": "until round",
      opnist: "opens",
      "Í millitíðinni getur þú endurtekið lotu":
        "In the meantime you can repeat round",
      "Æfingin skapar umferðarmeistarann": "Practice makes perfect",
      "Samkvæmt reglum Samgöngustofu um netökuskóla verður að vera sólahringur á milli lota":
        "According to rules from the Ministry of Transport there must be one day between rounds.",
    },
  },
};

interface AlertProps {
  showAlert: boolean;
  courseStatus: "Finished" | "Expired" | "Not Started" | "Ongoing";
  nextRoundOpen: boolean;
  timerMinutes: number;
  timerHours: number;
  prevNr: number;
  nr: number;
}

// Data is passed to this component from the Main.jsx component
// It spits out the time until the next round is open with the correct text
// And when the round is open it should render a message informing the user.
export const Alert: FC<AlertProps> = ({
  showAlert,
  courseStatus,
  nextRoundOpen,
  timerMinutes,
  timerHours,
  prevNr,
  nr,
}) => {
  const { t } = useTranslation();

  let alertText = null;
  if (showAlert && courseStatus !== "Expired") {
    if (nextRoundOpen || timerMinutes < 0) {
      // Return that round is open.
      alertText = (
        <div className="time-to-round">
          <div className="alert-box">
            <i className="fa fa-clock-o"></i>
            <div className="alert-text-container">
              <h2>{`${t("Alert.Lota")} ${nr} ${t("Alert.er nú opin")}!`}</h2>
              <p>{`${t("Alert.Smelltu á Byrja Lotu")} ${nr} ${t(
                "Alert.fyrir ofan til að byrja"
              )}.`}</p>
            </div>
          </div>
        </div>
      );
    } else {
      // Format text to fit Icelandic grammar rules
      let minutes = t("Alert.mínútur");
      let hours = "";
      let is = t("Alert.eru");

      // Format minutes text
      // Use singular if 1, 21, 31 etc minutes are left
      if (timerMinutes % 10 === 1 && timerMinutes !== 11) {
        minutes = t("Alert.mínúta");
        is = t("Alert.er");
      }

      // Format hours text
      // Use singular if 1 hour is left
      if (timerHours > 1) {
        hours = `${timerHours} ${t("Alert.tímar og")} `;
      } else if (timerHours === 1) {
        hours = `${timerHours} ${t("Alert.tími og")} `;
      } else {
        hours = "";
      }

      // Put it all together.
      alertText = (
        <div>
          <div className="time-to-round">
            <div className="alert-box">
              <i className="fa fa-clock-o"></i>
              <div className="alert-text-container">
                <h2>{`${t("Alert.Það")} ${is} ${
                  hours + timerMinutes
                } ${minutes} ${t("Alert.í að lota")} ${nr} ${t(
                  "Alert.opnist"
                )}`}</h2>
                <p>
                  {`${t(
                    "Alert.Í millitíðinni getur þú endurtekið lotu"
                  )} ${prevNr}. ${t(
                    "Alert.Æfingin skapar umferðarmeistarann"
                  )}!`}
                </p>
              </div>
            </div>
          </div>
          <p>
            {t(
              "Alert.Samkvæmt reglum Samgöngustofu um netökuskóla verður að vera sólahringur á milli lota"
            )}
          </p>
        </div>
      );
    }
  }

  return <>{alertText}</>;
};
