import React, { FC } from "react";
import PaymentStep from "./PaymentStep";
import { useLocation } from "react-router-dom";

type PaymentNavProps = {
  isPaymentFlow: boolean | undefined;
  paymentStep: "step-1" | "step-2" | "step-3" | undefined;
};

const useDetectSchool = () => {
  const location = useLocation();

  let school = "1";
  if (
    location.pathname === "/okuskoli2-upplysingar" ||
    location.pathname === "/okuskoli-2/skraning" ||
    location.pathname === "/okuskoli-2/takk-fyrir"
  ) {
    school = "2";
  }
  return school;
};

const PaymentNav: FC<PaymentNavProps> = ({ paymentStep, isPaymentFlow }) => {
  const school = useDetectSchool();
  if (!isPaymentFlow || !paymentStep)
    // If the page isn't a part of the payment flow, don't render anything.
    return null;

  // Takes step number, returns states as array
  const [firstState, secondState, thirdState] = generateClasses(paymentStep);

  // Hacky management of if arrows are given active-pd class or not.
  let firstClass;
  let secondClass;
  firstState === "Finished" ? (firstClass = "active-pd") : (firstClass = "");
  secondState === "Finished" ? (secondClass = "active-pd") : (secondClass = "");
  return (
    <div className="payment-nav">
      <div className="payment-container">
        <PaymentStep
          stepNr={1}
          state={firstState}
          path={`/okuskoli${school}-upplysingar`}
        />
        <span className={`payment-divider ${firstClass}`}></span>
        <i className={`arrow right ${firstClass}`}></i>
        <PaymentStep
          stepNr={2}
          state={secondState}
          path={`/okuskoli-${school}/skraning`}
        />
        <span className={`payment-divider ${secondClass}`}></span>
        <i className={`arrow right ${secondClass}`}></i>
        <PaymentStep
          stepNr={3}
          state={thirdState}
          path={`/okuskoli-${school}/takk-fyrir`}
        />
      </div>
    </div>
  );
};

// Takes step number, returns states as array
const generateClasses = (stepNumber: string) => {
  const lastChar = stepNumber.charAt(stepNumber.length - 1);
  const number = parseInt(lastChar);
  const emptyArray = ["", "", ""];

  const results = emptyArray.map((elem, index) => {
    const numberIndex = number - 1;

    if (numberIndex > index) {
      return "Finished";
    } else if (numberIndex === index) {
      return "Active";
    } else if (numberIndex < index) {
      return "Unfinished";
    }
    // if all fails, return unfinished
    return "Unfinished";
  });

  return results;
};

export default PaymentNav;
