import React from "react";
import DesktopMenu from "./Navigation";
import MobileMenu from "./MobileMenu";
import Footer from "./Footer";
import Helmet from "./Helmet";
import { Link } from "react-router-dom";
import Button from "../../reusables/button";
import { Pages } from "../../routes/routes";
import BlogOverview from "./BlogOverview";

import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    FrontPage: {
      MetaTitle: "Ökuskóli 1 og 2",
      StrapLine: "Ökuskóli 1 og 2 á netinu",
      StrapLineDesc:
        "Taktu ökuskólann þar sem þú vilt, þegar þú vilt og náðu bóklega ökuprófinu í fyrstu tilraun.",
      Benefit1: "Lærðu hvar sem er",
      Benefit2: "Lærðu hvenær sem er",
      Price: "Verð: 12.500 kr",
      "Skráðu þig": "Skráðu þig",
      "Lestu meira": "Lestu meira",
      MainSalesText:
        "Netökuskóli Urðarbrunns er í stöðugri þróun til þess að veita besta ökunám á Íslandi.",
      Myndrænt: "Myndrænt",
      MyndræntTexti:
        "Við leggjum áhærslu á að útskýra flókið efni með myndum og myndböndum.",
      Gagnvirkt: "Gagnvirkt",
      GagnvirktTexti:
        "Við notum próf og spurningar í hverju skrefi til að tryggja að þú skiljir efnið.",
      Þægilegt: "Þægilegt",
      ÞægilegtTexti:
        "Enginn strætó, ekkert skutl. Það er hægt að læra hvaðan sem er í gegnum netið.",
      AlltafOpið: "Alltaf opið",
      AlltafOpiðTexti: "Námsefnið er aðgengilegt allan sólarhringinn.",
      GiltNám: "Gilt nám",
      GiltNámTexti: "Nám hjá okkur kemur í staðinn fyrir nám í skólastofu.",
      Öryggi: "Öryggi",
      ÖryggiTexti:
        "Greiðslur eru öruggar og við deilum ekki upplýsingum nema með ökukennara þínum.",
      UmÖkuskóla1Titill: "Ökuskóli 1 og 2",
      UmÖkuskóla1Texti1:
        "Ökuskóla 1 og 2 er skipt upp í 6 lotur. Til að hjálpa þér að muna efnið er mikið notast við myndir og gangvirk verkefni. Eftir hverja lotu er lotupróf þar sem er spurt er úr efninu.",
      UmÖkuskóla1Texti2:
        "Til þess að taka þátt í Ökuskóla 1 þarf nemandi að hafa námsheimild frá sýslumanni og klárað 1 ökutíma hjá ökukennara. ",
      "Lestu meira um Ö1": "Lestu meira um Ö1",
      "Skráðu þig í ökuskóla 1": "Skráðu þig í ökuskóla 1",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Einhverjar spurningar? Hringdu í síma 777-9344.",
    },
  },
  en: {
    FrontPage: {
      MetaTitle: "Driving School 1 and 2 online",
      StrapLine: "Driving School 1 and 2 online",
      StrapLineDesc:
        "Complete Driving School 1 and 2 wherever you'd like, whenever you'd like and ace the test in the first try.",
      Benefit1: "Completely online course",
      Benefit2: "Study at any time that suits you",
      Price: "Price: 12.500 kr",
      "Skráðu þig": "Sign up",
      "Lestu meira": "Read more",
      MainSalesText:
        "Over 1,000 students have gone through our course. Here's why:",
      Myndrænt: "Visual",
      MyndræntTexti: "We explain complex concepts with pictures and videos.",
      Gagnvirkt: "Interactive",
      GagnvirktTexti:
        "We use quizes in each step to ensure you've understood the content.",
      Þægilegt: "All online",
      ÞægilegtTexti:
        "No need to take the bus to take the course or beg a friend to drive you. You can study from wherever.",
      AlltafOpið: "Always open",
      AlltafOpiðTexti:
        "The course is always accessible. 24/7 and even months after you've finished the course.",
      GiltNám: "Certified Course",
      GiltNámTexti:
        "Studying online is equivalent to taking the course in person.",
      Öryggi: "Safe and secure",
      ÖryggiTexti:
        "Payments are secure and we only share your progress with your driving instructor.",
      UmÖkuskóla1Titill: "Driving School 1 and 2",
      UmÖkuskóla1Texti1:
        "Driving School 1 and 2 are split into 6 rounds. We rely on pictures and quizes to help you understand and remember the content. After each round we have a 'round test' where we quiz you on the content of the round.",
      UmÖkuskóla1Texti2:
        "Before starting Driving School 1 you should have finished at least one driving lesson with a driving instructor. You'll get more out of the course that way.",
      "Lestu meira um Ö1": "Read more",
      "Skráðu þig í ökuskóla 1": "Sign up to Driving School 1",
      "Einhverjar spurningar? Hringdu í síma 777-9344.":
        "Any questions? Call us at 777-9344.",
    },
  },
};

const FrontPage = () => {
  const { t } = useTranslation();

  // When someone clicks on "Read more" they
  // should scroll smoothly to content
  const scrollToContent = (event: React.SyntheticEvent) => {
    event.preventDefault();
    document.querySelector("#lesameira")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Helmet pageTitle={t("FrontPage.MetaTitle")} />
      <MobileMenu />
      <div className="site-wrap">
        <div className="push-wrap front-page">
          <DesktopMenu />
          <div className="main-body first-section">
            <div className="front-page-first-text">
              <h1>{t("FrontPage.StrapLine")}</h1>
              <p>{t("FrontPage.StrapLineDesc")}</p>
              <div className="checkmark-container">
                <i className="fa fa-check" />
                <p className="checkmark-text">{t("FrontPage.Benefit1")}</p>
              </div>
              <div className="checkmark-container">
                <i className="fa fa-check" />
                <p className="checkmark-text">{t("FrontPage.Benefit2")}</p>
              </div>
              <div className="checkmark-container">
                <i className="fa fa-money" />
                <p className="checkmark-text">{t("FrontPage.Price")}</p>
              </div>
              <div className="button-container">
                <Link className="main-button" to={t(Pages.Registration)}>
                  {t("FrontPage.Skráðu þig")}
                </Link>
              </div>
              <div className="button-container">
                <a
                  className="secondary-button"
                  href="#lesameira"
                  id="read-more-button"
                  onClick={scrollToContent}
                >
                  {t("FrontPage.Lestu meira")}
                </a>
              </div>
            </div>
            <picture>
              <source srcSet="/images/driver-1x1.webp" type="image/webp" />
              <source srcSet="/images/driver-1x1.jpg" type="image/jpeg" />
              <img
                className="front-page-image"
                src="/images/driver-1x1.jpg"
                alt="Ökumaður"
              />
            </picture>
          </div>
          <div className="second-section" id="lesameira">
            <div className="main-body">
              <p className="sales-main-text">{t("FrontPage.MainSalesText")}</p>
              <div className="threebytwo">
                <i className="fa fa-check-circle" />
                <h2>{t("FrontPage.Myndrænt")}</h2>
                <p>{t("FrontPage.MyndræntTexti")}</p>
              </div>
              <div className="threebytwo">
                <i className="fa fa-mobile" />
                <h2>{t("FrontPage.Gagnvirkt")}</h2>
                <p>{t("FrontPage.GagnvirktTexti")}</p>
              </div>
              <div className="threebytwo">
                <i className="fa fa-user" />
                <h2>{t("FrontPage.Þægilegt")}</h2>
                <p>{t("FrontPage.ÞægilegtTexti")}</p>
              </div>
              <div className="threebytwo">
                <i className="fa fa-clock-o" />
                <h2>{t("FrontPage.AlltafOpið")}</h2>
                <p>{t("FrontPage.AlltafOpiðTexti")}</p>
              </div>
              <div className="threebytwo">
                <i className="fa fa-certificate" />
                <h2>{t("FrontPage.GiltNám")}</h2>
                <p>{t("FrontPage.GiltNámTexti")}</p>
              </div>
              <div className="threebytwo">
                <i className="fa fa-lock" />
                <h2>{t("FrontPage.Öryggi")}</h2>
                <p>{t("FrontPage.ÖryggiTexti")}</p>
              </div>
            </div>
          </div>
          <div className="main-body third-section">
            <div className="initial-text">
              <h2>{t("FrontPage.UmÖkuskóla1Titill")}</h2>
              <p>{t("FrontPage.UmÖkuskóla1Texti1")}</p>
              <p>{t("FrontPage.UmÖkuskóla1Texti2")} </p>
              <Button
                size="medium"
                href={t(Pages.DrivingSchool1)}
                type="react-router-link"
              >
                {t("FrontPage.Lestu meira um Ö1")}{" "}
                <i className="fa fa-arrow-right"></i>
              </Button>
            </div>
            <picture>
              <source data-srcset="/images/4.webp" type="image/webp" />
              <source data-srcset="/images/4.jpg" type="image/jpeg" />
              <img
                data-src="/images/4.PNG"
                className="lazyload"
                alt="Skjáskot af námskeiði"
              />
            </picture>
            <picture>
              <source data-srcset="/images/2.webp" type="image/webp" />
              <source data-srcset="/images/2.jpg" type="image/jpeg" />
              <img
                data-src="/images/2.PNG"
                className="lazyload"
                alt="Skjáskot af námskeiði"
              />
            </picture>
          </div>
          <div className="fourth-section">
            <div className="main-body">
              <BlogOverview />
            </div>
          </div>
          <div className="main-body">
            <div className="third-section-text-container">
              <div className="button-container">
                <Link className="main-button" to={t(Pages.Registration)}>
                  {t("FrontPage.Skráðu þig í ökuskóla 1")}
                </Link>
              </div>
              <p>
                {t("FrontPage.Einhverjar spurningar? Hringdu í síma 777-9344.")}
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FrontPage;
