import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import ScrollToTop from "./ScrollToTop";
import { useTranslation } from "react-i18next";
import { pages } from "../../pages";
import { useGetLang } from "../hooks/useGetLang";
import { useLocation } from "react-router-dom";

// Site
import About from "../components/site/About";
import FrontPage from "../components/site/FrontPage";
import Login from "../components/site/Login";
import ForgotPassword from "../components/site/ForgotPassword";
import ResetPassword from "../components/site/ResetPassword";
import Okuskoli1SalesPage from "../components/site/sales-pages/Okuskoli1SalesPage";
import Okuskoli2SalesPage from "../components/site/sales-pages/Okuskoli2SalesPage";
import Terms from "../components/site/Terms";
import DrivingInstructors from "../components/site/driving-instructor";
import Registration from "../components/site/Registration";
import NotFound from "../components/site/NotFound";

// Blog
import Process from "../components/site/blog/Process";
import Cost from "../components/site/blog/Cost";
import Unions from "../components/site/blog/Unions";

// App
import Dashboard from "../components/Dashboard";

// Purchase flow
import Okuskoli1Info from "../components/payment/Okuskoli1Info";
import Okuskoli2Info from "../components/payment/Okuskoli2Info";
import Payment from "../components/payment/Payment";
import ThankYou from "../components/payment/ThankYou";
import PaymentCanceled from "../components/payment/PaymentCanceled";
import PaymentError from "../components/payment/PaymentError";

// Ökuskóli 1 + 2
import Okuskoli1Main from "../components/courses/subdash/Main";
import Step from "../components/courses/rounds/Step";
import Test from "../components/courses/rounds/Test";
import FinalTest from "../components/courses/FinalTest";

// Done pages
import Done from "../components/courses/Done";
import Okuskoli2Done from "../components/courses/DoneOkuskoliTwo";

// Settings
import Settings from "../components/settings";

// Admin pages
import Admin from "../components/admin";
import Student from "../components/admin/student";
import Content from "../components/admin/content";
import StepEditor from "../components/admin/step-editor";
import Statistics from "../components/admin/Statistics";

// TODO: bring into normal page structure...
const AppPages = {
  DrivingSchoolOneInformation: "/okuskoli1-upplysingar",
  Settings: "PageLinks.Settings",
};

// Bring in the pages and put into a format for i18nReact
export const PageLinks = {
  is: {
    PageLinks: {
      ...pages["is"],
    },
  },
  en: {
    PageLinks: {
      ...pages["en"],
    },
  },
};

export const Pages = {
  FrontPage: "PageLinks.FrontPage",
  AboutUs: "PageLinks.AboutUs",
  ForgotPassword: "PageLinks.ForgotPassword",
  ResetPassword: "PageLinks.ResetPassword",
  TermsAndConditions: "PageLinks.TermsAndConditions",
  BlogCostOfLicense: "PageLinks.BlogCostOfLicense",
  BlogHowToGetLicense: "PageLinks.BlogHowToGetLicense",
  DrivingSchool1: "PageLinks.Okuskoli1",
  DrivingSchool2: "PageLinks.Okuskoli2",
  Login: "PageLinks.Login",
  Registration: "PageLinks.Registration",
  DrivingInstructors: "PageLinks.DrivingInstructors",
  HowUnionsHelp: "PageLinks.Unions",
  ...AppPages,
};

// Extremely hacky way of
// 1. Setting the correct language in the <html> tag on render
// 2. Setting the language to English if an English route is triggered
const LanguageLogic = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const lang = useGetLang();

  // Set correct language in main <html> tag.
  // It'll look like this: <html lang="is">
  const changeLanguageInHtmlTag = (lang: "is" | "en") => {
    const html = document.querySelector("html");
    html?.setAttribute("lang", lang);
  };

  Object.keys(pages["en"]).forEach((key) => {
    if (location.pathname === pages["en"][key] + "/" && lang !== "en") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
      changeLanguageInHtmlTag("en");
    }
  });

  changeLanguageInHtmlTag(lang);

  return null;
};

export default function Routes() {
  const { t } = useTranslation();

  return (
    <Router>
      <>
        <LanguageLogic />
        <ScrollToTop />
        <Switch>
          {/* Payment flow */}
          <AuthRoute
            path={Pages.DrivingSchoolOneInformation}
            component={Okuskoli1Info}
          />
          <AuthRoute path="/okuskoli2-upplysingar" component={Okuskoli2Info} />
          <AuthRoute path="/okuskoli-:courseId/skraning" component={Payment} />
          <AuthRoute
            path="/okuskoli-:courseId/takk-fyrir"
            component={ThankYou}
          />
          <AuthRoute
            path="/okuskoli-:courseId/villa"
            component={PaymentError}
          />
          <AuthRoute
            path="/okuskoli-:courseId/haetta"
            component={PaymentCanceled}
          />
          {/* Courses done pages*/}
          <AuthRoute path="/okuskoli-1/vel-gert" component={Done} />
          <AuthRoute
            path="/okuskoli-2/vel-gert"
            exact
            component={Okuskoli2Done}
          />
          {/* Course */}
          <AuthRoute
            path="/okuskoli-:courseId/lota-:roundNr/skref-:stepNr"
            component={Step}
          />
          <AuthRoute
            path="/okuskoli-:courseId/lota-:roundNr/krossaprof"
            component={Test}
          />
          <AuthRoute
            path="/okuskoli-:courseId/lokaprof"
            component={FinalTest}
          />
          <AuthRoute
            path="/okuskoli-:courseId"
            exact
            component={Okuskoli1Main}
          />
          {/* Misc admin pages */}
          <AuthRoute path={t(Pages.Settings)} exact component={Settings} />
          <AuthRoute path="/command" exact component={Admin} />
          <AuthRoute
            path="/command/nemandi/:userId"
            exact
            component={Student}
          />
          <AuthRoute path="/command/content" exact component={Content} />
          <AuthRoute
            path="/command/content/skref/:stepId"
            exact
            component={StepEditor}
          />

          {/* Statistics */}
          <AuthRoute path="/statistics" exact component={Statistics} />

          {/* Application */}
          <AuthRoute path="/stjornbord" component={Dashboard} />

          {/* Site */}
          <Route path={t(Pages.TermsAndConditions)} component={Terms} />
          <Route path={pages.en.TermsAndConditions} component={Terms} />

          <Route path={t(Pages.ForgotPassword)} component={ForgotPassword} />
          <Route path={pages.en.ForgotPassword} component={ForgotPassword} />

          <Route path={t(Pages.ResetPassword)} component={ResetPassword} />
          <Route path={pages.en.ResetPassword} component={ResetPassword} />

          <Route
            path={t(Pages.DrivingSchool1)}
            component={Okuskoli1SalesPage}
          />
          <Route path={pages.en.Okuskoli1} component={Okuskoli2SalesPage} />
          <Route
            path={t(Pages.DrivingSchool2)}
            component={Okuskoli2SalesPage}
          />
          <Route path={pages.en.Okuskoli2} component={Okuskoli2SalesPage} />
          <Route path={t(Pages.AboutUs)} component={About} />
          <Route path={pages.en.AboutUs} component={About} />

          <Route path={t(Pages.Login)} component={Login} />
          <Route path={pages.en.Login} component={Login} />
          <Route path={t(Pages.Registration)} component={Registration} />
          <Route path={pages.en.Registration} component={Registration} />

          <Route
            path={t(Pages.DrivingInstructors)}
            component={DrivingInstructors}
          />
          <Route
            path={pages.en.DrivingInstructors}
            component={DrivingInstructors}
          />

          {/* Blog */}
          <Route path={t(Pages.BlogHowToGetLicense)} component={Process} />
          <Route path={pages.en.BlogHowToGetLicense} component={Process} />
          <Route path={t(Pages.BlogCostOfLicense)} component={Cost} />
          <Route path={pages.en.BlogCostOfLicense} component={Cost} />
          <Route path={t(Pages.HowUnionsHelp)} component={Unions} />
          <Route path={pages.en.Unions} component={Unions} />

          {/* Front page and 404 */}
          <Route exact path={t(Pages.FrontPage)} component={FrontPage} />
          <Route path={pages.en.FrontPage} component={FrontPage} />

          <Route component={NotFound} />
        </Switch>
      </>
    </Router>
  );
}
