import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavItem from "./NavItem";
import MobileNav from "./MobileNav";
import { okuskoliData } from "../store/redux";
import PaymentNav from "./paymentNav";
import { AuthReducerTypes, IndexReducerTypes } from "../store/types";
import { useTranslation } from "react-i18next";
import { Pages } from "../../routes/routes";

export const translations = {
  is: {
    DashNavigation: {
      Próf: "Próf",
      Stillingar: "Stillingar",
      "Skrá út": "Skrá út",
      Lota: "Lota",
      Valmynd: "Valmynd",
    },
  },
  en: {
    DashNavigation: {
      Próf: "Test",
      Stillingar: "Settings",
      "Skrá út": "Sign out",
      Lota: "Round",
      Valmynd: "Menu",
    },
  },
};

type NavigationProps = {
  authReducer: AuthReducerTypes;
  courseId?: string;
  dispatch: any;
  paymentStep?: "step-1" | "step-2" | "step-3";
  isPaymentFlow?: boolean;
};

type RoundLinkType = {
  roundNr: number;
  roundStatus: "Finished" | "Not Started" | "Ongoing" | "Expired";
  isCurrentRound: boolean;
  nextRoundOpen: boolean;
};

const Navigation = ({
  courseId,
  paymentStep,
  isPaymentFlow,
  authReducer,
  dispatch,
}: NavigationProps) => {
  const { userCourses, userRounds, messageFeatureUsed, currentStep } =
    authReducer;
  let finalTestStatus = "fa fa-lock";
  let finalTestEnabled = "disabled-link";
  let allFinished = true;
  const { t } = useTranslation();

  const links: Array<RoundLinkType> = [];

  let courseStatus = "";
  userCourses.forEach((course: any) => {
    if (courseId && parseInt(course.id) === parseInt(courseId)) {
      courseStatus = course.status;
    }
  });

  const fetchCourseData = () => {
    const { id, currentUserRounds } = authReducer;

    // Fetch data when needed
    if (courseId !== undefined && currentUserRounds.length !== 6) {
      axios
        .post(`/api/sub-dashboard`, {
          userId: id,
          courseId: parseInt(courseId),
        })
        .then((res) => {
          dispatch(okuskoliData({ ...res.data }));
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  // Check if the user is registered for the course
  if (userRounds.length > 0) {
    // Steps through each round, checks if open.
    userRounds.forEach((round: any) => {
      const { nr, status } = round;
      const { nextRoundOpen, currentRound } = authReducer;
      let isCurrentRound = false;
      currentRound === nr ? (isCurrentRound = true) : (isCurrentRound = false);

      const roundLink = {
        roundNr: nr,
        roundStatus: status,
        isCurrentRound,
        nextRoundOpen,
      };

      if (courseId && parseInt(courseId) === round.course_id) {
        links.push(roundLink);
      }

      // If at least one round is not finished
      if (
        courseId &&
        status !== "Finished" &&
        parseInt(courseId) === round.course_id
      ) {
        allFinished = false;
      }
    });

    // Render final test link correctly
    if (allFinished === true && courseId !== undefined) {
      finalTestStatus = "fa fa-square-o";
      finalTestEnabled = "enabled-link";
      authReducer.userCourses.forEach((course: any) => {
        if (course.id === parseInt(courseId) && course.status === "Finished") {
          finalTestStatus = "fa fa-check-square-o";
        }
      });
    }
  }

  return (
    <>
      <div className="navigation">
        <div className="logo-container">
          <Link to="/stjornbord">
            <img id="logo" src="/images/icon-logo.gif" />
          </Link>
          {/* Half baked idea to add more context to Ö1 and Ö2 */}
          {/* <p
            style={{
              display: "inline-block",
              bottom: "31px",
              position: "relative",
              marginLeft: "20px",
            }}
          >
            Ökuskóli 1
          </p> */}
        </div>
        <MobileNav
          links={links}
          finalTestStatus={finalTestStatus}
          finalTestEnabled={finalTestEnabled}
          courseId={courseId}
          messageFeatureUsed={messageFeatureUsed}
          courseStatus={courseStatus}
        />
        {/* Display payment steps in payment flow */}
        <div className="lotu-links">
          {courseStatus === "Expired" || courseId === undefined
            ? ""
            : links.map((link) => {
                const { roundNr, roundStatus, nextRoundOpen, isCurrentRound } =
                  link;
                return (
                  <NavItem
                    key={roundNr}
                    courseId={courseId}
                    roundNr={roundNr}
                    currentStep={currentStep}
                    roundStatus={roundStatus}
                    nextRoundOpen={nextRoundOpen}
                    isCurrentRound={isCurrentRound}
                    messageFeatureUsed={messageFeatureUsed}
                  />
                );
              })}
          {/* If no course is open don't display the final test item */}
          {links.length === 0 || courseStatus === "Expired" ? (
            ""
          ) : (
            <Link
              to={`/okuskoli-${courseId}/lokaprof`}
              className={finalTestEnabled}
              key={"link-finaltest"}
            >
              <p>
                <i className={finalTestStatus}></i> {t("DashNavigation.Próf")}
              </p>
            </Link>
          )}
          <Link to={t(Pages.Settings)}>
            <p>
              <i className="fa fa-cog"></i> {t("DashNavigation.Stillingar")}
            </p>
          </Link>
          <a href="/logout" target="_self">
            <p>
              <i className="fa fa-sign-out"></i> {t("DashNavigation.Skrá út")}
            </p>
          </a>
        </div>
      </div>
      <PaymentNav paymentStep={paymentStep} isPaymentFlow={isPaymentFlow} />
    </>
  );
};

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Navigation);
