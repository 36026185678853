import React, { FC } from "react";
import Card from "../../../../../reusables/card";
import AnswerCheckboxes from "./AnswerCheckboxes";
import QuestionImages from "./QuestionImages";
import { TestAnswers, UserTest, Test } from "../../../../store/test";
import { useGetLang } from "../../../../../hooks/useGetLang";

interface TestQuestionProps {
  testAnswers: TestAnswers[];
  userTest: UserTest;
  disabled: boolean;
  test: Test;
  answerSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
  testType: "FinalTest" | "RoundTest";
}

export const TestQuestions: FC<TestQuestionProps> = ({
  testAnswers,
  userTest,
  disabled,
  test,
  answerSelected,
  testType,
}) => {
  const totalQuestions = testType === "FinalTest" ? 30 : 10;
  const lang = useGetLang();

  return (
    <>
      {test.questions.slice(0, totalQuestions).map((question, index) => {
        const { question_images, id } = question;
        const hasImages = question_images.length ? true : false;
        const initialQuestionText =
          lang === "is" ? question.question : question.question_en;

        const questionText = `${index + 1}. ${initialQuestionText}`;
        return (
          <Card className="mg-bottom-10" key={id}>
            <li>
              <p>{questionText}</p>
              <div>
                {hasImages && (
                  <QuestionImages questionImages={question_images} />
                )}
              </div>
              <AnswerCheckboxes
                question={question}
                testAnswers={testAnswers}
                userTest={userTest}
                disabled={disabled}
                answerSelected={answerSelected}
              />
            </li>
          </Card>
        );
      })}
    </>
  );
};

export default TestQuestions;
