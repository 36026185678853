import React, { useState, useEffect } from "react";
import CourseWrapper from "../CourseWrapper";
import TextSetting from "./TextSetting";
import { useApi } from "../../hooks";
import { getUserSettings, getUserTransactions } from "../../api/courseApi";
import LangToggle from "../site/LangToggle";
import { useTranslation } from "react-i18next";
import DyslexiaSetting from "./DyslexiaSetting";
import Card from "../../reusables/card";

export const translations = {
  is: {
    Settings: {
      Stillingar: "Stillingar",
      Notendaupplýsingar: "Notendaupplýsingar",
      "Úbs! Eithvað fór úrskeiðis": "Úbs! Eithvað fór úrskeiðis:",
      Nafn: "Nafn",
      Tölvupóstur: "Tölvupóstur",
      Notendaupplifun: "Notenda stillingar",
      Language: "Tungumál",
      Kaup: "Viðskipti og kvittanir",
      Námskeið: "Námskeið",
      Verð: "Verð",
      Dagsetning: "Dagsetning",
      "Sýna kvittun": "Sýna kvittun",
      "Engin kaup": "Engin viðskipti fundust",
    },
  },
  en: {
    Settings: {
      Stillingar: "Settings",
      Notendaupplýsingar: "User profile",
      "Úbs! Eithvað fór úrskeiðis": "Oops! Something went wrong:",
      Nafn: "Name",
      Tölvupóstur: "Email",
      Notendaupplifun: "User settings",
      Language: "Language",
      Kaup: "Purchases",
      Námskeið: "Course",
      Verð: "Price",
      Dagsetning: "Date",
      "Sýna kvittun": "Show receipt",
      "Engin kaup": "No purchases found",
    },
  },
};

interface Transaction {
  id: number;
  course_name: string;
  price: number;
  time: string;
}

const Settings = () => {
  const {
    data: response,
    status,
    message,
    exec: initGetUserSettings,
  } = useApi(getUserSettings);

  const { data: transactionsResponse, exec: initGetUserTransactions } =
    useApi(getUserTransactions);

  const { t } = useTranslation();

  const [user, setUser] = useState({
    name: "Loading...",
    email: "Loading...",
  });

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    initGetUserSettings();
    initGetUserTransactions();
  }, []);

  useEffect(() => {
    if (response?.user) {
      const { user } = response;
      setUser(user);
    }
  }, [response]);

  useEffect(() => {
    if (transactionsResponse?.transactions) {
      setTransactions(transactionsResponse.transactions);
    }
  }, [transactionsResponse]);

  useEffect(() => {
    if (status === "ERROR") {
      setUser({
        name: "",
        email: "",
      });
    }
  }, [status]);

  const updateUserData = (field: string, value: string) => {
    setUser((prevUser) => {
      const fieldToUpdate = { [field]: value };
      return { ...prevUser, ...fieldToUpdate };
    });
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("is-IS");
  };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat("is-IS", {
      style: "currency",
      currency: "ISK",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const { name, email } = user;

  return (
    <CourseWrapper title={t("Settings.Stillingar")}>
      <div className="main-content">
        <div className="lotu-container">
          <div className="settings-page">
            <h1>{t("Settings.Stillingar")}</h1>
            <h3>{t("Settings.Notendaupplýsingar")}</h3>
            {status === "ERROR" && (
              <p className="error">
                {t("Settings.Úbs! Eithvað fór úrskeiðis")} {message}
              </p>
            )}
            <TextSetting
              label={t("Settings.Nafn")}
              userData={name}
              name="name"
              updateUserData={updateUserData}
            />
            <TextSetting
              label={t("Settings.Tölvupóstur")}
              userData={email}
              name="email"
              updateUserData={updateUserData}
            />
            <div>
              <h3>{t("Settings.Notendaupplifun")}</h3>
              <Card className="mb-5">
                <p className="mt-0 mb-0 font-bold">{t("Settings.Language")}</p>
                <LangToggle onlyShowFlag={false} />
              </Card>
              <Card>
                <DyslexiaSetting />
              </Card>
            </div>
            <div>
              <h3>{t("Settings.Kaup")}</h3>
              {transactions.length > 0 ? (
                <div className="space-y-4">
                  {transactions.map((transaction: any) => (
                    <Card key={transaction.id}>
                      <div className="flex items-center justify-between">
                        <div className="space-y-1">
                          <div className="font-medium">
                            {transaction.course_name}
                          </div>
                          <div className="text-sm text-gray-600">
                            {formatDate(transaction.time)} -{" "}
                            {formatPrice(transaction.price)}
                          </div>
                        </div>
                        <button
                          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                          onClick={() =>
                            window.open(
                              `/api/receipt/${transaction.id}`,
                              "_blank"
                            )
                          }
                        >
                          {t("Settings.Sýna kvittun")}
                        </button>
                      </div>
                    </Card>
                  ))}
                </div>
              ) : (
                <Card>
                  <p className="text-gray-500 text-center py-4">
                    {t("Settings.Engin kaup")}
                  </p>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    </CourseWrapper>
  );
};

export default Settings;
